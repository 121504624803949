import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { CardOptions } from '@usitsdasdesign/dds-ng/cards';
import { WidthState, Themes, Size, ButtonKind,LabelPosition, ErrorState, TabThemeType } from '@usitsdasdesign/dds-ng/shared';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SelectOptions, SelectItemOptions} from '@usitsdasdesign/dds-ng/select';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { SharingmessageService } from '../Services/sharingmessage.service'
import { I18nService, EN } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { ChatService } from '../Services/chat.service';
import { WizardOptions, WizardThemes, WizardItemOptions, WizardItemComponent } from '@usitsdasdesign/dds-ng/wizard';
import { TabsOptions, TabOptions, TabComponent } from '@usitsdasdesign/dds-ng/tabs';
import { VerticalTabsOptions } from '@usitsdasdesign/dds-ng/vertical-tabs';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-chat-navbar',
  templateUrl: './chat-navbar.component.html',
  styleUrls: ['./chat-navbar.component.css']
})
export class ChatNavbarComponent{
  @Input() uservalid : any;
  title = 'Gen-D';
  nameLetter:any;  
  lang1:any;
  private destroy = new Subject<void>();
  label1: string;
  subheading: string;
  input_placeholder: string;
  messagetoChat1: string;
  messagetoChat2: string;
  messagetoChat3: string;
  messagetoChat4: string;
  messagetoChat5: string;
  messagetoChat6: string;
  messagetoChat7: string;
  messagetoChat8: string;
  messagetoChat9: string;
  messagetoChat10: string;
  messagetoChat11: string;
  prompt_head1: string;
  prompt_head2: string;
  prompt_head3: string;
  pfb: string;
  pfb_placeholder: string;
  fb_skip: string;
  fb_submit: string;
  nfb: string;
  nfb_placeholder: string;
  fb_prompt: string;
  copy_title: string;
  option1: string;
  option2: string;
  fault: string;
  welcomemsg: string;
  docHeader: string;
  limitation: string;
  limitationdoc1: string;
  limitationdoc2: string;
  limitationdoc3: string;
  docpop3_msg1: string;
  docpop3_msg2: string;
  docpop3_msg3: string;
  docpop3_msg4: string;
  docpop3_msg5: string;
  docpop3_msg6: string;
  docpop3_msg7: string;
  docsub1_1: string;
  docsub1_2: string;
  docsub2_1: string;
  docsub2_2: string;
  docsub2_3: string;
  docsub2_4: string;
  docsub3_1: string;
  docsub3_2: string;
  docsub3_3: string;
  docsub4_1: string;
  docsub4_2: string;
  guidancePrivacy: string;
  docpop1_msg1: string;
  docpop1_msg2: string;
  docpop1_msg3: string;
  docpop1_msg4: string;
  docpop1_msg5: string;
  docpop1_msg6: string;
  docpop1_msg7: string;
  docpop2_msg1: string;
  docpop2_msg2: string;
  docpop2_msg3: string;
  docpop2_msg4: string;
  docpop2_msg5: string;
  docpopup_subheader_2: string;
  docpopup_subheader_1: string;
  tips1: string;
  tips2: string;
  docstorage: string;
  transtip: string;
  transtip1: string;
  transtip2: string;
  transtip1_1: string;
  transtip1_2: string;
  transtip1_3: string;
  transtip1_4: string;
  transtip3: string;
  transtip4: string;
  gend_guidelines: string;
  guideLinesLimitations:string = "Gen-D";
  guideLinesLimitations1:string = "Gen-D";
  guardrails: string;
  howto: string;
  doc_analyzer: string;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private https: HttpClient, private sharingService:SharingmessageService,
    private i18n: I18nService,
    private router: Router,
    private route: ActivatedRoute,
    private chatService: ChatService,
  ) {}
  displayStyle = "block";
  display2= "none"
  display3 = "none";
  response = "";
  displayStylepopup = "block";

  popup_header1: any = '';
  popup_header2: any = '';
  popup_header3: any = '';
  popup_header4: any = '';
  popup_subheader1 : any = '';
  popup_subheader2 : any = '';
  popup_subheader3 : any = '';
  popup_subheader4 : any = '';
  navigation1:any = '';
  navigation2:any = '';
  navigation3:any = '';
  navigation4:any = '';
  page1:any = '';
  page2:any = '';
  page3:any = '';
  pop1_msg1:any = '';
  pop1_msg2:any = '';
  pop1_msg3:any = '';
  pop1_msg4:any = '';
  pop1_msg5:any = '';
  pop2_msg1:any = '';
  pop2_msg2:any = '';
  pop2_msg3:any = '';
  pop2_msg4:any = '';
  pop2_msg5:any = '';
  pop2_msg6:any = '';
  pop2_msg7:any = '';
  help_popup:any = '';
  pop3_msg1:any = '';
  pop3_msg2:any = '';
  pop3_msg3:any = '';
  pop3_msg4:any = '';
  pop3_msg5:any = '';
  pop3_msg6:any = '';
  pop4_msg1:any = '';
  sub1_1:any = '';
  sub1_2:any = '';
  sub1_3:any = '';
  sub1_4:any = '';
  sub1_5:any = '';
  sub1_6:any = '';
  sub1_7:any = '';
  sub1_8:any = '';
  sub2_1:any = '';
  sub2_2:any = '';
  sub3_1:any = '';
  sub4_1:any = '';
  sub5_1:any = '';
  sub6_1:any = '';
  sub1_6_1:any = '';
  sub1_6_2:any = '';
  sub1_6_3:any = '';
  sub1_6_4:any = '';
  sub_1:any = '';
  sub_2:any = '';
  sub_3:any = '';
  sub_4:any = '';
  sub_5:any = '';
  sub_6:any = '';
  sub_7:any = '';
  sub_8:any = '';
  sub_9:any = '';
  sub_10:any = '';
  instruction:any = '';
  sub_instruction:any = '';
  disclaimer:any = '';
  switchvalue: any = false;
  switcpopup:any = false;
  switcpopupmain:any = false;
  var_project : any;
  orange:any;
  xls_msg: any;
  var_new : any;
  closePopup1() {
    this.displayStylepopup = "none";
    this.display3 = "none";
    this.display2 = "none";
  }
  openPopup1(){
       this.switcpopup = true;
       this.displayStylepopup = "block";
       this.display3= "none";
       this.display2 = "none";
  
  }
  OnChange(){
    var c = document.location.href.split("/");
    var proj = c[3].split("?");
    if(proj[0] == "chat" || proj[0] == ""){
    this.router.navigate(['/chat'], { queryParams: { lang:this.lang1 } });
    }
    else if(proj[0] == "documentanalyzer"){
    this.router.navigate(['/documentanalyzer'], { queryParams: { lang:this.lang1 } });
    }  
    else if(proj[0] == "InnovapostIntegration"){
    this.router.navigate(['/InnovapostIntegration'], { queryParams: { lang:this.lang1 } });
    }

  }

  tabContainerOptions: TabsOptions = {
    theme: Themes.green,
    themeType: TabThemeType.border,
    size: Size.md,
    ariaLabel: 'Horizontal tabs',
    isDisabled: false,
    customClass: '',
    isResponsive: false,    
  }
  tabOptions1: TabOptions = {
    label: 'Gen-D'
  };
  tabOptions2: TabOptions = {
    label: 'Document Analyzer'
  };
  tabChanged(tabItem: TabComponent) {
    console.log(tabItem);
  }
  wizardOptions: WizardOptions = {
    theme: WizardThemes.dark,
    startNumbering: 1,
    isNumeric: false,
    isVertical: true,
    isCompact: false,
    isInverse: false,
    customClass: '',
    activeIndex: 0
  };
  wizardItemOptions: WizardItemOptions = {
    label: 'Option',
    icon: '',
    progress: 100,
    customClass: ''
  };
  wizardItemChanged(item: WizardItemComponent): void {
    this.guideLinesLimitations = item.label;
  }
  wizardItemChanged1(item: WizardItemComponent): void {
    this.guideLinesLimitations1 = item.label;
  }
  
  verticaltabContainerOptions: VerticalTabsOptions = {
    theme: Themes.dark,
    themeType: TabThemeType.border,
    size: Size.lg,
    ariaLabel: 'Vertical tabs',
    isDisabled: false,
    isInverse: false,
    customClass: ''
  };
  verticaltabContainerOptions1: VerticalTabsOptions = {
    theme: Themes.dark,
    themeType: TabThemeType.border,
    size: Size.lg,
    ariaLabel: 'Vertical tabs',
    isDisabled: false,
    isInverse: false,
    customClass: ''
  };

  ngOnInit() {
    this.getProfile();
    var c = document.location.href.split("/");
    var proj = c[3].split("?");
    if(proj[0] == "chat"){
    this.var_project = "chat";
    }
    else if(proj[0] == "documentanalyzer"){
    this.var_project = "documentanalyzer";
    }  
    else if(proj[0] == "InnovapostIntegration"){
    this.var_project = "orange";
    }
    
    this.route.queryParams.subscribe(params => {
      var lang = params['lang']; 

    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (lang) {

          case "fr":   
          this.label1 = "Exemples de questions "; 
          this.subheading = " Vous cherchez de l’inspiration? Essayez un exemple de question ci-dessous! ";
          this.input_placeholder = "Tapez votre information ou votre question...";
          this.popup_header1 = 'Lignes directrices et limites';
          this.popup_header2 = 'Capacités';
          this.popup_header3 = 'DONNÉES POUVANT ÊTRE UTILISÉES';
          this.popup_header4 = 'DONNÉES NE POUVANT PAS ÊTRE UTILISÉES'; 
          this.popup_subheader1 = 'Notre agent conversationnel est alimenté par le modèle GPT-3.5-Turbo et GPT-4 d’Azure OpenAI et présente les mêmes risques et limites, y compris ceux ci-dessous.';
          this.popup_subheader2 = 'Notre agent conversationnel offre toutes les capacités d’IA générative du modèle GPT-3.5-Turbo et GPT-4, qui alimente également la version d’OpenAI de ChatGPT.';
          this.popup_subheader3 = 'Voici quelques exemples de types de données dont l’utilisation est approuvée dans l’application.';
          this.messagetoChat1 = 'Pouvez-vous m’aider à trouver de l’information sur [sujet]?';
          this.messagetoChat2 = 'Quelles sont certaines sources d’information fiables sur [sujet]? ';
          this.messagetoChat3 = 'Je cherche des statistiques sur [sujet]. Pouvez-vous m’aider à en trouver? ';
          this.messagetoChat4 = 'Texte : [ajouter le texte ici]\nPouvez-vous résumer cet article pour moi? ';
          this.messagetoChat5 = 'Texte : [ajouter le texte ici]\nÀ partir du texte ci-dessus, pouvez-vous créer un résumé qui sera présenté au responsable du service?';
          this.messagetoChat6 = 'Pouvez-vous produire un courriel que je peux envoyer à [personne] au sujet de [sujet]?';
          this.messagetoChat7 = 'Pouvez-vous produire un rapport sur [sujet] pour moi?';
          this.messagetoChat8 = 'Pouvez-vous produire une offre de service pour [projet]?';
          this.messagetoChat9 = 'Effectuez une comparaison côte à côte de [Technologie1] et [Technologie2] et présentez les résultats sous forme de tableau.';
          this.messagetoChat10 = 'Code: [ajouter le code ici]\nPouvez-vous expliquer la fonctionnalité de base de ce script en quelques phrases?';
          this.messagetoChat11 = 'Pouvez-vous me donner le code pour générer un robot qui répète en Python à l’aide de RASA?';
          this.prompt_head1 = 'RECHERCHE';
          this.prompt_head2 = 'SYNTHÈSE';
          this.prompt_head3 = 'PRODUCTION DE CONTENU';
          this.navigation1 = 'Ignorer';
          this.navigation2 = 'Suivant';
          this.navigation3 = 'Précédent';
          this.navigation4 = 'Fermer';
          this.page1 = '1 de 3';
          this.page2 = '2 de 3';
          this.page3 = '3 de 3';
          this.pop1_msg1 = 'Connaissance limitée du monde : bien que le modèle ait accès à une grande quantité d’information, ses connaissances se limitent aux données sur lesquelles il a été entraîné. Il pourrait donc ne pas être en mesure de fournir des réponses précises à des questions qui ne portent pas sur les données au moyen desquelles il a été entraîné, comme les événements survenus après 2021.';
          this.pop1_msg2 = 'Vulnérabilité aux biais : le modèle pourrait être susceptible à des biais, car ses réponses se fondent sur les données sur lesquelles il a été entraîné. Si ces données sont biaisées, les réponses du modèle pourraient l’être aussi.';
          this.pop1_msg3 = 'Manque de fiabilité: le modèle pourrait ne pas toujours fournir des informations fiables, car il se fonde sur les données sur lesquelles il a été entraîné. Cela signifie qu’il pourrait fournir des informations inexactes ou incomplètes et entraîner une mauvaise prise de décision.';
          this.pop1_msg4 = 'Incapacité à vérifier les sources: : le modèle pourrait ne pas être en mesure de vérifier les sources des informations qu’il fournit, ce qui peut conduire à la présentation d’informations fausses ou trompeuses comme des faits.';
          this.pop1_msg5 = 'Originalité et propriété intellectuelle : comme l’agent conversationnel s’appuie sur les données sur lesquelles il a été entraîné, il est possible que les résultats générés soient trop semblables au texte source et conduisent éventuellement à une violation du droit d’auteur. Pour atténuer ce risque de violation du droit d’auteur, les professionnels doivent examiner et réviser le contenu généré par l’agent conversationnel avant de l’utiliser dans tout document destiné aux clients, y compris les produits livrables.';
          this.pop2_msg1 = 'Ces capacités comprennent notamment la recherche générale, la production de résumés et la génération de données. Notez que l’agent conversationnel ne traite que les conversations textuelles.';
          this.pop2_msg2 = 'Dans la mesure du possible, l’agent conversationnel est entraîné de manière à fournir des réponses fondées sur les données sur lesquelles il a été entraîné, sans toutefois donner de fausses informations induites par des invites utilisateurs.';
          this.pop2_msg3 = 'L’outil peut se souvenir des conversations antérieures au cours d’une session et permettre aux utilisateurs de poser des questions de suivi.';
          this.pop2_msg4 = 'Les utilisateurs peuvent utiliser les exemples de demandes ou rédiger leurs propres demandes afin d’obtenir des réponses plus pertinentes.';
          this.pop2_msg5 = 'Les renseignements sur les utilisateurs et les conversations seront stockés à des fins de rapports internes. Toutefois, les données permettant d’identifier les utilisateurs ne seront pas divulguées dans le cadre de ces rapports.';
          this.pop2_msg6 = 'Les conversations et les renseignements sur les utilisateurs peuvent être stockés à des fins de production de rapports internes seulement.';
          this.pop2_msg7 = '’agent conversationnel peut refuser de répondre à des demandes inappropriées.';
          this.pop3_msg1 = 'Données de Deloitte (pourvu que leur utilisation ou leur diffusion à grande échelle ne fassent pas l’objet de restrictions)';
          this.pop3_msg2 = 'Données publiques';
          this.pop3_msg3 = 'Données de tiers achetées à des courtiers en données ';
          this.pop3_msg4 = 'Données synthétiques';
          this.pop3_msg5 = 'Renseignements personnels';
          this.pop3_msg6 = 'Renseignements sur les clients dont l’utilisation dans l’outil d’IA a été approuvée ';
          this.pop4_msg1 = 'Données de Deloitte';
          this.sub1_1 = 'Toute information accessible sur le site web public externe de Deloitte;';
          this.sub1_2 = 'Occasions d’emploi de Deloitte;';
          this.sub1_3 = 'Propositions, qualifications, approches et modèles conçus par Deloitte (doivent exclure tout renseignement ou élément de propriété intellectuelle de clients);';
          this.sub1_4 = 'Documents de marketing externes et destinés au public de Deloitte;';
          this.sub1_5 = 'Politiques, procédures et documents d’orientation de Deloitte (pourvu que leur utilisation ou leur diffusion à grande échelle ne fassent pas l’objet de restrictions);';
          this.sub1_6 = 'Exigences commerciales ou codes internes de Deloitte (exemples ci-dessous) :';
          this.sub1_7 = 'Documents de formation de Deloitte;';
          this.sub1_8 = 'Autres renseignements confidentiels de Deloitte qui ne figurent pas dans la liste des données non approuvées.';
          this.sub2_1 = 'Informations qui ne sont pas protégées par le droit d’auteur ou par d’autres conditions d’utilisation;';
          this.sub2_2 = 'Informations (publiques ou exclusives) protégées par une marque de commerce, le droit d’auteur ou des conditions d’utilisation (p. ex. d’un site web) pour lesquelles une autorisation d’utilisation a été obtenue.';
          this.sub3_1 = 'Données de tiers qui ont été obtenues ou achetées pour être utilisées par Deloitte (confirmez avec le tiers responsable du contrat que le cas d’utilisation des données d’IA générative que vous envisagez serait autorisé en vertu des modalités du contrat).';
          this.sub4_1 = 'Données fausses ou fictives générées pour remplacer des données réelles (les données synthétiques NE COMPRENNENT PAS de données réelles ou provenant d’un environnement de développement ou de production, y compris les données confidentielles ou personnelles).';
          this.sub5_1 = 'Renseignements personnels agrégés, dépersonnalisés ou anonymisés.';
          this.sub6_1 = 'L’utilisation de renseignements sur les clients dans l’outil est assujettie à l’examen et à l’approbation de votre ALSC. Les renseignements sur les clients ne peuvent être utilisés qu’en conformité avec les modalités de nos ententes avec les clients et dans le cadre de la prestation des services convenus. En règle générale, si les renseignements sur un client sont utilisés dans le but précis de lui fournir des services et qu’aucune restriction ne s’y oppose, ils peuvent être utilisés. Si votre ALSC a des doutes quant aux exigences de confidentialité de vos ententes avec les clients, communiquez avec le Bureau du directeur des affaires juridiques.';
          this.sub_1 = 'Procès-verbaux des réunions du Conseil d’administration;';
          this.sub_2 = 'États financiers de Deloitte;';
          this.sub_3 = 'Modèles et cartes de tarification de Deloitte;';
          this.sub_4 = 'Informations nécessitant une certification qu’Azure OpenAI ne détient pas ou l’adhésion à une norme qu’Azure OpenAI ne respecte pas (p. ex. renseignements sur les cartes de paiement, car Azure OpenAI n’a pas obtenu d’attestation de conformité à la norme PCI DSS);';
          this.sub_5 = 'Données individuelles sur les effectifs;';
          this.sub_6 = 'Données individuelles sur l’activité des employés.';
          this.sub_7 = 'Informations (publiques ou exclusives) dont les conditions d’utilisation interdisent expressément l’utilisation (p. ex. données de journal en ligne, études de marché);';
          this.sub_8 = 'Données obtenues par moissonnage du web (c.-à-d. l’utilisation de robots pour extraire le contenu et les données d’un site web).';
          this.sub_9 = 'Données de tiers qui n’ont pas été obtenues ou achetées pour l’usage de Deloitte (p. ex. données obtenues auprès d’entrepreneurs ou de fournisseurs).';
          this.sub_10 = 'Renseignements personnels ou permettant d’identifier une personne.'; 
          this.pfb = 'Vos commentaires sont importants pour nous.';
          this.pfb_placeholder = 'Qu’avez-vous aimé à propos de la réponse?';
          this.fb_skip = 'Ignorer cette étape pour l’instant';
          this.fb_submit = 'Soumettre';
          this.nfb = 'Avez-vous d’autres commentaires à formuler? Nous voulons les connaître.';
          this.nfb_placeholder = 'Dites-nous comment nous pouvons nous améliorer.';
          this.fb_prompt = 'Cette réponse est-elle utile ou exacte?';
          this.copy_title = 'Copier le code';
          this.option1 = 'Oui';
          this.option2 = 'Non';
          this.fault = 'Désolé, une erreur s’est produite! Veuillez réessayer plus tard.';
          this.welcomemsg = 'Bonjour, bienvenue à Gen-D! Allez-y, envoyez un message.';
          this.sub1_6_1 = 'Documents d’accueil,';
          this.sub1_6_2 = 'Documents d’exigences commerciales,';
          this.sub1_6_3 = 'Documents d’exigences fonctionnelles,';
          this.sub1_6_4 = 'Codes ou scripts liés aux applications internes de Deloitte;';
          this.instruction = 'En vigueur en date du 1er août 2023';
          this.sub_instruction = 'En raison de ces limites, l’utilisation des résultats de l’outil à des fins de recherche est autorisée. Lorsque l’outil est utilisé à des fins de prestation de services aux clients, les professionnels doivent respecter les attentes en matière de qualité (y compris l’originalité) établies par leur service, les politiques pertinentes et le code d’éthique de Deloitte. Au minimum, une vérification humaine de l’exactitude est nécessaire.';
          this.disclaimer = "Gen-D ne peut être utilisé que par les professionnels de Deloitte du cabinet membre canadien, y compris le Chili.";
          this.docHeader = "L'analyseur de documents permet aux utilisateurs de poser des questions sur la base de documents téléchargés, en utilisant la génération augmentée ainsi que les modèles GPT-4 et GPT-3 Embeddings d'Azure OpenAI.";
          this.limitation = "Limitation";
          this.limitationdoc1 = 'Bien que les réponses soient générées en utilisant le contexte des données téléchargées, elles peuvent être sujettes à des hallucinations.';
          this.limitationdoc2 = "L'application utilise MS Form Recognizer pour lire les données des documents téléchargés et applique Gen AI à ces données. Il est recommandé d'utiliser des documents contenant beaucoup de texte et il peut y avoir des problèmes résultant d'une capture inefficace des données des documents dans certains cas.";
          this.limitationdoc3 = "Les utilisateurs ne pourront interagir qu'avec le texte et non avec les infographies contenues dans les documents.";           
          this.docpop3_msg1 = "N'UTILISEZ PAS l'analyseur de documents pour créer des produits de travail à partir d'informations provenant de différents clients (ce que l'on appelle l'utilisation secondaire). Il est interdit d'utiliser des informations confidentielles relatives à un autre client, telles que des données précédemment collectées ou fournies par un autre client dans une promotion et des pièces jointes (par exemple, ne pas faire référence à des documents relatifs à trois clients différents pour les résumer).";
          this.docpop3_msg2 = "N'UTILISEZ PAS l'analyseur de documents si cette utilisation risque de violer les engagements contractuels d'un client. Discutez avec votre ALSC pour confirmer si des restrictions spécifiques au client s'appliquent à votre mission.";
          this.docpop3_msg3 = "N'UTILISEZ PAS de renseignements personnels (renseignements concernant une personne identifiable, y compris, mais sans s'y limiter, les professionnels de Deloitte, les clients, leurs clients ou leurs employés, tels que définis dans le Pri) dans L'analyseur de documents à des fins qui ne sont pas compatibles avec l'objectif initial pour lequel ces renseignements ont été recueillis.";
          this.docpop3_msg4 = 'Vous pouvez utiliser des informations publiques telles que :';
          this.docpop3_msg5 = 'Vous pouvez utiliser les informations de Deloitte telles que :';
          this.docpop3_msg6 = 'Vous pouvez utiliser les données des clients :';
          this.docpop3_msg7 = 'Vous pouvez utiliser les informations personnelles';
          this.docsub1_1 = "Les informations (publiques ou propriétaires) protégées par une marque, des droits d'auteur ou des conditions d'utilisation spécifiques (par exemple, les conditions d'utilisation d'un site web) pour lesquelles une autorisation d'utilisation a été obtenue.";
          this.docsub1_2 = "Les informations qui ne sont pas protégées par des droits d'auteur ou d'autres conditions d'utilisation spécifiques.";
          this.docsub2_1 = 'Le matériel de marketing externe de Deloitte.';
          this.docsub2_2 = "Les politiques, procédures et documents d'orientation de Deloitte.";
          this.docsub2_3 = 'Exigences et codes internes de Deloitte.';
          this.docsub2_4 = 'Matériel de formation de Deloitte.';
          this.docsub3_1 = 'conformément aux dispositions des accords conclus avec les clients et dans le cadre de la fourniture des services convenus à ce même client, ou';
          this.docsub3_2 = "pour fournir des services à ce client, et si aucune restriction ne s'oppose à cette utilisation, ou";
          this.docsub3_3 = 'pour lesquelles le LCSP a examiné et approuvé';
          this.docsub4_1 = "à condition que vous ayez l'autorisation ou le consentement nécessaire pour collecter les informations et que l'objectif de l'utilisation des informations personnelles soit conforme à celui pour lequel elles ont été collectées à l'origine (par exemple, vous êtes un spécialiste des finances qui compare des factures contenant des informations personnelles afin d'identifier des écarts de prix, ou vous êtes un responsable du recrutement qui compare des notes d'entretien afin d'identifier des thèmes communs parmi les candidats). En cas de doute, contactez le Bureau de la protection de la vie privée";
          this.docsub4_2 = 'Deloitte firm pricing model materials and rate cards';
          this.guidancePrivacy = "Conseils et limites";
          this.docpop1_msg1 = 'Sous le "Documents Dock", choisissez les fichiers avec lesquels vous souhaitez interagir et cliquez sur "Télécharger".';
          this.docpop1_msg2 = `Une fois téléchargé, le bouton "Upload" sera désactivé jusqu'à ce que l'utilisateur clique à nouveau sur "Réinitialiser r".`;
          this.docpop1_msg3 = "Lors du téléchargement, l'outil fournit un résumé des documents téléchargés, après quoi les utilisateurs peuvent poser des questions basées sur leur contenu.";
          this.docpop1_msg4 = "Les questions des utilisateurs sont mises en correspondance avec les sections correspondantes des documents téléchargés et les réponses sont générées sur la base de ce contexte. Toutefois, l'application ne permet pas de poser des questions de suivi. Il est conseillé aux utilisateurs de formuler leurs questions de manière détaillée afin d'obtenir des réponses pertinentes";
          this.docpop1_msg5 = `Une fois l'opération terminée, les utilisateurs peuvent fermer le navigateur ou cliquer sur "Réinitialiser" pour supprimer les documents.`;
          this.docpop1_msg6 = 'Après la "réinitialisation", les utilisateurs peuvent sélectionner de nouveaux documents à télécharger et interagir avec eux.';
          this.docpop1_msg7 = "Les utilisateurs peuvent sélectionner jusqu'à 5 documents à la fois.";
          this.docpop2_msg1 = "Une fois téléchargés, les documents et leurs données d'intégration respectives sont stockés dans un cache temporaire afin de retrouver les réponses aux requêtes de l'utilisateur.";
          this.docpop2_msg2 = `Les données sont supprimées du cache lorsque l'utilisateur clique sur "Réinitialiser" ou ferme la session.`;
          this.docpop2_msg3 = "Les données téléchargées par les utilisateurs ne sont pas utilisées ou recoupées pour fournir des réponses à d'autres utilisateurs de l'application.";
          this.docpop2_msg4 = "Le cache d'exécution réside dans la zone Azure de Deloitte, dans la région du Canada.";
          this.docpop2_msg5 = "Les détails de l'utilisateur et les conversations sont récupérés au cours de l'interaction pour permettre aux utilisateurs de poser des questions de suivi.";
          this.docpopup_subheader_2 = "Conseils pour interagir avec plusieurs fichiers"; 
          this.docpopup_subheader_1= 'Les utilisateurs peuvent maintenant interagir avec leurs documents en suivant ces étapes simples';
          this.tips1 = "Les utilisateurs peuvent télécharger jusqu'à 5 fichiers à la fois. Il est recommandé de télécharger des fichiers apparentés ensemble pour améliorer le contexte.";
          this.tips2 = "Les utilisateurs ne pourront pas ajouter d'éléments à ces documents sélectionnés une fois qu'ils auront été téléchargés. Ex : si les utilisateurs veulent télécharger un troisième document qui est également lié au document déjà téléchargé, ils doivent réinitialiser et télécharger à nouveau tous les documents ensemble.";
          this.docstorage = 'Politique de stockage des données';
          this.transtip = "Conseils pour une meilleure expérience avec les transcriptions ";
          this.transtip1 = "Résumez le contenu dans la structure ci-dessous";
          this.transtip2 = "Quelle a été la contribution de <Nom> pendant l'appel? ";
          this.transtip1_1 = "Titre de la réunion :";
          this.transtip1_2 = "Principaux points de discussion :";
          this.transtip1_3 = "Liste de tous les intervenants :";
          this.transtip1_4 ="Points d'action et prochaines étapes : ";
          this.transtip3 = "Qui est l'organisateur de l'appel? ";
          this.transtip4 = "Que s'est-il passé au cours de la seconde moitié de la réunion? ";
            this.lang1 = 'fr';
            this.popup_header1 = 'Lignes directrices et limites';
            this.popup_header2 = 'Capacités';
            this.popup_header3 = 'DONNÉES POUVANT ÊTRE UTILISÉES';
            this.popup_header4 = 'DONNÉES NE POUVANT PAS ÊTRE UTILISÉES';
            this.popup_subheader1 = 'Notre agent conversationnel est alimenté par les modèles GPT-3.5-Turbo et GPT 4 d’Azure OpenAI et présente les mêmes risques et limites, y compris ceux ci-dessous.';
            this.popup_subheader2 = 'Notre agent conversationnel offre toutes les capacités d’IA générative des modèles GPT-3.5-Turboet GPT 4 d’Azure OpenAI';
            this.popup_subheader3 = 'Voici quelques exemples de types de données dont l’utilisation est approuvée dans l’application.';
            this.navigation1 = 'Ignorer';
            this.navigation2 = 'Suivant';
            this.navigation3 = 'Précédent';
            this.navigation4 = 'Fermer';
            this.page1 = '1 de 3';
            this.page2 = '2 de 3';
            this.page3 = '2 de 3';
            this.pop1_msg1 = 'Connaissance limitée du monde : bien que le modèle ait accès à une grande quantité d’information, ses connaissances se limitent aux données sur lesquelles il a été entraîné. Il pourrait donc ne pas être en mesure de fournir des réponses précises à des questions qui ne portent pas sur les données au moyen desquelles il a été entraîné, comme les événements survenus après 2021.';
            this.pop1_msg2 = 'Vulnérabilité aux biais : le modèle pourrait être susceptible à des biais, car ses réponses se fondent sur les données sur lesquelles il a été entraîné. Si ces données sont biaisées, les réponses du modèle pourraient l’être aussi.';
            this.pop1_msg3 = 'Manque de fiabilité: le modèle pourrait ne pas toujours fournir des informations fiables, car il se fonde sur les données sur lesquelles il a été entraîné. Cela signifie qu’il pourrait fournir des informations inexactes ou incomplètes et entraîner une mauvaise prise de décision.';
            this.pop1_msg4 = 'Incapacité à vérifier les sources: : le modèle pourrait ne pas être en mesure de vérifier les sources des informations qu’il fournit, ce qui peut conduire à la présentation d’informations fausses ou trompeuses comme des faits.';
            this.pop1_msg5 = 'Originalité et propriété intellectuelle : comme l’agent conversationnel s’appuie sur les données sur lesquelles il a été entraîné, il est possible que les résultats générés soient trop semblables au texte source et conduisent éventuellement à une violation du droit d’auteur. Pour atténuer ce risque de violation du droit d’auteur, les professionnels doivent examiner et réviser le contenu généré par l’agent conversationnel avant de l’utiliser dans tout document destiné aux clients, y compris les produits livrables.';
            this.pop2_msg1 = 'Ces capacités comprennent notamment la recherche générale, la production de résumés et la génération de données. Notez que l’agent conversationnel ne traite que les conversations textuelles.';
            this.pop2_msg2 = 'Dans la mesure du possible, l’agent conversationnel est entraîné de manière à fournir des réponses fondées sur les données sur lesquelles il a été entraîné, sans toutefois donner de fausses informations induites par des invites utilisateurs.';
            this.pop2_msg3 = 'L’outil peut se souvenir des conversations antérieures au cours d’une session et permettre aux utilisateurs de poser des questions de suivi.';
            this.pop2_msg4 = 'Les utilisateurs peuvent utiliser les exemples de demandes ou rédiger leurs propres demandes afin d’obtenir des réponses plus pertinentes.';
            this.pop2_msg5 = 'Les renseignements sur les utilisateurs et les conversations seront stockés à des fins de rapports internes. Toutefois, les données permettant d’identifier les utilisateurs ne seront pas divulguées dans le cadre de ces rapports.';
            this.pop2_msg6 = 'Les conversations et les renseignements sur les utilisateurs peuvent être stockés à des fins de production de rapports internes seulement.';
            this.pop2_msg7 = '’agent conversationnel peut refuser de répondre à des demandes inappropriées.';
            this.pop3_msg1 = 'Données de Deloitte (pourvu que leur utilisation ou leur diffusion à grande échelle ne fassent pas l’objet de restrictions)';
            this.pop3_msg2 = 'Données publiques';
            this.pop3_msg3 = 'Données de tiers achetées à des courtiers en données ';
            this.pop3_msg4 = 'Données synthétiques';
            this.pop3_msg5 = 'Renseignements personnels';
            this.pop3_msg6 = 'Renseignements sur les clients dont l’utilisation dans l’outil d’IA a été approuvée ';
            this.pop4_msg1 = 'Données de Deloitte';
            this.sub1_1 = 'Toute information accessible sur le site web public externe de Deloitte;';
            this.sub1_2 = 'Occasions d’emploi de Deloitte;';
            this.sub1_3 = 'Propositions, qualifications, approches et modèles conçus par Deloitte (doivent exclure tout renseignement ou élément de propriété intellectuelle de clients);';
            this.sub1_4 = 'Documents de marketing externes et destinés au public de Deloitte;';
            this.sub1_5 = 'Politiques, procédures et documents d’orientation de Deloitte (pourvu que leur utilisation ou leur diffusion à grande échelle ne fassent pas l’objet de restrictions);';
            this.sub1_6 = 'Exigences commerciales ou codes internes de Deloitte (exemples ci-dessous) :';
            this.sub1_7 = 'Documents de formation de Deloitte;';
            this.sub1_8 = 'Autres renseignements confidentiels de Deloitte qui ne figurent pas dans la liste des données non approuvées.';
            this.sub2_1 = 'Informations qui ne sont pas protégées par le droit d’auteur ou par d’autres conditions d’utilisation;';
            this.sub2_2 = 'Informations (publiques ou exclusives) protégées par une marque de commerce, le droit d’auteur ou des conditions d’utilisation (p. ex. d’un site web) pour lesquelles une autorisation d’utilisation a été obtenue.';
            this.sub3_1 = 'Données de tiers qui ont été obtenues ou achetées pour être utilisées par Deloitte (confirmez avec le tiers responsable du contrat que le cas d’utilisation des données d’IA générative que vous envisagez serait autorisé en vertu des modalités du contrat).';
            this.sub4_1 = 'Données fausses ou fictives générées pour remplacer des données réelles (les données synthétiques NE COMPRENNENT PAS de données réelles ou provenant d’un environnement de développement ou de production, y compris les données confidentielles ou personnelles).';
            this.sub5_1 = 'Renseignements personnels agrégés, dépersonnalisés ou anonymisés.';
            this.sub6_1 = 'L’utilisation de renseignements sur les clients dans l’outil est assujettie à l’examen et à l’approbation de votre ALSC. Les renseignements sur les clients ne peuvent être utilisés qu’en conformité avec les modalités de nos ententes avec les clients et dans le cadre de la prestation des services convenus. En règle générale, si les renseignements sur un client sont utilisés dans le but précis de lui fournir des services et qu’aucune restriction ne s’y oppose, ils peuvent être utilisés. Si votre ALSC a des doutes quant aux exigences de confidentialité de vos ententes avec les clients, communiquez avec le Bureau du directeur des affaires juridiques.';
            this.sub_1 = 'Procès-verbaux des réunions du Conseil d’administration;';
            this.sub_2 = 'États financiers de Deloitte;';
            this.sub_3 = 'Modèles et cartes de tarification de Deloitte;';
            this.sub_4 = 'Informations nécessitant une certification qu’Azure OpenAI ne détient pas ou l’adhésion à une norme qu’Azure OpenAI ne respecte pas (p. ex. renseignements sur les cartes de paiement, car Azure OpenAI n’a pas obtenu d’attestation de conformité à la norme PCI DSS);';
            this.sub_5 = 'Données individuelles sur les effectifs;';
            this.sub_6 = 'Données individuelles sur l’activité des employés.';
            this.sub_7 = 'Informations (publiques ou exclusives) dont les conditions d’utilisation interdisent expressément l’utilisation (p. ex. données de journal en ligne, études de marché);';
            this.sub_8 = 'Données obtenues par moissonnage du web (c.-à-d. l’utilisation de robots pour extraire le contenu et les données d’un site web).';
            this.sub_9 = 'Données de tiers qui n’ont pas été obtenues ou achetées pour l’usage de Deloitte (p. ex. données obtenues auprès d’entrepreneurs ou de fournisseurs).';
            this.sub_10 = 'Renseignements personnels ou permettant d’identifier une personne.';
            this.sub1_6_1 = 'Documents d’accueil,';
            this.sub1_6_2 = 'Documents d’exigences commerciales,';
            this.sub1_6_3 = 'Documents d’exigences fonctionnelles,';
            this.sub1_6_4 = 'Codes ou scripts liés aux applications internes de Deloitte;';
            this.instruction = 'En vigueur en date du 1er août 2023';
            this.sub_instruction = 'En raison de ces limites, l’utilisation des résultats de l’outil à des fins de recherche est autorisée. Lorsque l’outil est utilisé à des fins de prestation de services aux clients, les professionnels doivent respecter les attentes en matière de qualité (y compris l’originalité) établies par leur service, les politiques pertinentes et le code d’éthique de Deloitte. Au minimum, une vérification humaine de l’exactitude est nécessaire.';
            this.help_popup = 'AIDE';
            this.disclaimer = "Gen-D ne peut être utilisé que par les professionnels de Deloitte du cabinet membre canadien, y compris le Chili.";
            this.guardrails = "Lignes directrices";
            this.howto = "Comment";
            this.doc_analyzer = "L'analyseur de documents";
            this.orange = "Intégration Innovaposte";
            this.xls_msg = "Attention : le support XLS/XLSX est actuellement en phase de test bêta."
            this.var_new = " NOUVEAU"
            break;
          default:
            this.lang1 = 'en';
            this.popup_header1 = 'Guidance and Limitations';  
            this.popup_header2 = 'Capabilities';
            this.popup_header3 = 'Data that may be used'; 
            this.popup_header4 = 'Data that cannot be used'; 
            this.popup_subheader1 = 'This chatbot is powered by GPT 3.5 Turbo and GPT 4 from Azure Open AI and inherits their risks and limitations, including the following:'; 
            this.popup_subheader2 = 'This chatbot offers all Generative AI capabilities offered by GPT 3.5 Turbo and GPT 4 models from Azure OpenAI.'; 
            this.popup_subheader3 = 'Below are some examples of types of data approved to be used with this application.';
            this.popup_subheader4 = 'Below are some examples of types of data not approved to be used with this application unless explicit authorization has been provided by the identified owners of such data sources.'; 
            this.navigation1 = 'Skip';
            this.navigation2 = 'Next';
            this.navigation3 = 'Previous';
            this.navigation4 = 'Close';
            this.page1 = '1 OF 3';
            this.page2 = '2 OF 3';
            this.page3 = '3 OF 3';
            this.pop1_msg1 = 'Limited world knowledge: Though the model has access to a vast amount of information, its knowledge is limited by the data it has been trained on. It may not be able to provide accurate answers to questions outside of its training data. E.g. Events after 2021.';
            this.pop1_msg2 = 'Vulnerability to bias: Model may be susceptible to bias, as its responses are based on the data it has been trained on. If the training data is biased, the model may produce biased responses.';
            this.pop1_msg3 = 'Lack of reliability: Model may not always provide reliable information, as it is based on the data it has been trained on. This means that it may provide inaccurate or incomplete information, leading to poor decision- making.';
            this.pop1_msg4 = 'Inability to verify sources:  Model may not be able to verify the sources of information it provides, which can lead to false or misleading information being presented as facts.';
            this.pop1_msg5 = 'Originality and IP: As the Chatbot relies on its training data to generate its outputs, there is a possibility that the generated output could be too close to the source text and potentially lead to copyright infringement. To help mitigate this copyright infringement risk, a practitioner should review and revise anything generated by the Chatbot before using it in any client facing materials, including deliverables.';
            this.pop2_msg1 = 'Its capabilities include and are not limited to, general search, summarization and data generation. Note that the chatbot handles only text-based conversations.';
            this.pop2_msg2 = 'To the best possible extent, this chatbot is trained to provide answers based on data it is trained on, without scope for hallucination.';
            this.pop2_msg3 = 'The chatbot can remember previous conversations within the session and allow users to ask follow-up questions.';
            this.pop2_msg4 = 'Users can utilize the sample prompts or design the prompts to elicit more meaningful responses from the chatbot.';
            this.pop2_msg5 = 'User details and conversations will be stored for internal reporting purposes. However, identifiable details will not be disclosed as part of such reporting';
            this.pop2_msg6 = 'Conversations and user details may be stored for internal reporting only.';
            this.pop2_msg7 = 'The chatbot may decline to answer inappropriate requests.';
            this.help_popup = 'HELP';
            this.pop3_msg1 = 'Deloitte Data (provided they are not restricted from use or wide dissemination)';
            this.pop3_msg2 = 'Public Data';
            this.pop3_msg3 = 'Third Party Data purchased from Data Brokers';
            this.pop3_msg4 = 'Synthetic Data';
            this.pop3_msg5 = 'Personal Information';
            this.pop3_msg6 = 'Client Information Approved for Use with the AI Tool';
            this.pop4_msg1 = 'Deloitte Data';
            this.sub1_1 = 'Any information available on Deloitte’s external public website';
            this.sub1_2 = 'Deloitte employment opportunity listings';
            this.sub1_3 = 'Deloitte developed templates and proposals, quals, and approaches (must exclude all client details and intellectual property).';
            this.sub1_4 = 'Deloitte’s external, public facing marketing materials';
            this.sub1_5 = 'Deloitte policies, procedures and guidance documents, provided they are not restricted from use or wide dissemination';
            this.sub1_6 = 'Internal Deloitte business requirements/code (examples below)';
            this.sub1_7 = 'Deloitte training materials';
            this.sub1_8 = 'Other Deloitte confidential information that is not on the “Data NOT approved” list';
            this.sub2_1 = 'Information that is not protected by copyright or other specific terms of use.';
            this.sub2_2 = 'Information (public or proprietary) protected by trademark, copyright or specific use terms (e.g., website terms) for which permission to use has been obtained.';
            this.sub3_1 = 'Third-party data that has been procured or purchased for Deloitte use and you have confirmed with the third-party contract owner that your intended generative AI data use case would be permitted under the terms of that contract.';
            this.sub4_1 = 'Fake or mock data generated as a substitute for live or real data. Synthetic data does NOT include any real data, development, or production environment data, including confidential or personal information';
            this.sub5_1 = 'Aggregated, de-identified or anonymized personal information';
            this.sub6_1 = 'Use of Client information in this tool is subject to LCSP review and approval. Client information can only be used in accordance with the terms of our client agreements and related to the delivery of the agreed-upon services to that same client. Generally, if the use of client information is specifically to provide services to that client, and there are no restrictions against such use, then it may be used. If your LCSP is unsure about the confidentiality requirements in your client agreements, consult with the Office of General Counsel (OGC)';
            this.sub_1 = 'Board of Director minutes';
            this.sub_2 = 'Deloitte’s financial statements';
            this.sub_3 = 'Deloitte firm pricing model materials and rate cards';
            this.sub_4 = 'Processing information requiring a certification or adherence to a specific standard not held by Azure OpenAI (e.g., processing of payment card information as Azure OpenAI does not have a PCIDSS certification)';
            this.sub_5 = 'Individual level talent data';
            this.sub_6 = 'Individual level employee activity';
            this.sub_7 = 'Information (public or proprietary) where the terms of use for such information expressly prohibit the use (e.g., online journal data, or market research)';
            this.sub_8 = 'Data obtained from web scraping (i.e., using bots to extract content and data from a website)';
            this.sub_9 = 'Purchased third party data not procured or purchased for Deloitte use (e.g., data acquired from contractors or vendors)';
            this.sub_10 = 'Any individual level or identifiable personal information';
            this.sub1_6_1 = 'Intake Documents';
            this.sub1_6_2 = 'Business Requirement Documents';
            this.sub1_6_3 = 'Functional Requirement Documents';
            this.sub1_6_4 = 'Code or scripts related to Deloitte internal applications';
            this.instruction = 'Effective August 1, 2023';
            this.sub_instruction = 'As a result of these limitations, use of the output from the tool for research purposes is permitted. Where the tool is being used for the purpose of client delivery, practitioners are reminded to adhere to quality (including originality) expectations set by their business, relevant policies, and Deloitte’s Code of Conduct. At a minimum, prior human review of accuracy is necessary.';
            this.disclaimer = 'Gen-D may only be used by Deloitte professionals in the Canadian member firm, including Chile.';
            this.label1 = "Sample Prompts"; 
            this.subheading = "Looking for inspiration? Try a sample prompt below?"; 
            this.input_placeholder = "Type your input/question...";
            this.popup_header1 = 'Guidance and Limitations';  
            this.popup_header2 = 'Capabilities';
            this.popup_header3 = 'Data that may be used';
            this.popup_header4 = 'Data that cannot be used'; 
            this.docstorage = 'Data Storage Policy';
            this.popup_subheader1 = 'This chatbot is powered by GPT 3.5 Turbo and GPT 4 from Azure Open AI and inherits its risks and limitations, including the following. As a result of these limitations, use of the output from the tool for research purposes is permitted. Where the tool is being used for the purpose of client delivery, practitioners are reminded to adhere to quality (including originality) expectations set by their business, relevant policies, and Deloitte’s Code of Conduct. At a minimum, prior human review of accuracy is necessary.'; 
            this.popup_subheader2 = 'This chatbot offers all Generative AI capabilities offered by GPT 3.5 Turbo and GPT 4 models from Azure OpenAI.'; 
            this.popup_subheader3 = 'Below are some examples of types of data approved to be used with this application.'; 
            this.popup_subheader4 = 'Below are some examples of types of data not approved to be used with this application unless explicit authorization has been provided by the identified owners of such data sources.';
            this.messagetoChat1 = 'Can you help me find information about [topic]?';
            this.messagetoChat2 = 'What are some reputable sources for information about [topic]?';
            this.messagetoChat3 = 'I am looking for statistics about [topic]. Can you help me find some?';
            this.messagetoChat4 = 'Text: [add text here]\nCan you summarize this article for me?';
            this.messagetoChat5 = 'Text: [add text here]\nFrom the above text, can you create an executive summary to be presented to the business sponsor';
            this.messagetoChat6 = 'Can you generate an email for me to send to [person] about [topic]?';
            this.messagetoChat7 = 'Can you generate a report on [topic] for me?';
            this.messagetoChat8 = 'Can you generate a proposal for [project]?';
            this.messagetoChat9 = 'Conduct a side-by-side comparison of [Technology1] and [Technology2] and present the results in a tabular form.';
            this.messagetoChat10 = 'Code: [add code here]\nCan you explain the core functionality of this script in a few sentences?';
            this.messagetoChat11 = 'Can you give me the code to generate an echo bot in python using RASA?';
            this.prompt_head1 = 'SEARCH';
            this.prompt_head2 = 'SUMMARIZE';
            this.prompt_head3 = 'CONTENT GENERATION';
            this.navigation1 = 'Skip';
            this.navigation2 = 'Next';
            this.navigation3 = 'Previous';
            this.navigation4 = 'Close';
            this.page1 = '1 OF 3';
            this.page2 = '2 OF 3';
            this.page3 = '3 OF 3';
            this.gend_guidelines = "Limited world knowledge: Though the model has access to a vast amount of information, its knowledge is limited by the data it has been trained on. It may not be able to provide accurate answers to questions outside of its training data. E.g. Events after 2021. Vulnerability to bias: Model may be susceptible to bias, as its responses are based on the data it has been trained on. If the training data is biased, the model may produce biased responses.Lack of reliability: Model may not always provide reliable information, as it is based on the data it has been trained on. This means that it may provide inaccurate or incomplete information, leading to poor decision- making.Inability to verify sources: Model may not be able to verify the sources of information it provides, which can lead to false or misleading information being presented as facts.Originality and IP: As the Chatbot relies on its training data to generate its outputs, there is a possibility that the generated output could be too close to the source text and potentially lead to copyright infringement. To help mitigate this copyright infringement risk, a practitioner should review and revise anything generated by the Chatbot before using it in any client facing materials, including deliverables. ";              
            this.pop1_msg1 = 'Limited world knowledge: Though the model has access to a vast amount of information, its knowledge is limited by the data it has been trained on. It may not be able to provide accurate answers to questions outside of its training data. E.g. Events after 2021.';
            this.pop1_msg2 = 'Vulnerability to bias: Model may be susceptible to bias, as its responses are based on the data it has been trained on. If the training data is biased, the model may produce biased responses.';
            this.pop1_msg3 = 'Lack of reliability: Model may not always provide reliable information, as it is based on the data it has been trained on. This means that it may provide inaccurate or incomplete information, leading to poor decision- making.';
            this.pop1_msg4 = 'Inability to verify sources:  Model may not be able to verify the sources of information it provides, which can lead to false or misleading information being presented as facts.';
            this.pop1_msg5 = 'Originality and IP: As the Chatbot relies on its training data to generate its outputs, there is a possibility that the generated output could be too close to the source text and potentially lead to copyright infringement. To help mitigate this copyright infringement risk, a practitioner should review and revise anything generated by the Chatbot before using it in any client facing materials, including deliverables. ';
            this.pop2_msg1 = 'Its capabilities include and are not limited to, general search, summarization and data generation. Note that the chatbot handles only text-based conversations.';
            this.pop2_msg2 = 'To the best possible extent, this chatbot is trained to provide answers based on data it is trained on, without scope for hallucination.';
            this.pop2_msg3 = 'The chatbot can remember previous conversations within the session and allow users to ask follow-up questions.';
            this.pop2_msg4 = 'Users can utilize the sample prompts or design the prompts to elicit more meaningful responses from the chatbot.';
            this.pop2_msg5 = 'User details and conversations will be stored for internal reporting purposes. However, identifiable details will not be disclosed as part of such reporting.';
            this.pop2_msg6 = 'Conversations and user details may be stored for internal reporting only.';
            this.pop2_msg7 = 'The chatbot may decline to answer inappropriate requests.';
            this.pop3_msg1 = 'Deloitte Data (provided they are not restricted from use or wide dissemination)';
            this.pop3_msg2 = 'Public Data';
            this.pop3_msg3 = 'Third Party Data purchased from Data Brokers';
            this.pop3_msg4 = 'Synthetic Data';
            this.pop3_msg5 = 'Personal Information';
            this.pop3_msg6 = 'Client Information Approved for Use with the AI Tool';
            this.pop4_msg1 = 'Deloitte Data';
            this.sub1_1 = 'Any information available on Deloitte’s external public website';
            this.sub1_2 = 'Deloitte employment opportunity listings';
            this.sub1_3 = 'Deloitte developed templates and proposals, quals, and approaches (must exclude all client details and intellectual property).';
            this.sub1_4 = 'Deloitte’s external, public facing marketing materials';
            this.sub1_5 = 'Deloitte policies, procedures and guidance documents, provided they are not restricted from use or wide dissemination';
            this.sub1_6 = 'Internal Deloitte business requirements/code (examples below)';
            this.sub1_7 = 'Deloitte training materials';
            this.sub1_8 = 'Other Deloitte confidential information that is not on the “Data NOT approved” list';
            this.sub2_1 = 'Information that is not protected by copyright or other specific terms of use.';
            this.sub2_2 = 'Information (public or proprietary) protected by trademark, copyright or specific use terms (e.g., website terms) for which permission to use has been obtained.';
            this.sub3_1 = 'Third-party data that has been procured or purchased for Deloitte use and you have confirmed with the third-party contract owner that your intended generative AI data use case would be permitted under the terms of that contract.';
            this.sub4_1 = 'Fake or mock data generated as a substitute for live or real data. Synthetic data does NOT include any real data, development, or production environment data, including confidential or personal information';
            this.sub5_1 = 'Aggregated, de-identified or anonymized personal information';
            this.sub6_1 = 'Use of Client information in this tool is subject to LCSP review and approval. Client information can only be used in accordance with the terms of our client agreements and related to the delivery of the agreed-upon services to that same client. Generally, if the use of client information is specifically to provide services to that client, and there are no restrictions against such use, then it may be used. If your LCSP is unsure about the confidentiality requirements in your client agreements, consult with the Office of General Counsel (OGC)';
            this.sub_1 = 'Board of Director minutes';
            this.sub_2 = 'Deloitte’s financial statements';
            this.sub_3 = 'Deloitte firm pricing model materials and rate cards';
            this.sub_4 = 'Processing information requiring a certification or adherence to a specific standard not held by Azure OpenAI (e.g., processing of payment card information as Azure OpenAI does not have a PCIDSS certification)';
            this.sub_5 = 'Individual level talent data';
            this.sub_6 = 'Individual level employee activity';
            this.sub_7 = 'Information (public or proprietary) where the terms of use for such information expressly prohibit the use (e.g., online journal data, or market research)';
            this.sub_8 = 'Data obtained from web scraping (i.e., using bots to extract content and data from a website)';
            this.sub_9 = 'Purchased third party data not procured or purchased for Deloitte use (e.g., data acquired from contractors or vendors)';
            this.sub_10 = 'Any individual level or identifiable personal information';            
            this.pfb = 'Your feedback is valuable to us';
            this.pfb_placeholder = 'What did you like about the response?';
            this.fb_skip = 'Skip this for now';
            this.fb_submit = 'Submit';
            this.nfb = 'Do you have any additional feedback? We’re listening.';
            this.nfb_placeholder = 'Tell us how we can improve.';
            this.fb_prompt = 'Was this response helpful/accurate?';
            this.copy_title = 'Copy code';
            this.option1 = 'Yes';
            this.option2 = 'No';
            this.fault = 'Sorry, something went wrong!! Please try again after some time.';
            this.welcomemsg = 'Hi, welcome to Gen-D! Go ahead and send a message.';
            this.sub1_6_1 = 'Intake Documents';
            this.sub1_6_2 = 'Business Requirement Documents';
            this.sub1_6_3 = 'Functional Requirement Documents';
            this.sub1_6_4 = 'Code or scripts related to Deloitte internal applications';
            this.instruction = 'Effective August 1, 2023';
            this.sub_instruction = 'As a result of these limitations, use of the output from the tool for research purposes is permitted. Where the tool is being used for the purpose of client delivery, practitioners are reminded to adhere to quality (including originality) expectations set by their business, relevant policies, and Deloitte’s Code of Conduct. At a minimum, prior human review of accuracy is necessary.';
            this.disclaimer = 'Gen-D may only be used by Deloitte professionals in the Canadian member firm, including Chile.';
            this.docHeader = "Document Analyzer allows users to ask questions based on uploaded documents, utilizing Retrieval Augmented Generation along with the GPT-4 and GPT-3 Embeddings model from Azure OpenAI. ";
            this.limitation = "Limitation";
            this.limitationdoc1 = "While the answers are generated using the context from the data uploaded, they may be prone to hallucinations. ";
            this.limitationdoc2 = "The application uses MS Form recognizer to read the data from the uploaded documents and applies Gen AI on this data. It is recommended to use text heavy documents and there may be issues resulting from inefficient data capturing from documents in some cases. ";
            this.limitationdoc3 = "Users will only be able to interact with the text and not the infographics within the documents. ";
            this.docpop3_msg1 = "use Document Analyzer to create work products using information from different clients (referred to as secondary use). The use of any Client Confidential Information pertaining to a different client, such as data previously collected or provided by another client in a promote and attachments is prohibited (e.g., do not reference documents related to three different clients for summarization)";              
            this.docpop3_msg2 = "use Document Analyzer if such use would violate any client contractual commitments. Discuss with your Lead Client Service Partner (“LCSP”) to confirm if client specific restrictions apply to your engagement";
            this.docpop3_msg3 = "use Personal information (information about an identifiable individual, including but not limited to Deloitte professionals, clients, their customers, or their employees, as defined in the Pri) in Document Analyzer for purposes that are not compatible with the original purpose for which that information was collected.";
            this.docpop3_msg4 = "You may use Public Information such as:";
            this.docpop3_msg5 = "You may use Deloitte Information such as:";
            this.docpop3_msg6 = "You may use Client Data:";
            this.docpop3_msg7 = "You may use Personal Information:";
            this.docsub1_1 = "Information (public or proprietary) protected by trademark, copyright or specific use terms (e.g., website terms) for which permission to use has been obtained.";
            this.docsub1_2 = "Information that is not protected by copyright or other specific terms of use.";          
            this.docsub2_1 = "Deloitte external facing marketing materials.";
            this.docsub2_2 = "Deloitte policies, procedures and guidance documents.";
            this.docsub2_3 = "Internal Deloitte business requirements/code.";
            this.docsub2_4 = "Deloitte training materials.";
            this.docsub3_1 = "In accordance with the terms of the client agreements and related to the delivery of the agreed-upon services to that same client, or";
            this.docsub3_2 = "To provide services to that client, and there are no restrictions against such use, or";
            this.docsub3_3 = "For which the LCSP has reviewed and approved";
            this.docsub4_1 = "Provided you have appropriate permission / consent to collect the information, and that the purpose for using the Personal Information is consistent with that for which it was originally collected (e.g. you are a Finance specialist comparing invoices that contain personal information for the purpose of identifying pricing discrepancies, or you are a hiring manager comparing interview notes to identify common themes among candidates). If you are uncertain, contact the Privacy Office";
            this.guidancePrivacy = "Data & Privacy Guidelines";
            this.docpopup_subheader_1 = 'Users can now interact with their documents by following these simple steps';
            this.docpopup_subheader_2 = "Tips to interact with multiple files ";             
            this.docpop1_msg1 = 'Under the “Documents Dock”, choose files you need to interact with and click “Upload”.';
            this.docpop1_msg2 = 'Once uploaded, the “Upload” button will be disabled until user clicks on “Reset” again.';
            this.docpop1_msg3 = 'On upload, the tool provides a summary of the documents uploaded, post which, users will be able to ask questions based on their content within.';
            this.docpop1_msg4 = 'User queries are matched with related sections of the documents uploaded and answers are generated based on this context. However, the application does not support follow up questions. Users are advised to be elaborate in their queries to get relevant responses.';
            this.docpop1_msg5 = 'Once complete, users can close the browser or click on “Reset” to remove the documents.';
            this.docpop1_msg6 = 'After “Reset”, users can select new documents to upload and interact with the same';
            this.docpop1_msg7 = 'Users can select upto 5 documents at once.';
            this.docpop2_msg1 = 'Once uploaded, the documents and their respective embeddings data is stored in a temporary cache to retrieve the answers to user queries.';
            this.docpop2_msg2 = 'Data is removed from the cache when the user clicks “Reset” or closes the session.';
            this.docpop2_msg3 = 'Data uploaded by the users is not utilized or cross referenced to provide answers to other users of the application.';
            this.docpop2_msg4 = 'The run time cache resides within Deloitte’s Azure tenet within the Canada region.';
            this.docpop2_msg5 = 'User details and conversations are retrieved within the interaction to enable users ask follow-up questions.';
            this.tips1 = 'Users can upload upto 5 files at once. It is recommended to upload related files together for enhanced context.';
            this.tips2 = 'Users will not be able to append to these selected documents once uploaded. Ex: if the users want to upload a third document that is also related to the once uploaded, they need to reset and upload them all together again.';
            this.transtip = "Tips for best experience with Transcripts ";
            this.transtip1 = "Summarize the content in the below structure";
            this.transtip2 = "What was <Name>’s contribution to the call? ";
            this.transtip1_1 = "Meeting Title :";
            this.transtip1_2 = "Key Discussion Points :";
            this.transtip1_3 = "List of All Speakers :";
            this.transtip1_4 ="Action points and Next Steps:  ";
            this.transtip3 = "Who is the organizer of the call?";
            this.transtip4 = "What happened in the second half of the meeting? ";
            this.guardrails = "Guardrails";
            this.howto = "How To";
            this.doc_analyzer = "Document Analyzer";
            this.orange = "Innovapost Integration";
            this.xls_msg = "Attention: XLS/XLSX support is currently in Beta testing phase." 
            this.var_new = " NEW" 
            break;
        }
      });
    });
  }
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  cardOptions: CardOptions = {
    size: Size.lg,
    isVertical: false,
    isSelectable: false,
    isClickable: false,
    isUnbordered: false,
    customClass: ''
  };
  headerOptions: HeaderOptions = {
    name: 'DBOT BETA',
    width: WidthState.full,
    theme: Themes.green,
    isResponsive: false,
    isInverse: false,
    customClass: ''
  };
  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.logo,
    HeaderElmnts.projectName,
    HeaderElmnts.profile,
    HeaderElmnts.nav,
    HeaderElmnts.icons,
    HeaderElmnts.search
  ];
  buttonNavOptions: ButtonOptions = {
    theme: Themes.green,
    size: Size.lg,
  };
  button1Options: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_sm dds-icon_external-link'
  };
 
  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
    .subscribe((profile:any) => {
      var NamesSplit23=profile.displayName.split(' ');
      profile.displayName=profile.displayName.replace(/ /g,'');
      if (NamesSplit23.length >= 2) {
        let nameLetter = '';
        for (let i = NamesSplit23.length - 1; i >= 0; i--) {
          nameLetter += NamesSplit23[i].slice(0, 1);
          if (nameLetter.length >= 2) {
            break;
          }
        }
        this.nameLetter = nameLetter;
      }
      this.sharingService.changeData(this.nameLetter); 
    })
  }

  togglebutton(){
    this.chatService.toggle_button();
    this.switchvalue = !this.switchvalue;
    const msgHTML = `
      <div class="msg left-msg">  
        <div class="col-2 msg-img-left">
        <img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>
        </div> 
          <div class="col-10 msg-bubble" style="color:black">
            <div class="msg-text-new"> ${this.switchvalue ? "{GPT 4} selected / {GPT 4} sélectionné" : "{GPT 3.5} selected / {GPT 3.5} sélectionné" }</div>
          </div>
      </div>       
   `; 
  }
  redirect_doc_analyzer(){
    this.var_project = "documentanalyzer";
    $("#limitations").css("display","block");
    $("#button_analyzer").addClass("button_analyzer_clicked");
    this.router.navigate(['/documentanalyzer'], { queryParams: { lang:this.lang1 } } );
    $("#button_gend").removeClass("button_gend_clicked");
    $("#button_orange").removeClass("orange_button_clicked");  
  }

  redirect_chat(){
    this.var_project = "chat";
    $("#limitations").css("display","block");
    this.router.navigate(['/chat'],{ queryParams: { lang:this.lang1 } });
    $("#button_gend").addClass("button_gend_clicked");
    $("#button_analyzer").removeClass("button_analyzer_clicked");
    $("#button_orange").removeClass("orange_button_clicked"); 
  }
  redirect_orange(){
    this.router.navigate(['/InnovapostIntegration'],{ queryParams: { lang:this.lang1 }}); 
    $("#button_gend").removeClass("button_gend_clicked"); 
    $("#button_analyzer").removeClass("button_analyzer_clicked");
    $("#button_orange").addClass("orange_button_clicked");
    this.var_project = "orange"; 
  }
}
