import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../../auth-config';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class ResetBlobStorageService {

  user_id:  any;
  constructor(private httpClient: HttpClient) { }
  refresBlobUrl = protectedResources.resetUploadedDocument.endpoint;
  getProfile() {
    this.httpClient.get(GRAPH_ENDPOINT)
    .subscribe((profile:any) => {
      // console.log("mail",profile.mail);
      this.user_id = profile.mail;
    })
    return this.user_id ;

  }
resetUploadedDocument(id : any): any {
  const headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'content-type': 'multipart/form-data'
  });

  return this.httpClient.get(  
    this.refresBlobUrl+"?userId="+ id, {headers : headers}
  // { headers: headers, params: { "userId": "gprajwalakeerthi@deloitte.ca" }, observe: 'response' }
   );
  }
}
