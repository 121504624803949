<!-- <app-chat-navbar></app-chat-navbar>  -->
<div class=""> 
  <!-- Sample Prompts pane on the left -->
  <div class="row"> 
    <div class="col-lg-3 col-md-4 col-sm-6" style="background-color: white; padding-left:0px; padding-right: 0px;max-height:100%;">      
        <div class="form-group text-center" style="max-height:100vh" >
          <button type="button" class="btn btn-lg btn-block rounded-0" style="background-color: #084E6C;color:white;width:100%; left:40px; height:40px;font-size:16px;text-align: left;"><img src="../assets/images/Bulb.PNG" height="30" width="35" alt="bulb icon"/>{{label1}}</button>
          <div class="prompt-scroll">
            <br>
            <div class="subhead">{{subheading}}</div>
            <br>
           <div class="row form-group prompt-style">
              <div style="font-weight:700;text-align:left;font-size:14px;">{{prompt_head1}}</div>
              <br>
              <br>
              <div class="row" style="padding-left:15px;">                  
                  <div class="prompt-border">
                    <div class="prompt-text-fixed" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat1)" onkeypress="">{{messagetoChat1}}</div>
                  </div>
                <div class="prompt-border">
                    <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat2)" onkeypress="">{{messagetoChat2}}</div>
                </div>
                <div class="prompt-border">
                    <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat3)" onkeypress="">{{messagetoChat3}}</div>
                </div>      
                <div class="prompt-border">
                    <div class="prompt-text"[ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat9)" onkeypress="">{{messagetoChat9}}</div>
                </div>          
              </div>         
              <div class="row"><div class="col-12"><br></div></div>
              <div style="font-weight:700;text-align:left;font-size:14px;">{{prompt_head2}}</div>
              <br>
              <br>
              <div class="row" style="padding-left:15px;"> 
                <div class="prompt-border">
                    <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat4)" onkeypress="">{{messagetoChat4}}</div>
                </div>
                <div class="prompt-border">
                  <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat5)" onkeypress="">{{messagetoChat5}}</div>
                </div>
                <div class="prompt-border">
                  <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat10)" onkeypress="">{{messagetoChat10}}</div>
                </div>
              </div>  
              <div class="row"><div class="col-12"><br></div></div>
              <div style="font-weight:700;text-align:left;font-size:14px;">{{prompt_head3}}</div>
              <br>
              <br> 
              <div class="row" style="padding-left:15px;">
                <div class="prompt-border">
                  <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat6)" onkeypress="">{{messagetoChat6}}</div>
                </div>
                <div class="prompt-border">
                  <div class="prompt-text-fixed" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat7)" onkeypress="">{{messagetoChat7}}</div>
                </div>
                <div class="prompt-border">
                  <div class="prompt-text-fixed" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat8)" onkeypress="">{{messagetoChat8}}</div>
                </div>
                <div class="prompt-border">
                  <div class="prompt-text" [ngClass]="{'disabled-div': disablePrompts}" (click)="sendAsMessage(messagetoChat11)" onkeypress="">{{messagetoChat11}}</div>
                </div>
              </div>      
          </div>
      </div> 
      </div>  
    </div>  
    <!-- Chat window on the right --> 
    <div class="col-lg-9 col-md-8 col-sm-6" id="chat_window">                     
            <section class="msger">
               <main class="msger-chat" id="chat">
                 <div class="msg left-msg">
                   <div class="msg-img-left" style="background-image: url(../assets/images/AITool.PNG)"></div>
                   <div class="msg-bubble">
                     <div class="msg-info">
                       <div class="msg-info-name">Welcome/Bonjour</div>
                     </div>
                     <div class="msg-text-new" style="color:#75787B;"> {{this.welcomemsg}} </div>
                   </div>
                 </div>
                 <div *ngIf="isShown" id="wait" class="waitstyle"><img src="../assets/images/load.gif" height="50" width="50" alt="loading icon"/><br></div>   
               </main>  
               <!-- input text box -->
               <form class="form-group msger-inputarea" id="inputarea" (keyup.enter)="SendMessage()" style=" display: flex; align-items: flex-end;">
                <div class="msger-input"> 
                 <textarea class="textbox" id="msgoninput" autofocus ng-value="messagetoChat" placeholder= "{{this.input_placeholder}}" [(ngModel)]='messagetoChat' [ngModelOptions]="{standalone: true}"></textarea>
                 <img src="../assets/images/Send1.png" (click)="SendMessage()" height="30" width="30" alt="forward arrow icon" id="send_input" onkeypress="">
                </div>
              </form>
            </section>                 
    </div>
  </div>
</div>