<!-- <app-analyzer-navbar></app-analyzer-navbar>  -->
<!-- <app-chat-navbar></app-chat-navbar> -->
<div class=""> 

  <!-- Modal popup screen-1 -->
  <!-- <div class="modal" id="limitations" style="padding-top:50px;padding-left:0%;" id="myModal1" role="dialog" tabindex="-1" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="row">
            <div class="col-1"><img src="../assets/images/Limitations.PNG" class="popup-images"></div> 
            <div class="col-11"><h4 class="modal-title">{{popup_header1}}</h4></div>
            <div class="col-1"><button type="button" class="close" data-dismiss="modal" (click)="closePopup()">
              <span aria-hidden="true">&times;</span>
            </button></div>
          </div>
          <br>
          <div class="row">
            <div class="col-12"><p class="popup-header-text">{{this.popup_subheader1}}</p> 
              <br>
            </div>
          </div>
          <br>
        <div class="modal-body">
        <br>
        <ol class="popup-body-text">
          <li>{{popup_subheader_1}}
          <ul>
            <li>{{pop1_msg1}}</li>
            <li>{{pop1_msg2}} </li>
            <li>{{pop1_msg3}}</li>
            <li>{{pop1_msg4}}</li>
            <li>{{pop1_msg5}}</li>
            <li>{{pop1_msg6}}</li>
            <li>{{pop1_msg7}}</li>
            <li>{{pop1_msg8}}</li> 
           </ul> 
          </li>                   
          <li>{{popup_subheader_2}}
          <ul>
            <li>{{pop11_msg1}}</li>
            <li>{{pop12_msg2}} </li>
          </ul> 
          </li>  
          <li>{{popup_subheader_3}}         
          <ul>
            <li>{{pop1_1_msg1}}</li>
            <li>{{pop1_2_msg2}} </li>
            <li>{{pop1_3_msg3}}</li>
          </ul> 
          </li>           
        </ol>
        </div>
      
        <div class="row sub_popup">
          <h5><b>{{sub_instruction}}</b></h5>
        </div>
        <br>
        <div class="row center" ><h6>{{page1}}</h6></div>
        <br>
        <div>
          <div class="row"></div>
          <div class="row" style="width:785px">
            <div class="col-5"><button type="button" class="skip-button" data-dismiss="modal" (click)="closePopup()">{{navigation1}}</button></div>
            <div class="col-2"></div>
            <div class="col-5"><button type="button" class="next-button" data-toggle="modal" (click)="openPopup('usecase')" data-dismiss="modal">{{navigation2}}</button></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Modal popup screen 2 -->
  <!-- <div class="modal" id="usecase" style="padding-top:50px;padding-left:0%;" role="dialog" tabindex="-1" [ngStyle]="{'display':display2}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="row"> -->
          <!-- <div class="col-1"><img src="../assets/images/Usecases.PNG" class="popup-images"></div> -->
          <!-- <div class="col-11"><h4 class="modal-title">{{popup_header2}}</h4></div>
          <div class="col-1"><button type="button" class="close" data-dismiss="modal" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
          </button></div>
        </div>
        <br>
          <div class="row">
          </div>
        <br>
      <div class="modal-body">
          <br>
          <ul class="popup-body-text">
            <li>{{pop2_msg1}}</li>
            <li>{{pop2_msg2}}</li>
            <li>{{pop2_msg3}}</li>
            <li>{{pop2_msg4}}</li>
            <li>{{pop2_msg5}}</li>
          </ul>
      </div>
      <br>
      <div class="row center" ><h6>{{page2}}</h6></div>
        <br>
        <div>
          <div class="row"></div>
          <div class="row" style="width:785px">
            <div class="col-5"><button type="button" class="skip-button" data-dismiss="modal" (click)="openPopup('limitations')">{{navigation3}}</button></div>
            <div class="col-2"></div>
            <div class="col-5"><button type="button" class="next-button" data-toggle="modal" (click)="openPopup('risk')" data-dismiss="modal">{{navigation2}}</button></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Modal popup screen-3 -->
  <!-- <div class="modal" id="risk" style="padding-top:50px;padding-left:0%;" role="dialog" tabindex="-1" [ngStyle]="{'display':display3}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="row">
          <div class="col-11"><h4 class="modal-title">{{popup_header3}}</h4></div>
          <div class="col-1"><button type="button" class="close" data-dismiss="modal" (click)="closePopup()">
            <span aria-hidden="true">&times;</span>
          </button></div>
        </div>
        <br>
         
      <div class="modal-body">
          <br>
          <ul class="popup-body-text">
            <div>
              <li> <b> DO NOT</b>{{pop3_msg1}} </li>
              <li> <b> DO NOT</b>{{pop3_msg2}} </li>
              <li> <b> DO NOT</b>{{pop3_msg3}} </li>
            </div>
            <div class="custom-tick"> <b> {{pop3_msg4}} </b>
              <ul> 
                <li> {{sub1_1}}</li>
                <li> {{sub1_2}}</li>
              </ul>
            </div>
            <div class="custom-tick">  <b> {{pop3_msg5}} </b>
              <ul> 
                <li> {{sub2_1}}</li>
                <li> {{sub2_2}}</li>
                <li> {{sub2_3}}</li>
                <li> {{sub2_4}}</li>
              </ul>
            </div>
            <div class="custom-tick">  <b> {{pop3_msg6}} </b>
              <ul> 
                <li> {{sub3_1}}</li>
                <li> {{sub3_2}}</li>
                <li> {{sub3_3}}</li>
              </ul>
            </div>
            <div class="custom-tick">  <b> {{pop3_msg7}} </b>
              <ul> 
                <li> {{sub4_1}}</li>
              </ul>
            </div>
          </ul>
      </div>
      <br>
           
      <div class="row center" ><h6>{{page3}}</h6></div>
        <br>
        <div>
          <div class="row"></div>
          <div class="row"  style="width:785px">
            <div class="col-5"><button type="button" class="skip-button" data-dismiss="modal" (click)="openPopup('usecase')">{{navigation3}}</button></div>
            <div class="col-2"></div>
            <div class="col-5"><button type="button" class="next-button" data-toggle="modal" (click)="closePopup()" data-dismiss="modal">{{navigation4}}</button></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- new side panel -->
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <div class="container-fluid" style="padding-left:0px;padding-right:0px;">
    <div class="row">
      <!-- left panel -->
      <div class="col-lg-3 col-md-4 col-sm-6 d-flex flex-column align-items-start " 
      style="padding-right:0px;height:91.5vh;font-size: 10px;">
        <!-- <div class="d-flex flex-column" style="position:fixed;width:100%"> -->
          <!-- <section style="width:25.2%;height:100%;position:fixed;"> -->
          <div class="row" style="width:110%;display:flex;flex-direction: column;">
            <div class="col-12 d-flex justify-content-start align-items-center" style="background-color:#084E6C; height:40px">
              <img src="../../assets/images/Bulb.PNG" alt="Bulb Image" height="30" width="35">
              <h2 style="padding-left:5px; color:white;font-size:16px;padding-top:5px;">{{label1}}</h2>
            </div>
          </div>
          <div class="row"><div class="col-12" style="height:5px"></div></div>
          <div class="row" style="width:100%;overflow:auto;overflow-x: hidden;">
            <div class="col-12 form-group text-center" style="max-height:100vh;margin-left:50px; margin-left:20px; padding-top:5px;">
              <div class="d-flex flex-column justify-content-start">
                <div class="d-flex justify-content-start">
                  <h2 style="font-size:16px;font-weight:bold">{{prompt_head1}}</h2>
                  <h2 style="color:red">*</h2> 
                </div>
                <div class="d-flex justify-content-start">
                  <span>PDF, DOCX, PPTX, TXT, XLSX or VTT.{{prompt_head2}}</span>
                </div>
              </div>
              <div>
                <div class="drop-zone dotted-box d-flex align-items-center justify-content-center" 
                  style="width:90%; height: 160px"
                  appDragdropfile
                  [isDragDropEnabled]="dragDropEnabled"
                  (filesDropped)="onFilesDropped($event)"  #dragDropElement>
                  
                  <!-- appDragdropfile (fileDroppped)="onFilesDropped($event) "> -->
                  <div>
                    <img src="../../assets/images/upload-file.png" alt="Upload Image" style="height: 20px;width:20px;">
                    <h3 style="padding-top:10px;font-size: 10px;font-weight:400;">{{prompt_head3}}</h3>
                  </div>          
                </div>
             </div>
             <div style="width:100%; padding-top:14px;text-align: left;">
              <input type="file" #fileInput  id="fileInput" accept=".pdf, .doc, .docx, .vtt, .pptx, .xlsx, .xls" (change)="onFileSelected(fileInput.files)" multiple  style="padding-left: 80px;padding-bottom: 3px;padding-top: 2px; height:100px;text-align: center;font-size: larger;">  
              <label for="fileInput" id="fileid"  style="background-color: black; font-size:12px ; color:white;height: 26px; width:fit-content;padding-top: 4px;">{{choose}}</label>
             </div>
             <div style="height:12px;"></div>
          
          
            <div *ngIf = "selectedFilesList && selectedFilesList.length > 0">
              <div style="height:31vh; min-height:31vh !important; overflow-y: auto;margin-right:25px;margin-bottom:3%;">
                <div class="d-flex justify-content-between align-items-center py-2" 
                *ngFor="let file of selectedFilesList"
                [ngClass]="{'uploaded-image-btm': file['isUploaded']}"
                style="border-bottom: 2px solid #dee2e6;height:40px">
                  <div style="display: flex;">
                    <img [ngClass]="{'uploaded-image': file['isUploaded']}"
                              src="../../assets/images/icon-ellipsis_special.png" 
                              alt="Upload Image" 
                              height="20" width="20"> 
                    <div class="file-name" style="font-size: 12px">{{file["file"].name}}</div>
                  </div>
                  <div>
                    <button (click)="deleteFile(file)" type="button" [disabled]="isSummarizeClicked" 
                         class="btn btn-danger btn-xs custom-btn-smaller ml-2">X</button>
                  </div>
                </div>
            </div>
            <div></div>
              <div *ngIf="selectedFilesList.length > 5" style="color: red; padding-top: 10px;">{{limit1}}</div>
              <div *ngIf="selectedFilesList.length <= 5">
                <div *ngFor="let file of selectedFilesList">
                  <div *ngIf="file['file'].size > 100000000" style="color: red; padding-top: 10px;">{{limit2}}{{file["file"].name}}{{limit3}} </div> 
                  <div *ngIf="file['file'].size <= 100000000"></div>                            
                </div>
                <div class="d-flex justify-content-between" style="margin-right:25px">
                  <div style="padding-right:3px;padding-left: -3px;"><button id="upload_button" [disabled]="isSummarizeClicked" (click)="uploadFiles()" 
                    type="button" class="d-flex button-doc-summarize justify-content-center" style="border:none;">
                    {{choice1}}</button> </div>
                    <div style="padding-right:-3px;padding-left: 3px;">
                    <button (click)="deleteFiles()" id="reset_button" [disabled]="true" 
                      type="button" 
                      class="d-flex button-doc-reset justify-content-center" style="border:none;">
                      {{choice2}}
                    </button> </div>
                  
                </div>
                <br>
                <div class="uploadmsg" id="upload_msg">{{msgonupload}}</div>  
              </div>
            </div>
          </div>
          </div>
          <!-- </section> -->

        


      </div>
      <!-- right panel -->
      <div class="col-lg-9 col-md-8 col-sm -6" style="padding-left:0px; padding-right: 0px;border:1px solid;border-color:#D0D0CE;"> 
      <section class="msger">
        <!--  <header class="msger-header">
           <div class="msger-header-title">
             <i class="fas fa-comment-alt"></i> SimpleChat
           </div>
           <div class="msger-header-options">
             <span><i class="fas fa-cog"></i></span>
           </div>
         </header> -->
     
         <main class="msger-chat" id="chat">
           <div class="msg left-msg" id="welcome_msg">
             <div
             class="msg-img-left"
             style="background-image: url(../assets/images/AITool.PNG)"
             ></div>
     
             <div class="msg-bubble">
               <div class="msg-info">
                 <div class="msg-info-name">Welcome/Bonjour</div>
                 <!-- <div class="msg-info-time">12:45</div> -->
               </div>
     
               <div class="msg-text-new" style="color:#75787B;">
                 {{this.welcomemsg}} 
               </div>
             </div>
           </div>
           <div *ngIf="isShown" id="wait" class="waitstyle">
            <img src="../assets/images/load.gif" height="50" width="50" alt="loading_icon"/><br>
            <p class="loadingtext">{{loadmsg}}</p>
           </div>
           <div *ngIf="isAns" id="wait" class="waitstyle">
            <img src="../assets/images/load.gif" height="50" width="50" alt="loading_icon"/><br>
           </div>
         </main>
        <!-- check -->
        <!-- <div id="wait" class="waitstyle"><img src="../assets/images/Loading.gif"></div> -->
        
          <form class="form-group msger-inputarea" id="inputarea" (keyup.enter)="SendMessage()" style=" display: flex; align-items: flex-end;">
          <div class="msger-input"> 
           <!-- <input autoSizeInput type="text" class="textbox" id="msgoninput"  ng-value="messagetoChat" placeholder="Type your message/question..." [(ngModel)]='messagetoChat' [ngModelOptions]="{standalone: true}"/>  -->
           <textarea [disabled]="true"  class="textbox" id="msgoninput" autofocus ng-value="messagetoChat" [(ngModel)]='messagetoChat' [ngModelOptions]="{standalone: true}"></textarea>
           <!-- <input type="text" class="textbox" id="msgoninput" ng-value="messagetoChat"  id="inputText" placeholder="Type your message/question..." [(ngModel)]='messagetoChat' [ngModelOptions]="{standalone: true}"/> -->
           <img src="../assets/images/Send1.png" (click)="SendMessage()" height="30" width="30" id="send_input" alt="forward arrow icon" onkeypress="">
        </div>
        </form>
  </section>  
      </div>
    </div>
  </div>
  <!-- old side panel -->
  <!-- <div class="row">  
    <div class="col-lg-3 col-md-4 col-sm-6 d-flex align-items-start justify-content-center" 
      style="padding-right:0px;height:100vh;font-family: Open Sans;font-size: 10px;">    
      <div style="width:100%;display:flex;flex-direction: column;">
        <div class="d-flex flex-column justify-content-start">
          <div  class="d-flex justify-content-start align-items-center"
                style="background-color:#084E6C; height:40px">
            <img src="../../assets/images/Bulb.PNG" alt="Upload Image" style="height: 30px;width:35px;">
            <h2 style="padding-left:5px; color:white;font-size:16px;padding-top:5px;">Documents Dock</h2>
          </div>
        </div>
        <div 
          class="form-group text-center" 
          style="max-height:100vh;margin-left:50px; margin-left:20px; padding-top:5px" 
          >
    
            <div class="d-flex flex-column justify-content-start">
              <div class="d-flex justify-content-start"><h2 style="font-size:16px;font-weight:bold">Upload files</h2><h2 style="color:red">*</h2></div>
              <div class="d-flex justify-content-start"><span>PDF, DOCX, PPTX, TXT or VTT. Max size of 100MB</span></div>
            </div>
            <div style="width:100%">
                  <div class="drop-zone dotted-box d-flex align-items-center justify-content-center" 
                    style="width:90%; height: 160px" appDragdropfile (fileDroppped)="onFilesDropped($event)">
                    <div>
                      <img src="../../assets/images/upload-file.png" alt="Upload Image" style="height: 20px;width:20px;">
                      <h3 style="padding-top:10px;font-size: 10px;font-weight:400;"> Drag and Drop file here</h3>
                    </div>
            
                  </div>
            </div>
            <div style="width:100%; padding-top:14px;; text-align: left;">
              <input type="file" #fileInput  id="fileInput" accept=".pdf, .doc, .docx, .vtt, .pptx" (change)="onFileSelected(fileInput.files)" multiple  style="padding-left: 80px;padding-bottom: 3px;padding-top: 2px; height:100px;text-align: center;font-size: larger;">  
              <label for="fileInput" id="fileid"  style="background-color: black; font-size:12px ; color:white;height: 26px; width:100px;padding-top: 4px;"> Choose file </label>
            </div>
          

          
            <div class="mt-3" *ngIf=" selectedFilesList && selectedFilesList.length > 0 ">

              <div style="height:170px; min-height:170px !important; overflow-y: auto;margin-right:25px;">
                <div class="d-flex justify-content-between align-items-center py-2" 
                    *ngFor="let file of selectedFilesList"
                    [ngClass]="{'uploaded-image-btm': file['isUploaded']}"
                    style="border-bottom: 2px solid #dee2e6;height:40px">

                      <div style="display: flex;">
                        <img [ngClass]="{'uploaded-image': file['isUploaded']}"
                              src="../../assets/images/icon-ellipsis_special.png" 
                              alt="Upload Image" 
                              style="height: 20px;width:20px;"> 
                        <div  class="file-name" style="font-size: 12px">{{file["file"].name}}</div>
                      </div>
                    <div>
                     
                        <button (click)="deleteFile(file)" type="button" [disabled]="isSummarizeClicked" 
                         class="btn btn-danger btn-xs custom-btn-smaller ml-2">
                         X
                        </button>
                    </div>
                </div>
              
                </div>
                <div *ngIf="selectedFilesList.length > 5" style="color: red; padding-top: 10px;">   Please select less than 5 documents to Summarize. 
                </div>
              <div *ngIf="selectedFilesList.length <= 5" style="color: red; padding-top: 10px;margin-right:30px;"> 
              <div *ngFor="let file of selectedFilesList" style="color: red; padding-top: 10px;"> 
                <div *ngIf="file['file'].size > 100000000" style="color: red; padding-top: 10px;">The file size of {{file["file"].name}} is greater than 100MB. Please upload file with size less than 100MB.</div>             
                <div *ngIf="file['file'].size <= 100000000" style="color: red; padding-top: 10px;">
                  <div class="d-flex justify-content-between" style="width:100%; padding-top:14px;">
                    
                    <div style="padding-right:3px;padding-left: -3px;"><button [disabled]="isSummarizeClicked" (click)="uploadFiles()" 
                    type="button" class="d-flex button-doc-summarize justify-content-center" style="border:none;">
                    Upload</button> </div>
                    <div style="padding-right:-3px;padding-left: 3px;">
                    <button (click)="deleteFiles()" id="reset_button" [disabled]="true" 
                      type="button" 
                      class="d-flex button-doc-reset justify-content-center" style="border:none;">
                      Reset
                    </button> </div>
                </div>
              </div>
            </div>
            </div>

            </div>


  
          </div> 
      </div>
    </div>
      
    <div class="col-lg-9 col-md-8 col-sm -6" style="padding-left:0px; padding-right: 0px;border:1px solid;border-color:#D0D0CE;">    
                      
            <section class="msger">
           
               <main class="msger-chat" id="chat">
                 <div class="msg left-msg" id="welcome_msg">
                   <div
                   class="msg-img-left"
                   style="background-image: url(../assets/images/AITool.PNG)"
                   ></div>
           
                   <div class="msg-bubble">
                     <div class="msg-info">
                       <div class="msg-info-name">Welcome/Bonjour</div>
                      
                     </div>
           
                     <div class="msg-text" style="color:#75787B;">
                       {{this.welcomemsg}} 
                     </div>
                   </div>
                 </div>
                 <div *ngIf="isShown" id="wait" class="waitstyle">
                  <img src="../assets/images/load.gif" class="loadingstyle"/><br>
                  <p class="loadingtext">Processing the Files, please wait!</p>
                 </div>
                 
               </main>
              
              
                <form class="form-group msger-inputarea" id="inputarea" (keyup.enter)="SendMessage()" style=" display: flex; align-items: flex-end;">
                <div class="msger-input"> 
                 
                 <textarea [disabled]="true"  class="textbox" id="msgoninput" autofocus ng-value="messagetoChat" placeholder= "{{this.input_placeholder}}" [(ngModel)]='messagetoChat' [ngModelOptions]="{standalone: true}"></textarea>
                 
                 <img src="../assets/images/Send1.png" (click)="SendMessage()" class="send" id="send_input">
              </div>
              </form>
        </section>              
    </div>
  </div> -->
  </div>
