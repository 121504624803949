
 <!-- Modal popup screen-1 -->

<!-- Modal popup screen new -->
<div class="modal" id="limitations"  *ngIf="switcpopupmain || switcpopup" style="padding-top:50px;padding-left:0%;" id="myModal" role="dialog"  [ngStyle]="{'display': displayStylepopup}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="row">
          <div class="col-11" ><h4 >                     
              <dds-tabs [theme]="tabContainerOptions.theme"
              [themeType]="tabContainerOptions.themeType"
              [size]="tabContainerOptions.size"
              [isResponsive]="tabContainerOptions.isResponsive"
              [customClass]="tabContainerOptions.customClass"
              [ariaLabel]="tabContainerOptions.ariaLabel" style="overflow-y:auto;height:60em;">
                  <dds-tab [label]="guardrails">                 
                      <br>
                      <div class="col-lg-3">                                               
                        <dds-vertical-tabs [theme]="verticaltabContainerOptions.theme"
                        [themeType]="verticaltabContainerOptions.themeType"
                        [size]="verticaltabContainerOptions.size" 
                        >    
                        <dds-vertical-tab [label]="'Gen-D'"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged($event)">
                        </dds-vertical-tab>
                        <dds-vertical-tab [label]="doc_analyzer"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged($event)">
                        </dds-vertical-tab>
                    </dds-vertical-tabs>
                      </div>
                      <div class="col-lg-9" *ngIf="guideLinesLimitations == 'Gen-D'" style="max-height: 26em; overflow-y: scroll">
                        <div >
                        <b>{{this.popup_subheader1}}</b>
                          <br>  &nbsp;
                          <ol>
                            <li>{{pop1_msg1}}</li>
                            <li>{{pop1_msg2}}</li>
                            <li>{{pop1_msg3}}</li>
                            <li>{{pop1_msg4}}</li>
                            <li>{{pop1_msg5}}</li>
                          </ol><br>
                          <h4><b>{{popup_header3}}</b></h4>
                          <br>
                          {{this.popup_subheader3}} &nbsp;
                          <ol>
                            <li>{{pop3_msg1}}
                              <ul>
                                <li>{{sub1_1}}</li>
                                <li>{{sub1_2}}</li>
                                <li>{{sub1_3}}</li>
                                <li>{{sub1_4}}</li>
                                <li>{{sub1_5}}</li>
                                <li>{{sub1_6}}
                                  <ul>
                                    <li>{{sub1_6_1}}</li>
                                    <li>{{sub1_6_2}}</li>
                                    <li>{{sub1_6_3}}</li>
                                    <li>{{sub1_6_4}}</li>
                                  </ul>
                                </li>
                                <li>{{sub1_7}}</li>
                                <li>{{sub1_8}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg2}}
                              <ul>
                                <li>{{sub2_1}}</li>
                                <li>{{sub2_2}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg3}}
                              <ul>
                                <li>{{sub3_1}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg4}}
                              <ul>
                                <li>{{sub4_1}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg5}}
                              <ul>
                                <li>{{sub5_1}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg6}}
                              <ul>
                                <li>{{sub6_1}}</li>
                              </ul>
                            </li>
                          </ol>
                          <br>
                          <h4><b>{{popup_header4}}</b></h4>
                          <br>
                          {{this.popup_subheader4}} &nbsp;
                          <ol>
                          <li>{{pop4_msg1}}
                            <ul>
                              <li>{{sub_1}}</li>
                              <li>{{sub_2}}</li>
                              <li>{{sub_3}}</li>
                              <li>{{sub_4}}</li>
                              <li>{{sub_5}}</li>
                              <li>{{sub_6}}</li>
                            </ul>
                          </li>
                          <li>{{pop3_msg2}}
                            <ul>
                              <li>{{sub_7}}</li>
                              <li>{{sub_8}}</li>
                            </ul>
                          </li>
                          <li>{{pop3_msg3}}
                            <ul><li>{{sub_9}}</li></ul>
                          </li>
                          <li>{{pop3_msg5}}
                            <ul><li>{{sub_10}}</li></ul>
                          </li>
                        </ol> 

                        </div>
                      </div>
                      <div class="col-lg-9" *ngIf="guideLinesLimitations == doc_analyzer" style="max-height: 26em; overflow-y: scroll;">
                        <div>
                          {{docHeader}}
                          <br><br>
                         <h4> <b>{{limitation}}</b></h4>
                          <ul>
                            <li>{{xls_msg}}</li>
                               <li>{{limitationdoc1}}</li>
                               <li>{{limitationdoc2}}</li>
                               <li>{{limitationdoc3}}</li>
                          </ul> 
                          <br>
                          <h4><b>{{guidancePrivacy}}</b></h4>
                          <ul >
                            <div>
                              <li> <b> DO NOT </b>{{docpop3_msg1}} </li>
                              <li> <b> DO NOT </b>{{docpop3_msg2}} </li>
                              <li> <b> DO NOT </b>{{docpop3_msg3}} </li>
                            </div>
                            <div class="custom-tick"> <b> {{docpop3_msg4}} </b>
                              <ul> 
                                <li> {{docsub1_1}}</li>
                                <li> {{docsub1_2}}</li>
                              </ul>
                            </div>
                            <div class="custom-tick">  <b> {{docpop3_msg5}} </b>
                              <ul> 
                                <li> {{docsub2_1}}</li>
                                <li> {{docsub2_2}}</li>
                                <li> {{docsub2_3}}</li>
                                <li> {{docsub2_4}}</li>
                              </ul>
                            </div>
                            <div class="custom-tick">  <b> {{docpop3_msg6}} </b>
                              <ul> 
                                <li> {{docsub3_1}}</li>
                                <li> {{docsub3_2}}</li>
                                <li> {{docsub3_3}}</li>
                              </ul>
                            </div>
                            <div class="custom-tick">  <b> {{docpop3_msg7}} </b>
                              <ul> 
                                <li> {{docsub4_1}}</li>
                              </ul>
                            </div>
                          </ul>   
                          <br>
                          <h4><b>{{docstorage}}</b></h4>
                        <ul>
                          <li>{{docpop2_msg1}}</li>
                          <li>{{docpop2_msg2}}</li>
                          <li>{{docpop2_msg3}}</li>
                          <li>{{docpop2_msg4}}</li>
                          <li>{{docpop2_msg5}}</li>

                        </ul>                   
                        </div>
                      </div>
                     
                  </dds-tab>
                  <dds-tab [label]="howto">
                    <br>
                    <div class="col-lg-3">                                               
                      <dds-vertical-tabs [theme]="verticaltabContainerOptions.theme"
                      [themeType]="verticaltabContainerOptions.themeType"
                      [size]="verticaltabContainerOptions.size" 
                      >    
                      <dds-vertical-tab [label]="'Gen-D'"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged1($event)">
                      </dds-vertical-tab>
                      <dds-vertical-tab [label]="doc_analyzer"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged1($event)">
                      </dds-vertical-tab>
                  </dds-vertical-tabs>
                    </div>  
                    <div class="col-lg-9" *ngIf="guideLinesLimitations1 == 'Gen-D'" style="max-height: 26em; overflow-y: scroll">
                      <div >
                        {{this.popup_subheader2}}
                        <br><br>  &nbsp;
                        <ol>
                          <li>{{pop2_msg1}}</li>
                          <li>{{pop2_msg2}}</li>
                          <li>{{pop2_msg3}}</li>
                          <li>{{pop2_msg4}}</li>
                          <li>{{pop2_msg5}}</li>
                          <li>{{pop2_msg6}}</li>
                        </ol><br>
                        <div class="subhead1">{{subheading}}</div>
                        <h4><b>{{prompt_head1}}</b></h4>
                        <ul>
                              <li>{{messagetoChat1}}</li>
                              <li>{{messagetoChat2}}</li>
                              <li>{{messagetoChat3}}</li>
                              <li>{{messagetoChat9}}</li>
                        </ul>                      
                        <br>
                        <h4><b>{{prompt_head2}}</b></h4>
                        <ul>
                              <li>{{messagetoChat4}}</li>
                              <li>{{messagetoChat5}}</li>
                              <li>{{messagetoChat10}}</li>
                        </ul> 
                        <br>
                        <h4><b>{{prompt_head3}}</b></h4>
                        <ul>
                              <li>{{messagetoChat6}}</li>
                              <li>{{messagetoChat7}}</li>
                              <li>{{messagetoChat8}}</li>
                              <li>{{messagetoChat11}}</li>
                            </ul> 
                        
                      </div>
                    </div>
                    <div class="col-lg-9" *ngIf="guideLinesLimitations1 == doc_analyzer" style="max-height: 26em; overflow-y: scroll;">
                      <div>
                        <ol>
                          <li>{{this.docpopup_subheader_1}}
                            <ul>
                              <li> {{docpop1_msg1}} </li>
                              <li> {{docpop1_msg2}} </li>
                              <li> {{docpop1_msg3}} </li>
                              <li> {{docpop1_msg4}} </li>
                              <li> {{docpop1_msg5}} </li>
                              <li> {{docpop1_msg6}} </li>
                              <li> {{docpop1_msg7}} </li>
                            </ul>
                          </li>
                          <br>
                          <li>{{this.docpopup_subheader_2}}
                            <ul>
                              <li> {{tips1}} </li>
                              <li> {{tips2}} </li>
                            </ul>
                          </li>
                          <br>
                          <li>{{this.transtip}}
                            <ul>
                              <li> {{transtip1}}                                 
                                <ol>
                                  <li> {{transtip1_1}} </li>
                                  <li> {{transtip1_2}} </li>
                                  <li> {{transtip1_3}} </li>
                                  <li> {{transtip1_4}} </li>
                                </ol>
                              
                              </li>
                              <li> {{transtip2}} </li>
                              <li> {{transtip3}} </li>
                              <li> {{transtip4}} </li>
                            </ul>
                          </li>
                        </ol>                        
                      </div>
                    </div>
                  </dds-tab>
              </dds-tabs>
          </h4></div>
          <div class="col-1"><button type="button" class="close" data-dismiss="modal" (click)="closePopup1()">
            <span aria-hidden="true">&times;</span>
          </button></div>
        </div>
        <br> 
      <br>
    </div> 
  </div>
</div> 

<div class="">
  <div class="container-fluid">
  <div class="row menubar">
      <div class="col-2"><img src="../assets/images/Deloitte_logo.PNG" alt="deloitte logo" class="deloitte_logo"></div>
      <div class="col-1"><button id="button_gend" type="button" class="gend_button" (click)="redirect_chat()">Gen-D</button></div>
      <div class="col-1"><button id="button_analyzer" type="button" class="analyzer_button" (click)="redirect_doc_analyzer()">{{doc_analyzer}}</button></div>
      <div class="col-2"></div>
      <!-- <div class="col-2"><button *ngIf="uservalid" id="button_orange" type="button" class="orange_button" (click)="redirect_orange()">{{orange}}</button></div> -->
      <!-- <div class="col-1"></div> -->
      <div class="col-3" style="margin-left:5%;padding-left:100px;margin-top:15px">
        <button class="hover_button" type="button" >GPT 3.5
          <span class="tooltipText3">Token Limit: 13000 <br> Speed: High <br> Reasoning: Medium <br> Training upto: Sep 2021</span>
        </button>
        
        <label class="switch">
          <div [ngSwitch]="var_project">
            <div *ngSwitchCase="'orange'">
              <input  id="toggle_bar_chat" type="checkbox" disabled="true">
              <span id="sliderbar_chat" class="slider round"></span>
            </div>
            <div *ngSwitchCase="'documentanalyzer'">
              <input  id="toggle_bar_analyzer" type="checkbox" disabled="true">
              <span id="sliderbar_analyzer" class="slider round"></span>
            </div>
            <!-- <div *ngSwitchCase = "'orange'">
              <input  id="toggle_bar_orange" type="checkbox" disabled="true">
              <span id="sliderbar_orange" class="slider round"></span>
            </div> -->
            <div *ngSwitchDefault>
              <input  id="toggle_bar_orange" type="checkbox" (click)="togglebutton()" onkeypress="">
              <span id="sliderbar_orange" class="slider round"></span>
            </div>
          </div>
        </label>
      
        <button class="hover_button" type="button">GPT 4o<sup style="font-weight:600;color:orange">{{var_new}}</sup>
          <span class="tooltipText4">Token Limit: 13000 <br> Speed: High <br> Reasoning: High <br> Training upto: Oct 2023</span>
        </button>
      </div> 
      <div class="col-1"><button class="nav-button" type="submit" (click)="openPopup1()">{{help_popup}}</button></div>
      <div class="col-1 lang_dropdown">
        <select name="lang" id="lang" (change)="OnChange()" [(ngModel)]="lang1">
          <option value="en">EN</option>
          <option value="fr">FR</option>
        </select>
      </div>
      <div class="col-1 initials">{{nameLetter}}</div>
  </div>
  </div>
 </div>