import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams} from '@angular/common/http';
import { protectedResources } from '../../auth-config';
 import {HttpHeaders} from '@angular/common/http';
 import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
 import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { environment } from '../environments/environment';
 //import { Headers, RequestOptions, Response } from '@angular/common/http';
 //import { RequestOptions} from '@angular/http';
 //import { AppComponent } from '../app.component';
 const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Injectable({
  providedIn: 'root'
})
// export class Message{
//   constructor(public author: string, public content: string) { }
// }
export class ChatService {
  
  gptModel: any;
  userQuery: any; 
  user_id: any;
  switch_value: boolean = false;
  private chatGptUrl: string = 'https://botacsapidev-openapipoc.azcactniprdase01.appserviceenvironment.net/OpenAPI/OpenAPIResults?userQuery=';
  constructor(private httpClient: HttpClient,private https: HttpClient,  private http: HttpClient) { }
  apiurl = protectedResources.apiDBot.endpoint;
  summaryApiUrl = protectedResources.searchSummaryDocument.endpoint;
  orangeApiUrl = protectedResources.orange.endpoint;
  verifyApiUrl = protectedResources.verifyuser.endpoint;  
  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.profile
  ];
  toggle_button(){
   this.switch_value = !this.switch_value;
   //console.log("switchvalue",this.switch_value)
   if(this.switch_value == true){
    this.gptModel = "gpt4o";
   } 
   else{
    this.gptModel = "gpt3.5";  
   } 
  }
  toggle_analyzer(){
    this.switch_value = false;
    // console.log("gpt3");
  }
  toggle_value(){
    return this.switch_value; 
  }
  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
    .subscribe((profile:any) => {
      //console.log(profile.mail);
      this.user_id = profile.mail;
      
    })
  }

  getSearchResult(search:any, convId:any): Observable<any> { 
      this.getProfile();
      const headers = new HttpHeaders();
      headers.set('access-control-allow-origin',["*"]);
      headers.set('content-type','application/json');
      headers.set('Accept', 'application/json;charset=UTF-8');
      headers.set('responseType','text');
      headers.set('Authorization','Bearer');
      const params = new HttpParams();
      params.append('responseType','text');
      params.append('observe','response');
      //headers.set('access-control-allow-origin': ["*"], 'content-type': 'application/json','responseType': 'text');
      //const headers = { 'access-control-allow-origin': ["*"], 'content-type': 'application/json',  'responseType': 'text'} ;
      //const options = ({responseType: 'text',observe: 'response',headers:'headers'});
      const options ={
        headers : 'headers',
        params : 'params'
      }
      //let options = {responseType: 'text',observe: 'response',headers:'headers'};
      // const body: any =JSON.stringify(search); 
      // console.log("Headers : ",headers)
       //console.log("URL : ",+search)
       //console.log("loop",this.switch_value)
       if(this.switch_value == true){
        return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id,"gptModel":"gpt4o", "convId": convId});
       }
       else{
        return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id,"gptModel":"gpt3", "convId": convId});
       }
       //return this.httpClient.get(this.apiurl+"OpenAPI/OpenAPIResults?userQuery="+search,{responseType: 'text',observe: 'response'})
       //return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id});
       //return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{userQuery});
  }
  // getOrangeSearchResult(): Observable<any>{
  //   return this.httpClient.post();
  // }
  getOrangeResult(search:any): Observable<any>{
    let headers3 = new HttpHeaders({
      'content-Type' : 'application/json',  
      'access-control-allow-origin': ["*"],
    });
    return this.httpClient.post(this.orangeApiUrl,{"prompt":search},{headers: headers3});   
  }
  getDocumentResult(id:any, search:any): Observable<any> { 
    
    this.getProfile();
    let headers2 = new HttpHeaders({
      'content-Type': 'application/json',
      'access-control-allow-origin': ["*"],
    });
          
    const body = {
      userId: id,
      prompt: search
      
    };
// console.log("userId:", id);
   return this.http.post(
      this.summaryApiUrl, {
        userId: id,
        prompt: search
        
      } , {headers : headers2})

    //return this.httpClient.post(this.summaryApiUrl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id,"gptModel":"gpt4"});
    // debugger;
    // //return openaiResponse;

    // if(this.switch_value == true){
    //   return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id,"gptModel":"gpt4"});
    //  }
    // else{
    //   return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id,"gptModel":"gpt3"});
    //  }
     //return this.httpClient.get(this.apiurl+"OpenAPI/OpenAPIResults?userQuery="+search,{responseType: 'text',observe: 'response'})
     //return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"userId":this.user_id});
     //return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{userQuery});
}
  verifyUser(userId:any):Observable<any>{
    return this.httpClient.post(this.verifyApiUrl+"?userEmail="+userId,{});
  }
  
  verifyPreviewUser():Observable<any>{
    return this.httpClient.post(this.apiurl+"/OpenAPI/VerifyPreviewUser",{});
  }
  downloadDocument(fileName: string) {
    //debugger;
    //console.log("url",this.apiurl + "api/Onboarding/DownloadDocument?fileName="+fileName);
    return this.httpClient.get(this.apiurl + "api/Onboarding/DownloadDocument?fileName="+fileName, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    })
  }
}
