import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { DocumentSummaryComponent } from './document-summary/document-summary.component';
import { OrangeComponent } from './orange/orange.component';

const routes: Routes = [
  {path: '', component:MainPageComponent,canActivate: [MsalGuard]  }, 
  {path:"chat",component:MainPageComponent,canActivate: [MsalGuard]},
  {path:"documentanalyzer",component:DocumentSummaryComponent,canActivate: [MsalGuard]},
  {path:"InnovapostIntegration",component:OrangeComponent,canActivate: [MsalGuard]},
  {path :"[queryParams]='{lang: value}'",component:MainPageComponent,canActivate: [MsalGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }