import { Component, ElementRef, OnDestroy, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { HttpClient} from "@angular/common/http";
import { v4 as uuidv4 } from 'uuid';
import { Observable, Subject } from 'rxjs';
import { ChatService} from '../Services/chat.service';
import { environment } from '../environments/environment';
import { SharingmessageService } from '../Services/sharingmessage.service';
import { HttpResponse } from '@angular/common/http';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-orange',
  templateUrl: './orange.component.html',
  styleUrls: ['./orange.component.css']
})
export class OrangeComponent implements OnDestroy,AfterContentChecked{
  //@ViewChild('downloadLink') downloadLink!: ElementRef<HTMLAnchorElement>;
  index = 0;
  label1:any ='';
  subheading:any ='';
  prompt_head1:any =''; 
  messagetoChat1:any = '';  
  messagetoChat2:any = '';  
  messagetoChat3:any = '';  
  messagetoChat9:any = '';  
  prompt_head2:any = '';  
  messagetoChat4:any = '';  
  messagetoChat5:any = '';
  messagetoChat10:any = ''; 
  prompt_head3:any = '';  
  messagetoChat6:any = '';
  messagetoChat7:any = '';  
  messagetoChat8:any = '';  
  messagetoChat11:any = ''; 
  welcomemsg:any = ''; 
  filename:any = ''; 
  input_placeholder:any = ''; 
  guid:any = '';  
  isShown: boolean = false ; 
  messagetoChat :any;
  name:any; 
  id:any;
  userName:string = ""; 
  userIdLetter:any; 
  markerWithCode = '';
  references:any = '';
  urls:any = '';
  botName:string = "";
  botImg:any = "../assets/images/AITool.PNG";
  usermessage1:any ;
  isError: boolean = false;
  fault: any = '';
  cid:any = '';
  pfb:any = ''; 
  pfb_placeholder:any = ''; 
  fb_skip:any = ''; 
  fb_submit:any = ''; 
  nfb:any = ''; 
  nfb_placeholder:any = ''; 
  fb_prompt:any = ''; 
  likeImage = '../assets/images/Like.PNG';
  valueimage = "../assets/images/Liked.PNG";
  disLikeImage = "../assets/images/Dislike.PNG";
  disLikedImage = "../assets/images/Disliked.PNG";
  option2:any;
  option1:any;
  tablecode:any = ""; 
  countvalue = 0;
  language = "";
  copy_title:any = '';
  prompt_head4:any = '';  
  prompt_head5:any = '';
  messagetoChat12:any = '';
  messagetoChat13:any = '';
  messagetoChat14:any = ''; 
  messagetoChat15:any = '';
  messagetoChat16:any = '';
  messagetoChat17:any = '';
  messagetoChat18:any = '';
  hyperlinktext = "This is a hyperlink: https://www.youtube.com.. I am testing this if this is becoming a hyperlink or not";  
  disablePrompts: boolean = false;
  constructor(
    private https: HttpClient, private http: HttpClient, private i18n: I18nService,private sharingService:SharingmessageService, private router:Router, private route: ActivatedRoute,
    private chatService: ChatService, private elementRef: ElementRef  ) {
      this.guid = uuidv4();
     }
  private destroy = new Subject<void>();
  ngOnInit(){
    $("#msgoninput").keypress(function(e){
      if(e.which == 13 && !e.shiftKey) {
        e.preventDefault();
      }
    });
    this.getProfile();
    this.cid = uuidv4();
    this.sharingService.obs.subscribe(res => this.getdata(res)); 
    this.route.queryParams.subscribe(params => {
      var lang = params['lang'];
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        switch (lang) {
          case "fr": 
            this.label1 = "Exemples de questions";
            this.subheading = " Vous cherchez de l’inspiration? Essayez un exemple de question ci-dessous! "; 
            this.prompt_head1 = 'Mobile & Ordinateur portable';
            this.messagetoChat1 = 'Que dois-je faire avec mon appareil mobile Innovapost?';
            this.messagetoChat2 = "Puis-je installer des applications sur mon ordinateur portable Windows?";
            this.messagetoChat3 = "Comment puis-je obtenir du soutien informatique sur mes appareils et sur l'environnement de Deloitte?";
            this.messagetoChat9 = 'Où puis-je saisir mon temps chez Deloitte?';
            this.prompt_head2 = 'Technologie';
            this.messagetoChat4 = 'Où puis-je accéder à ma messagerie Deloitte?';
            this.messagetoChat5 = 'Comment puis-je réserver un espace chez Deloitte';
            this.messagetoChat10 = 'Comment puis-je accéder au réseau lorsque je travaille à distance?';
            this.prompt_head3 = 'Culture du lieu de travail';
            this.messagetoChat6 = 'Quelle est la politique de travail à distance de Deloitte?';
            this.messagetoChat7 = 'Comment Deloitte crée-t-il un environnement de travail sûr et inclusif?';
            this.messagetoChat8 = 'Deloitte a-t-il une politique officielle de code vestimentaire?';
            this.messagetoChat11 = "Comment puis-je demander l'installation d'un logiciel spécifique?";
            this.messagetoChat12 = 'Que comprend ma rémunération totale?';
            this.messagetoChat13 = "Quelle est la durée de notre année de prestations?";
            this.messagetoChat14 = 'Quels programmes de réduction sont disponibles pour les employés de Deloitte?';
            this.messagetoChat15 = 'Deloitte a-t-il un REER de groupe et un CELI de groupe auquel je peux contribuer?';
            this.messagetoChat16 = 'Quelle est la politique de vacances de Deloitte';
            this.messagetoChat17 = "Quelles occasions d'apprentissage et de développement aurai-je accès chez Deloitte?";
            this.messagetoChat18 = "Qu’est-ce que l’indépendance chez Deloitte et pourquoi est-elle importante?";
            this.welcomemsg = "Bonjour, bienvenue à Intégration Innovaposte! Allez-y, envoyez un message.";
            this.input_placeholder = "Tapez votre information ou votre question...";
            this.pfb = 'Vos commentaires sont importants pour nous.';
            this.pfb_placeholder = 'Qu’avez-vous aimé à propos de la réponse?';
            this.fb_skip = 'Ignorer cette étape pour l’instant';
            this.fb_submit = 'Soumettre';
            this.nfb = 'Avez-vous d’autres commentaires à formuler? Nous voulons les connaître.';
            this.nfb_placeholder = 'Dites-nous comment nous pouvons nous améliorer.';
            this.fb_prompt = 'Cette réponse est-elle utile ou exacte?';
            this.option1 = 'Oui';
            this.option2 = 'Non';
            this.copy_title = 'Copier le code';
            this.prompt_head4 = 'Rémunération & Avantages';
            this.prompt_head5 = 'Autres';
            break;
          default:
            this.label1 = "Sample Prompts"; 
            this.subheading = "Looking for inspiration? Try a sample prompt below?"; 
            this.input_placeholder = "Type your input/question...";
            this.prompt_head1 = 'Mobile & Laptop';
            this.prompt_head2 = 'Technology';
            this.prompt_head3 = 'Workplace Culture';
            this.prompt_head4 = 'Compensation & Benefits';
            this.prompt_head5 = 'Others';
            this.messagetoChat1 = 'What should I do with my Innovapost mobile device?';
            this.messagetoChat2 = 'Can I install apps on my Windows laptop?';
            this.messagetoChat3 = 'How can I get IT support on my Deloitte devices and environment?';
            this.messagetoChat4 = 'Where can I access my Deloitte mailbox?';
            this.messagetoChat5 = 'How can I book space?';
            this.messagetoChat6 = "What is Deloitte's remote work policy?";
            this.messagetoChat7 = 'How does Deloitte create a safe and inclusive work environment?';
            this.messagetoChat8 = 'Does Deloitte have an official dress code policy?';
            this.messagetoChat9 = 'Where can I enter my time?';
            this.messagetoChat10 = 'How can I access the network when working remotely?';
            this.messagetoChat11 = 'How can I request access to a specific software?';
            this.messagetoChat12 = 'What does my Total Rewards package include?';
            this.messagetoChat13 = 'What is our Benefits year?';
            this.messagetoChat14 = 'What employee discount programs are available to Deloitte employees?';
            this.messagetoChat15 = 'Does Deloitte have a Group RRSP and Group TFSA I can contribute to?';
            this.messagetoChat16 = 'What is Deloitte’s vacation policy?';
            this.messagetoChat17 = 'What learning and development opportunities will I have access to at Deloitte?';
            this.messagetoChat18 = 'What is Independence and why is it important?';
            this.pfb = 'Your feedback is valuable to us';
            this.pfb_placeholder = 'What did you like about the response?';
            this.fb_skip = 'Skip this for now';
            this.fb_submit = 'Submit';
            this.nfb = 'Do you have any additional feedback? We’re listening.';
            this.nfb_placeholder = 'Tell us how we can improve.';
            this.fb_prompt = 'Was this response helpful/accurate?';
            this.copy_title = 'Copy code';
            this.option1 = 'Yes';
            this.option2 = 'No';
            this.fault = 'Sorry, something went wrong!! Please try again after some time.';
            this.welcomemsg = 'Hi, welcome to Innovapost Integration! Go ahead and send a message.';
        }
      });
    });
  }
  
  ngOnDestroy(){
    this.destroy.next();
    this.destroy.complete();  
  }
  getProfile(){
    this.https.get(GRAPH_ENDPOINT)
    .subscribe((profile:any) => {   
      this.name = profile.displayName;
      this.id = profile.mail;
    })
  }
  getdata(data:any):any{
    this.userIdLetter = data;
  }
  // sendAsMessage(messagefromprompt:any):any {
  //   this.messagetoChat = messagefromprompt;
  //   this.messagetoChat = messagefromprompt.replace(/"/g,'');
  //   $("#msgoninput").val(this.messagetoChat);     
  // } 
  // SendMessage(){ //to send the user query to OpenAI
  //   $("#msgoninput").prop("disabled",true);    
  //   this.isShown = ! this.isShown; 
  //   const userMsg = this.messagetoChat;
  //   if (!userMsg) return;
  //   this.appendMessage(this.userName, this.userIdLetter, "right", userMsg, "userMsg","text","",""); 
  //     try{
  //       this.chatService.getOrangeResult(this.messagetoChat)
  //       .subscribe({next : response => {   
  //         this.index = this.index+1;
  //         setTimeout(() => {   
  //             //console.log("response",response);   
  //             //console.log("references",response.references);
  //             //this.markerWithCode = this.addMarkersForCode(this.hyperlinktext);   
  //             this.markerWithCode = this.addMarkersForCode(response.content);
  //             this.references = response.references;
  //             this.urls = this.references.map((reference:any) =>(reference.title));
  //             //this.urls = this.references.map((reference:any) =>({title: reference.title, url:reference.url}));
  //             //console.log("urls",this.urls);
  //             this.appendMessage(this.botName, this.botImg, "left", this.markerWithCode, this.usermessage1,"text",response.content,this.urls);
  //         }, 250);
  //         this.isShown = ! this.isShown;
  //         $("#msgoninput").prop("disabled",false);
  //        }, error: err =>{
  //         this.isShown = ! this.isShown;
  //         this.isError = ! this.isError;        
  //         this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," "," ");         
  //        }}); 
  //       }
  //       catch(err){}
  //     this.messagetoChat='';
  // };
  sendDirectMessage(messagefromprompt:any):any{ 
    const userMsg = messagefromprompt.replaceAll('<','&lt').replaceAll('>','&gt');
     if (!userMsg) return; this.disablePrompts = false; 
     if (userMsg.replace(/\s/g, '') !== '' && userMsg.replace(/\s/g, '') !== '\n' && userMsg !== undefined) {
      this.isShown = ! this.isShown; 
       $("#msgoninput").prop("disabled",true);  
       this.disablePrompts = true; 
    
      if (!userMsg) return;
      this.appendMessage(this.userName, this.userIdLetter, "right", userMsg, "userMsg","text","",""); 
        try{
          this.chatService.getOrangeResult(userMsg)
          .subscribe({next : response => {   
            this.index = this.index+1;
            setTimeout(() => {   
                //console.log("response",response);   
                //console.log("references",response.references);
                //this.markerWithCode = this.addMarkersForCode(this.hyperlinktext);   
                this.markerWithCode = this.addMarkersForCode(response.content);
                this.references = response.references;
                this.urls = this.references.map((reference:any) =>(reference.title));
                //this.urls = this.references.map((reference:any) =>({title: reference.title, url:reference.url}));
                //console.log("urls",this.urls);
                this.appendMessage(this.botName, this.botImg, "left", this.markerWithCode, this.usermessage1,"text",response.content,this.urls);
            }, 250);
            this.isShown = ! this.isShown;
            this.disablePrompts = false; 

            $("#msgoninput").prop("disabled",false);
          }, error: err =>{
            this.isShown = ! this.isShown;
            this.disablePrompts = false; 

            this.isError = ! this.isError;        
            this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," "," ");         
          }}); 
          }
          catch(err){}
      }
        this.messagetoChat='';
  };
  // downloadfile(){
  //   console.log("download file");
  // }
  downloadFile(file_name:any,event: any){ 
    //event.preventDefault();
    //console.log("file_name",file_name);
    this.chatService.downloadDocument(file_name)
    .subscribe((res: any) => { // Explicitly specify the type of 'res' as 'any'
      //debugger;
      let data = res as HttpResponse<Blob>;
        const downloadedFile = new Blob([data.body as BlobPart], {
          type: data.body?.type
        });
        if (downloadedFile.type != "") {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = file_name;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          setTimeout(() => {
            //this.options = {}
          }, 100);
          // this.JsonDocumentsList.map((itm:any,idx:any)=>{
          //   this.JsonDocumentsList[idx].isChecked=false;
         // })
        }         
  },(error:any) => {
    console.log("error",error);
  })
}
  appendMessage(name:any, img:any, side:any, text:any, usermes:any,flag:any,responseContent:any,urls:any) {
    var userInput; 
    //console.log("urls",urls.length); 
    var urlsHTML = '';
    if(side == "left"){
    urls = [...new Set(urls)];
    for(var i =0; i < urls.length; i++){
      //urlsHTML += `<button (click)="hi()">hi</button>`
      //urlsHTML += '<button type="button" onclick="download(event,${urls[i]})">${urls[i]}</button><br>';
      urlsHTML += `<a href="${urls[i]}" id="downloadLink">${urls[i]}</a><br>`;     
    }
    //$(".urlList").append(urlsHTML);
  } 
   
    const msgHTML = `<script type="text/javascript/">
    
    </script>
    <div class="msg ${side}-msg">  
      <div class="col-2 msg-img-${side}">
        ${side == "right" ? this.userIdLetter : "<img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>"}
      </div> 
        <div class="col-10 msg-bubble" style="color:black">
          <div class="msg-${flag}">${text}</div>
          
          ${urls.length > 0 && side == "left" && flag != "error" ? '<div class="msg-text"><br><b>References : </b><div class="urlList">'+urlsHTML+'</div></div>': ''}
          
        </div>
    </div>       
    `;    
    
    
    $("#chat").append(msgHTML);

   
      
  // $(document).on('click', '.download-link', function(event,urls) {
  //   event.preventDefault();
  //   this.filename = $(this).attr('href');
  //   //console.log("doc name",filename);
  //   this.downloadfile(this.filename);
  // });
  

    const feedbackHTML = `<script type="text/javascript">
    var token = "";
    var key =  "msal.token.keys."+"${environment.clientId}";
    var getalltokens = localStorage.getItem(key);
    if (getalltokens != undefined){
      var t = JSON.parse(getalltokens);
      var accesstoken = localStorage.getItem(t.accessToken[1]);
      if(accesstoken != undefined){
        var accesstokenobj = JSON.parse(accesstoken);
        this.token = accesstokenobj.secret;
        //console.log(t);
      }   
    } 
     
    function submitLikeRes(){
     var sPageURL = window.location.search.substring(1);
     var inpRes = $('.LikeRes').val();     
     var dataLike =  {
       "userQuery": "${usermes}",
       "feedback": $('.LikeRes').val(),
       "feedbackType": "Positive"
     }
     $.ajax({
      type: 'POST',
      data:JSON.stringify(dataLike) , 
      contentType: "application/json",
      url: "${environment.orangefeedbackURL}", 
      headers: { "Authorization": 'Bearer ' + token },         
      success: function () {         
        if(sPageURL == 'lang=fr'){
        //alert("Rétroaction soumise avec succès");
        }
        else{
        //alert("Feedback submitted successfully");
        }
      },
      error: function (xhr, textStatus, errorThrown) { 
        // console.log('Error in Operation');
      }
    });
    $('#LikeModal${this.index}').remove() ; 
  } 

  function submitDisLikeRes(){
   var sPageURL = window.location.search.substring(1);
   var inpRes = $('.DisLikeRes').val();
    var dataDisLike =  {
       "userQuery": "${usermes}",
       "feedback": $('.DisLikeRes').val(),
       "feedbackType": "Negative"
     }
     $.ajax({
      type: 'POST',
      data:JSON.stringify(dataDisLike),
      contentType: "application/json",
      url: "${environment.orangefeedbackURL}", 
      headers: { "Authorization": 'Bearer ' + token },         
      success: function () {         
        if(sPageURL == 'lang=fr'){
        //alert("Rétroaction soumise avec succès");
        }
        else{
        // alert("Feedback submitted successfully");
        }
      },
      error: function (xhr, textStatus, errorThrown) {
        // console.log('Error in Operation');
      }
    }); 
     $('#DisLikeModal${this.index}').remove(); 
    } 
    $("#closePopupLike${this.index}").click(function(){                
      $('#LikeModal${this.index}').remove() ; 
    });

    $("#closePopupDisLike${this.index}").click(function(){                
      $('#DisLikeModal${this.index}').remove();  
    });     
       
    $("#closePopupLike1${this.index}").click(function(){                
      $('#LikeModal${this.index}').remove() ; 
    });

    $("#closePopupDisLike1${this.index}").click(function(){                
      $('#DisLikeModal${this.index}').remove() ; 
    });   

    $("#button1").click(function(){
      var val1 = $("#button1").val();
      $("#negativefb").val(val1);
    });  

    $("#button2").click(function(){
      var val1 = $("#button2").val();
      $("#negativefb").val(val1);
    }); 

    $("#button3").click(function(){
      var val1 = $("#button2").val();
      $("#negativefb").val(val1);
    }); 

    $("#send_input").click(function() {
      $("#wait").css("display", "block");       
    });
       
    $("#msgoninput").focus(); 

    </script>
      <div class="modal" id="LikeModal${this.index}" style="padding-top:60px;">
      <div class="modal-dialog">
        <div class="modal-content content1">     
            <div class="row" style="height:56px;margin-bottom:20px">
             <div class="col-1"><img src="../assets/images/Positive_fb.PNG" style="height:24px;width:32px;"></div>
             <div class="col-10"><h4 class="modal-title" style="font-size:16px;font-weight:600;">${this.pfb}</h4> </div>
             <div class="col-1"><button type="button" class="close" id="closePopupLike${this.index}">&times;</button></div>
            </div>
            <div class="feedback_input">
                <textarea class="LikeRes" style="overflow:hidden" placeholder="${this.pfb_placeholder}"></textarea>  
            </div>                                                                   
          <div class="modal-footer">
            <div class="row" id="full_width">
             <div class="col-7"><button type="button" id="closePopupLike1${this.index}" class="skip_button">${this.fb_skip}</button></div>
             <div class="col-5"><button type="button" class="submit_button" onclick="submitLikeRes()">${this.fb_submit}</button></div>
            </div>                                        
          </div>
        </div>                                                                       
      </div>                                      
    </div>

    <div class="modal" id="DisLikeModal${this.index}" style="padding-top:60px;">
    <div class="modal-dialog">
      <div class="modal-content content2" >
        <div class="row" style="height:56px;margin-bottom:20px">
          <div class="col-1"><img src="../assets/images/Emoji.PNG" style="height:24px;"></div>
          <div class="col-10"><h4 class="modal-title" style="font-size:16px;font-weight:600;">${this.pfb}</h4> </div>
          <div class="col-1"><button type="button" class="close" id="closePopupDisLike${this.index}">&times;</button></div>
        </div>
        <div class="heading">${this.nfb}</div>
        </br>                                                            
        <div class="feedback_input">
        <textarea class="DisLikeRes" id="negativefb" style="overflow:hidden" placeholder="${this.nfb_placeholder}"></textarea>  
        </div>         
        <div class="modal-footer">
          <div class="row" id="full_width">
              <div class="col-7"><button type="button" id="closePopupDisLike1${this.index}" class="skip_button">${this.fb_skip}</button></div>
              <div class="col-5"><button type="button" class="submit_button" onclick="submitDisLikeRes()">${this.fb_submit}</button></div>
          </div>                            
        </div>
      </div>                                                                       
    </div>                                      
  </div>

      <div class="msg ${side}-msg">   
      <div class="msg-img-${side}" style="background-image: url(${img})"></div>    
       
      <div class="row msg-bubble-feedback">
        <div class="col-9 msg-text-new">${this.fb_prompt}</div>
        
          <div id="likediv${this.index}" class="col" style="padding-left:0px;padding-right:0px;">
          <button type="button" id="button_like${this.index}" onclick='feedbackLike3(${this.index})' class="feedbackid"> 
            <img id="likeImage${this.index}" src="${this.likeImage}"           
            style="width: 21.04px;height: 19.51px;"/>
          </button>${this.option1}</div>
          <div id="dislikediv${this.index}" class="col" style="padding-left:0px;padding-right:0px;">
            <button  type="button" id="button_dislike${this.index}" onclick='feedbackDisLike(${this.index})'  class="feedbackdislikeid">
              <img id="dislikeImage${this.index}" src="${this.disLikeImage}" style="width: 21.04px;height: 19.51px;"> 
            </button>${this.option2}</div>        
      </div>
    </div>
        </div>
      </div>
    `;
    
     side == "left" ?  $("#chat").append(feedbackHTML) : $("#chat").append();

     //the following line of code is to scroll to the bottom of the page along with the response
      $("#chat").scrollTop($('#chat')[0].scrollHeight);
  }
  // convertTextToHyperlinks(text: string){
  //   const urlRegex = /((?:(https?|ftp):\/\/)?[\w.-]+(?:[:\d]*\/)(?:[\w_\.~:\/?#[+]@!$&'()*+,;=,\-{}|%])*(?:[\w_\.~:\/?#[+]@!$&'()*+,;=]))|(?:www\.)(?:[\w.-]+(?:[:\d]*\/)(?:[\w_\.~:\/?#[+]@!$&'()*+,;=])))/gi;
   
  //   return text.replace(urlRegex, (url) => {
  //     return `<a href="${url}" target="_blank">${url}</a>`;
  //   });
    
  // }
  
  addMarkersForCode(apiResponse:any):any{  //this is to identify code/table in the api response
    var updatedResponse:any = '';
    var locateString:any = "```"; 
    var locateString1:any = "\n\n|";
    var locateString2:any = "|\n\n";
    var locateString3:any = "\n\n```\n|";
    var locateString4:any = "|\n```\n\n";
    var locateString5:any = "\n\n```\n<table>";
    var locateString6:any = "</table>\n```\n\n";
    var locatehyperlink:any = "https";
    var locatehyperlink1:any = "http";
    var locatehyperlink2:any = "www";
    var value ="";
    let index = apiResponse.indexOf(locateString);
    let index1 = apiResponse.indexOf(locateString1);
    let index2 = apiResponse.indexOf(locateString2);
    let index3 = apiResponse.indexOf(locateString3);
    let index4 = apiResponse.indexOf(locateString4);
    let index5 = apiResponse.indexOf(locateString5);
    let index6 = apiResponse.indexOf(locateString6);
    let index7 = apiResponse.indexOf(locatehyperlink);
    let index8 = apiResponse.indexOf(locatehyperlink1);
    let index9 = apiResponse.indexOf(locatehyperlink2);
    if(index5 != -1 && index6!= -1){
      var newstring = apiResponse.substring(index5+1, index6-1);
      //console.log(newstring);
      //console.log("entered table"); 
      var table = $('<table>');
      apiResponse = apiResponse.replace("```","");
      apiResponse = apiResponse.replace(/^\s*[\r\n]/gm, '');
    }
    else if(index3 != -1 && index4 != -1 ){
      var newstring = apiResponse.substring(index3, index4+1);
      //console.log(newstring);
      var rows = newstring.split('\n');
      var table = $('<table>');
      var thRow = $('<tr>'); 
      var headers = rows[3].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th>').text(header.trim());  
        // Append header cell to row  
        thRow.append(th);  
      });
      table.append(thRow);
      for (var j = 5; j < rows.length; j++) {  
        // Split row into cells  
        var cells = rows[j].split('|').filter(function(cell:any):any{  
          return cell.trim() !== '';  
        }); 
        var tr = $('<tr>');
        cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td>').text(cell.trim());      
        // Append cell to row  
        tr.append(td);  
        }); 
        this.tablecode = table.append(tr);
      }
      
       apiResponse = apiResponse.replace(newstring,
        `<table>          
          ${this.tablecode[0].innerHTML};
          </table> `
        );       
    }
    else if(index1 != -1 && index2 != -1){
      var newstring = apiResponse.substring(index1, index2+1);
      //console.log(newstring);
      var rows = newstring.split('\n');
      var table = $('<table>');
      var thRow = $('<tr>'); 
      var headers = rows[2].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th>').text(header.trim());        
        // Append header cell to row  
        thRow.append(th);  
      }); 
      table.append(thRow); 
      for (var j = 4; j < rows.length; j++) {  
        // Split row into cells  
        var cells = rows[j].split('|').filter(function(cell:any):any{  
          return cell.trim() !== '';  
        });      
        var tr = $('<tr>');
        cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td>').text(cell.trim());      
        // Append cell to row  
        tr.append(td);  
        }); 
        this.tablecode = table.append(tr);
      }
       apiResponse = apiResponse.replace(newstring,
        `<table>          
          ${this.tablecode[0].innerHTML};
          </table> `
        ); 
        apiResponse = apiResponse.replace("```",""); 
        apiResponse = apiResponse.replace(/^\s*[\r\n]/gm, '');   
    }
    else if(index1 != -1 && index2 == -1){
      var newstring = apiResponse.substring(index1);
      //console.log(newstring);
      var rows = newstring.split('\n');
      var table = $('<table>');
      var thRow = $('<tr>'); 
      var headers = rows[2].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th>').text(header.trim());       
        // Append header cell to row  
        thRow.append(th);  
      }); 
      table.append(thRow); 
      for (var j = 4; j < rows.length; j++) {  
        // Split row into cells  
        var cells = rows[j].split('|').filter(function(cell:any):any{  
          return cell.trim() !== '';  
        });       
        var tr = $('<tr>');
        cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td>').text(cell.trim());     
        // Append cell to row  
        tr.append(td);  
        }); 
        this.tablecode = table.append(tr);
      }
      //console.table(JSON.stringify(this.tablecode));
        apiResponse = apiResponse.replace(newstring,
          `<table>          
          ${this.tablecode[0].innerHTML};
          </table> `
          );
          apiResponse = apiResponse.replace("```","");
          apiResponse = apiResponse.replace(/^\s*[\r\n]/gm, '');
    }
  //code functionality
  else if(index != -1){
    let i = 1;
    while (index != -1)
    {
      if (i % 2 != 0)
        {
          this.countvalue  = this.countvalue+1;
        if(apiResponse.slice(index+3, index+4)=="\n"){
          this.language = ""
        }
        else{
         let index1 = apiResponse.indexOf("\n",index+3);
         this.language = apiResponse.substring(index+3, index1);
        }
        apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replaceAll("<" , "&lt")
        apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replaceAll(">" , "&gt")
        apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(this.language , " ")
        apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(locateString, 
        `<pre style="border:none;margin-bottom:0px"><div style="background-color:#4A4A48;height:30px;"><button class="btn language" type="button">${this.language}</button><input type="button" id="copy_btn${this.countvalue}" value="${this.copy_title}" class="copy" onclick='copycode(${this.countvalue})'></input></div><code class="hljs language-xslt"  id="precode${this.countvalue}" lang=${this.language} >`);               
      }
      else{
          apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(locateString, `</code> </pre>`);
      }
      index = apiResponse.indexOf(locateString, index + 1);
      i++;
    }
  }
  else if(index7 != -1 || index8 != -1 || index9 != -1){  
     //return apiResponse.replace(/(https?:\/\/[^\s()]+)(\.|\,)?(?=\s|$|[^a-z0-9])(?!\.)/g,'<a href="$1" target="_blank">$1</a>');
     return apiResponse.replace(/(https?:\/\/[^\s()]+[A-Za-z0-9\/])(?=\s|$|[^a-z0-9])/g,'<a href="$1" target="_blank">link</a>');
    //return apiResponse.replace((https?:\/\/[^\s()]+[A-Za-z0-9\/])(?=\s|$|[^a-z0-9]),'<a href="$1" target="_blank">link</a>');
  }
  else{
    //console.log("entered else");
    apiResponse = apiResponse.replaceAll("<" , "&lt").replaceAll(">","&gt");  
    //this.convertTextToHyperlinks(apiResponse);
    //console.log("apiResponse",apiResponse); 
  }
    var key1   = localStorage.key(0);
    //console.log("apirespose",apiResponse);
    return apiResponse;
    //return apiResponse.replaceAll("<" , "&lt").replaceAll(">","&gt"); 
  }
  ngAfterContentChecked() :void{
    const aTags = this.elementRef.nativeElement.querySelectorAll('#downloadLink');
    // if(aTag && !aTag.hasAttribute('download-listener')){
    //   aTag.setAttribute('download-listener', 'true');
    aTags.forEach((aTag: HTMLAnchorElement) => {
      if(!aTag.hasAttribute('download-listener')){
        aTag.setAttribute('download-listener', 'true');
      aTag.addEventListener('click', (event:any) => {
      
        event.preventDefault();
        const url = aTag.href;
        const parts = url.split('/')
        this.filename = parts[parts.length - 1];
        //this.decodedFilename = decodeURIComponent(this.filename);
        this.downloadFile( decodeURIComponent(this.filename),event);
        //console.log("filename",this.filename);
      }); 
    } 
    });
    // }
    //console.log("event listener");
    //this.downloadLink.nativeElement.addEventListener('click',(event) => this.downloadFile(this.filename,event));
  }
}
