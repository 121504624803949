import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { AccordionModule } from '@usitsdasdesign/dds-ng/accordion';
import { DatepickerModule } from '@usitsdasdesign/dds-ng/datepicker';
// import ExpandableRowModule to be able to use ddsExpandableRow directive
import { ExpandableRowModule } from '@usitsdasdesign/dds-ng/shared/expandable-row';
// import FilterModule to be able to use filter component
import { FilterModule } from '@usitsdasdesign/dds-ng/filter';
// import SortByModule to be able to use sort pipe
import { SortByModule } from '@usitsdasdesign/dds-ng/shared/sort-by';
// import HighlightModule to be able to use highlight pipe
// import FocusHandlerModule to be able to use focus directive

import { VerticalTabsModule } from '@usitsdasdesign/dds-ng/vertical-tabs';
import { FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import { InputModule } from '@usitsdasdesign/dds-ng/input';
import { SelectModule } from '@usitsdasdesign/dds-ng/select';
import { CardModule } from '@usitsdasdesign/dds-ng/cards';
import { ProfileModule } from '@usitsdasdesign/dds-ng/profile';
import { HeaderModule } from '@usitsdasdesign/dds-ng/header';
import { ModalModule } from '@usitsdasdesign/dds-ng/modal';
import { ResizeHandlerModule } from '@usitsdasdesign/dds-ng/shared/resize-handler';
import { UploaderModule } from '@usitsdasdesign/dds-ng/uploader';
import { CheckboxModule } from '@usitsdasdesign/dds-ng/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TabsModule } from '@usitsdasdesign/dds-ng/tabs';
import { TextareaModule } from '@usitsdasdesign/dds-ng/textarea';
import { TooltipModule } from '@usitsdasdesign/dds-ng/tooltip';
import { MainPageComponent } from './main-page/main-page.component';
import { ChatService } from './Services/chat.service';
import { SharingmessageService } from './Services/sharingmessage.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { I18nModule } from '@usitsdasdesign/dds-ng/shared/i18n';
import { WizardModule } from '@usitsdasdesign/dds-ng/wizard';
import { LogLevel, IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from '../auth-config';
import { ClipboardModule } from 'ngx-clipboard';
import { DocumentSummaryComponent } from './document-summary/document-summary.component';
import { DragdropfileDirective } from './Directives/dragdropfile.directive';
import { ChatNavbarComponent } from './chat-navbar/chat-navbar.component';
import { OrangeComponent } from './orange/orange.component';



const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log("message", message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
* MSAL Angular will automatically retrieve tokens for resources
* added to protectedResourceMap. For more info, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(protectedResources.apiDBot.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiDBot.scopes.write]
    }
    // {
    //   httpMethod: 'POST',
    //   scopes: [...protectedResources.apiDBot.scopes.read]
    // }
  ]);
  protectedResourceMap.set(protectedResources.searchSummaryDocument.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.searchSummaryDocument.scopes.write]
    }
   
    // {
    //   httpMethod: 'POST',
    //   scopes: [...protectedResources.apiDBot.scopes.read]
    // }
  ]);
  protectedResourceMap.set(protectedResources.orange.endpoint,[
    {
      httpMethod: 'POST', 
      scopes: [...protectedResources.orange.scopes.write]
    }
  ]);
  protectedResourceMap.set(protectedResources.uploadDocument.endpoint,[
    {
      httpMethod: 'POST', 
      scopes: [...protectedResources.uploadDocument.scopes.write]   
    }
   ]);
  return {
    interactionType:InteractionType.Redirect, //InteractionType.Popup,
    protectedResourceMap,
  };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    DocumentSummaryComponent,
    DragdropfileDirective,
    ChatNavbarComponent,
    OrangeComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CheckboxModule,
    AccordionModule,
    ButtonModule,
    ExpandableRowModule,
    FilterModule,
    SortByModule,
    HighlightModule,
    FocusHandlerModule,
    DatepickerModule,
    InputModule,
    SelectModule,
    CardModule,
    ProfileModule,
    HeaderModule,
    TooltipModule,
    ModalModule,
    ResizeHandlerModule,
    ReactiveFormsModule,
    FormsModule,
    TabsModule,
    TextareaModule,
    UploaderModule, 
    HttpClientModule,
    MsalModule,
    ClipboardModule,
    I18nModule,
    WizardModule,
    VerticalTabsModule,
  ],
  
  providers: [ChatService, SharingmessageService,AppComponent,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        fullLibraryLoader: () => import('highlight.js'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent,MsalRedirectComponent], 
  })
export class AppModule { }