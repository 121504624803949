import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { protectedResources } from '../../auth-config';
import { HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import { Observable } from 'rxjs';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {

  gptModel: any;
  userQuery: any; 
  user_id: any;
  
  switch_value: boolean = false;
  
  constructor(private httpClient: HttpClient,private https: HttpClient) { }
  blobUrl = protectedResources.uploadDocument.endpoint;
  docupUrl = protectedResources.processUploadedDocument.endpoint;
  

  
    getProfile() {
    this.https.get(GRAPH_ENDPOINT)
    .subscribe((profile:any) => {
      // console.log("mail",profile.mail);
      this.user_id = profile.mail;
    })
    return this.user_id ;

  }
uploadDocument(id : any, formData: FormData): any {
  const headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'content-type': 'multipart/form-data'
  });

  return this.httpClient.post(  
    this.blobUrl+"?userId="+ id,    
    formData   
  // { headers: headers, params: { "userId": "gprajwalakeerthi@deloitte.ca" }, observe: 'response' }
   );
  }
  ProcessDocuments(id : any):  Observable<any> {
    return this.httpClient.post(  
      this.docupUrl+"ProcessDocuments?userEmail="+ id,{});
    }

  GenerateEmbeddings(userId:any):  Observable<any>{
      return this.httpClient.post(this.docupUrl + "GenerateEmbeddings?userId="+userId, {});   
    }

  ShowSummary(id : any):  Observable<any> {
      return this.httpClient.post(  
        this.docupUrl+"ShowSummary?userId="+ id,{});
      }
}
