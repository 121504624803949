import { Component, OnInit,OnDestroy, ElementRef, ViewChild,AfterViewInit, Input} from '@angular/core';
import { ChatService} from '../Services/chat.service';
import { DocumentUploadService } from '../Services/document-upload.service';
import { ResetBlobStorageService } from '../Services/reset-blob-storage.service';
import $, { error } from 'jquery';
import { SharingmessageService } from '../Services/sharingmessage.service';
import { HighlightLoader } from 'ngx-highlightjs';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
//import hljs from 'highlight.js';
import hljs from 'highlight.js';
import css from 'highlight.js/lib/languages/css';
import { I18nService, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { environment } from '../environments/environment';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { v4 as uuidv4 } from 'uuid';
import { protectedResources } from '../Services/auth.service';
import { DragdropfileDirective } from '../Directives/dragdropfile.directive';
import DOMPurify from 'dompurify';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

interface FileItem {
  file: File;
  isUploaded: boolean;
}

@Component({
  selector: 'app-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.css']
})

export class DocumentSummaryComponent implements OnDestroy {
  private subscription: Subscription;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  dragDropEnabled: boolean = true;
  @ViewChild('dragDropElement', { static: true }) dragDropElement: ElementRef;  // selectedFile: File;

  // onFileSelected(event: any): void {
  //   this.selectedFile = event.target.files[0];
  // }

  // uploadFile(): void {
  //   if (!this.selectedFile) {
  //     return;
  //   }
  //   const formData = new FormData();
  //   formData.append('file', this.selectedFile);
  //   this.fileInput.nativeElement.value = '';
  // }

  // @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  // selectedFileName: string;

  // onFileSelected(event: any): void {
  //   const file = event.target.files[0];
  //   this.selectedFileName = file ? file.name : '';
  // }

  // selectedFilesList : any = [];
  selectedFilesList: FileItem[] = [];
  FileNames: string[] = [];

  isShown: boolean = false ;
  isAns: boolean = false;
  value: string;
  likeCount = 0;
  disLikeCount = 0;
  index = 0;
  countvalue = 0;
  countDocs = 0;
  feedbacknos = 0;
  language = "";
  getdatafromPrompt:any;
  userIdLetter:any;
  filesListfromobs: File[];
  chatGptAnswer:any = '';
  isError: boolean = false;
  label1: string = '';
  subheading: string = '';
  input_placeholder: string = '';
  popup_header1: string = '';
  popup_header2: string = '';
  popup_header3: string = '';
  popup_header4: string = '';
  popup_subheader_1: any = '';
  popup_subheader_2: any = '';
  popup_subheader_3: any = '';
  popup_subheader1: string = '';
  popup_subheader2: string = '';
  popup_subheader3: string = '';
  popup_subheader4: string = '';
  messagetoChat1:any = '';
  messagetoChat2:any = '';
  messagetoChat3:any = '';
  messagetoChat4:any = '';
  messagetoChat5:any = '';
  messagetoChat6:any = '';
  messagetoChat7:any = '';
  messagetoChat8:any = '';
  messagetoChat9:any = '';
  messagetoChat10:any = '';
  messagetoChat11:any = '';
  prompt_head1:any = '';
  prompt_head2:any ='';
  prompt_head3:any = '';
  navigation1:any = '';
  navigation2:any = '';
  navigation3:any = '';
  navigation4:any = '';
  page1:any = '';
  page2:any = '';
  page3:any = '';
  pop1_msg1:any ='';
  pop1_msg2:any ='';
  pop1_msg3:any ='';
  pop1_msg4:any ='';
  pop1_msg5:any = '';
  pop2_msg1:any = '';
  pop2_msg2:any = '';
  pop2_msg3:any = '';
  pop2_msg4:any = '';
  pop2_msg5:any = '';
  pop2_msg6:any = '';
  pop2_msg7:any = '';
  pop1_msg6:any = '';
  pop1_msg7:any = '';
  pop11_msg1:any = '';
  pop12_msg2:any = '';
  pop1_1_msg1:any = '';
  pop1_3_msg3:any = '';
  pop3_msg7:any = '';
  sub1_1:any = '';
  sub1_2:any = '';
  sub2_1:any = '';
  sub2_2:any = '';
  sub2_3:any = '';
  sub2_4:any = '';
  sub3_1:any = '';
  sub3_2:any = '';
  sub3_3:any = '';
  sub4_1:any = '';
  sub4_2:any = '';
  help_popup:any = '';
  pfb: string = '';
  pfb_placeholder:string = '';
  fb_skip:string = '';
  fb_submit:string = '';
  nfb:string = '';
  nfb_placeholder:string = '';
  fb_prompt:string = '';
  copy_title:any = '';
  option1:any = '';
  option2:any = '';
  fault: any = '';
  welcomemsg: any = '';
  pop3_msg1:any = '';
  pop3_msg2:any = '';
  pop3_msg3:any = '';
  pop3_msg4:any = '';
  pop3_msg5:any = '';
  pop3_msg6:any = '';
  pop4_msg1:any = '';
  pop1_msg8:any = '';
  pop1_2_msg2:any = '';
  sub1_3:any = '';
  sub1_4:any = '';
  sub1_5:any = '';
  sub1_6:any = '';
  sub1_7:any = '';
  sub1_8:any = '';
  sub5_1:any = '';
  sub6_1:any = '';
  sub1_6_1:any = '';
  sub1_6_2:any = '';
  sub1_6_3:any = '';
  sub1_6_4:any = '';
  sub_1:any = '';
  sub_2:any = '';
  sub_3:any = '';
  sub_4:any = '';
  sub_5:any = '';
  sub_6:any = '';
  sub_7:any = '';
  sub_8:any = '';
  sub_9:any = '';
  sub_10:any = '';
  instruction:any = '';
  sub_instruction:any = '';
  disclaimer:any = '';
  summaryOfDocs:any = [];
  isSummarizeClicked:boolean = false;
  isDragDropEnabled: boolean = true;
  guid:string;
  
  private destroy = new Subject<void>();
  feedbackwork: boolean = false;
  loadmsg1: string;
  loadmsg2: string;

  constructor(public chatService: ChatService,public DocumentUploadService : DocumentUploadService,
    public ResetBlobStorageService : ResetBlobStorageService ,private sharingService:SharingmessageService,
    private https: HttpClient, private http: HttpClient, private i18n: I18nService, private router:Router, private route: ActivatedRoute) {
    this.guid=uuidv4();
   }
  usermessage1:any ;
  messagetoChat :string = '';
  example:any;
  prompt:any = "Prompt:";
  ex:any = '\nExample:';
  // messagetoChat1:any ="Create analysis of the current state of the eCommerce market, including trends, predictions, and new innovations";
  // messagetoChat2:any = "Explain how Search Engine Optimization (SEO) works and why clients should consider investing in improvements";
  // messagetoChat3:any = "Generate HTML code for a basic website header with 3 buttons, a search bar, and a logo";
  // messagetoChat4:any = "Write a formal email to an existing client asking to set up a meeting to discuss the project, including the date and time of the proposed meeting, the agenda for the meeting, and the desired outcome"
  // messagetoChat5:any = "Summarize the following article into 3 sentences";
  // messagetoChat6:any = "Create an agenda for an upcoming project meeting, covering updates from team members, addressing challenges, and discussing upcoming milestones.";
  // messagetoChat1:any = 'Can you help me find information about [topic]?';
  // example1:any = 'Can you help me find information about the latest advances in renewable energy and how they are being implemented in the Canada?';
  // messagetoChat2:any = 'What are some reputable sources for information about [topic]?';
  // example2:any = 'What are some reputable sources for information about climate change and its effects on the environment?';
  // messagetoChat3:any = 'I am looking for statistics about [topic]. Can you help me find some?';
  // example3 = 'I am looking for statistics about the success rates of small businesses in the Canada. Can you help me find some reliable sources and data sets that provide insights into factors that contribute to their success or failure?';
  // messagetoChat4:any = 'Text: [add text here]\nCan you summarize this article for me?';
  // messagetoChat5:any = 'Text: [add text here]\nFrom the above text, can you create an executive summary to be presented to the business sponsor';
  // messagetoChat6:any = 'Can you generate an email for me to send to [person] about [topic]?';
  // example6:any = 'Can you generate an email for me to send to my boss about requesting a meeting to discuss my performance review?';
  // messagetoChat7:any = 'Can you generate a report on [topic] for me?';
  // example7:any = 'Can you generate a report on the market trends for sustainable fashion in the last five years?';
  // messagetoChat8:any = 'Can you generate a proposal for [project]? ';
  // example8:any = 'Can you generate a proposal for a marketing campaign to launch a new product in the tech industry?';
  // messagetoChat9:any = 'Conduct a side-by-side comparison of [Technology1] and [Technology2] and present the results in a tabular form.';
  // messagetoChat10:any = 'Code: [add code here]\nCan you explain the core functionality of this script in a few sentences?';
  // messagetoChat11:any = 'Can you give me the code to generate an echo bot in python using RASA?';
  tablecode:any = "";
  feedbackLikeStyle = "none";
  displayStyle = "block";
  display2= "none"
  display3 = "none";
  toggleLike = true;
  startCode = 'Start Code';
  endCode = 'End code';
  markerWithCode = '';
  //fault = 'Sorry, something went wrong!! Please try again after some time.';
  likeImage = '../assets/images/Like.PNG';
  valueimage = "../assets/images/Liked.PNG";
  disLikeImage = "../assets/images/Dislike.PNG";
  disLikedImage = "../assets/images/Disliked.PNG";
  showImage:any = true;
  toggleDisLike = true;
  toggleStore =true;
  cid:any = '';
  name:any = '';
  id:any = '';
  boolFiles:any = false;
  choose:any = '';
  limit1:any = '';
  loadmsg:any = '';
  limit2:any = '';
  limit3:any = '';
  choice1:any = '';
  choice2:any = '';
  msgonupload:any = '';
 
  closePopup() {
  this.displayStyle = "none";
  this.display3 = "none";
  this.display2 = "none";
  }
  openPopup(popupmodal:any){
   if(popupmodal == "usecase"){    
    this.displayStyle = "none";
    this.display2 = "block";
    this.display3 = "none";
   }
   if(popupmodal == "risk"){
    this.displayStyle = "none";
    this.display3= "block";
    this.display2 = "none";
   }
   if(popupmodal == "limitations"){
    this.displayStyle = "block";
    this.display3= "none";
    this.display2 = "none";
   }
   
  }
  files : any[] = [];
  selectedFiles: FileItem[] = [];
  @Input() filesDropped: File[] = [];
  onFilesDropped(files: FileList): void { 
    this.boolFiles = false;
    this.isSummarizeClicked = false;
    this.fileInput.nativeElement.value = '';
    $("#fileInput").prop('disabled', false); 
  }
  
  onFileSelected(files: FileList | null): void {
    $("#fileInput").prop('disabled', false); 
    this.boolFiles = false;
    if(files) {
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document','text/plain', 'application/pptx','application/vnd.openxmlformats-officedocument.presentationml.presentation','text/VTT', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
      const validFiles = Array.from(files).filter(file => (file.type == "") ? file.name.includes('.vtt') :  allowedTypes.includes(file.type));
      if(validFiles){
          // Add selected files to the collection (array)
          for (let i = 0; i < validFiles.length; i++) {
            const fileName = validFiles[i].name;
            if(!fileName.includes('#')){
            this.selectedFiles.push({
              "file":validFiles[i],
              "isUploaded":false
            });
          }
          }
          this.selectedFilesList = this.selectedFiles;
        }
        } else {
          console.error('No files selected.');
        }
      this.isSummarizeClicked = false;
      
  }

onDragOver(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  event.dataTransfer!.dropEffect = 'copy'; // Set the drop effect to 'copy'
  // Add any styling for the drag-over state if needed
}
onDragLeave(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  // Remove any styling for the drag-over state if needed
}
onDrop(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  if (event.dataTransfer && event.dataTransfer.files) {
    this.handleFiles(event.dataTransfer.files);
    // Remove any styling for the drag-over state if needed
  }
}
// onFileSelected(event: Event): void {
//   const inputElement = event.target as HTMLInputElement;
//   this.handleFiles(inputElement.files);
//   inputElement.value = ''; 
// }

handleFiles(files: FileList | null): void {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push({
        file: files[i],
        "isUploaded":false
      });
    }
  }
}

summarizeFiles(): void {
  //console.log("summarize all")
}

deleteFile(fileName:any): void {
// Assuming fileName is the name of the file you want to remove 
 this.selectedFilesList = this.selectedFilesList.filter((item: FileItem) => item !== fileName);
 this.selectedFiles = this.selectedFiles.filter((item: FileItem) => item !== fileName);
 this.fileInput.nativeElement.value = '';
}

deleteFiles(): void {
  if(this.summaryOfDocs.length == 0){
    this.isShown = false;
    this.subscription.unsubscribe();
  }
  else{
  for(var count=0; count<= this.summaryOfDocs[0].length; count++){
    $("#summaryId"+count).remove();
  }
  $("#feedbackId"+count).remove();
 for( var count=0; count<= this.countDocs  ; count++  ){
 $("#chatdata"+count).remove();
 $("#feedbackId"+count).remove();
 }
   this.ResetBlobStorageService.resetUploadedDocument(this.id).subscribe(
     (response: any) => {
   },
   (error : any) => {
     console.error('File upload failed.', error);
   });
  }
   this.selectedFilesList = [];
   this.selectedFiles = [];
   this.fileInput.nativeElement.value = '';
   $("#reset_button").prop('disabled', true);
   this.isDragDropEnabled = true;
   $("#msgoninput").prop('disabled', true); 
   $("#fileInput").prop('disabled', false); 
   
  this.dragDropEnabled = true;
  this.dragDropElement.nativeElement.isDragDropEnabled = this.dragDropEnabled;
}


 async uploadFiles(): Promise<any> {
  this.dragDropEnabled = false;
  this.dragDropElement.nativeElement.isDragDropEnabled = this.dragDropEnabled;
  $("#fileInput").prop('disabled', true);
  this.isDragDropEnabled = false;
  this.loadmsg = this.loadmsg;
  this.FileNames = [];
  if(this.selectedFilesList){
    for (let i = 0; i < this.selectedFilesList.length; i++) {
      this.FileNames.push(this.selectedFilesList[i]["file"].name);      
    }}
  this.boolFiles = true;
  if (this.selectedFiles.length === 0 && this.selectedFilesList.length === 0) {
    console.error('No files selected.');
    return;
  }

 
  let msgHTML = '';  
  this.isShown = ! this.isShown;
  const formData = new FormData();  
  if(this.selectedFiles.length > 0){
    this.selectedFiles.forEach(item => {
      formData.append('files', item["file"], item["file"].name);
    })
  }
  const feedbackHTML = `<script type="text/javascript">
  var token = "";
  var key =  "msal.token.keys."+"${environment.clientId}";
  var getalltokens = localStorage.getItem(key);
  if (getalltokens != undefined){
    var t = JSON.parse(getalltokens);
    var accesstoken = localStorage.getItem(t.accessToken[1]);
    if(accesstoken != undefined){
      var accesstokenobj = JSON.parse(accesstoken);
      this.token = accesstokenobj.secret;
      //console.log(t);
    }   
  } 
  
 function submitLikeRes(){
  var sPageURL = window.location.search.substring(1);
  var inpRes = $('.LikeRes').val();     
  var dataLike =  {
    "conversationId": "${this.cid}",
    "userName": "${this.name}",
    "userId": "${this.id}",
    "document_Name": ["${this.FileNames}"],
    "userQuery": "document",
    "apiResponse": "summary",
    "feedback": $('.LikeRes').val(),
    "feedbackType": "Positive",
    "gptModel":"gpt3.5"
  }
  $.ajax({
   type: 'POST',
   data:JSON.stringify(dataLike) , 
   contentType: "application/json",
   url: "${environment.docfeedbackURL}", 
   headers: { "Authorization": 'Bearer ' + token },         
   success: function () {         
     if(sPageURL == 'lang=fr'){
     //alert("Rétroaction soumise avec succès");
     }
     else{
      //alert("Feedback submitted successfully");
     }
   },
   error: function (xhr, textStatus, errorThrown) { 
     // console.log('Error in Operation');
   }
 });
 $('#LikeModal${this.index}').remove() ; 
} 

function submitDisLikeRes(){
var sPageURL = window.location.search.substring(1);
var inpRes = $('.DisLikeRes').val();
 var dataDisLike =  {
    "conversationId": "${this.cid}",
    "userName": "${this.name}",
    "userId":"${this.id}",
    "document_Name": ["${this.FileNames}"],
    "userQuery": "document",
    "apiResponse": "summary",
    "feedback": $('.DisLikeRes').val(),
    "feedbackType": "Negative",
    "gptModel":"gpt3.5"
  }
  $.ajax({
   type: 'POST',
   data:JSON.stringify(dataDisLike),
   contentType: "application/json",
   url: "${environment.docfeedbackURL}", 
   headers: { "Authorization": 'Bearer ' + token },         
   success: function () {         
     if(sPageURL == 'lang=fr'){
     //alert("Rétroaction soumise avec succès");
     }
     else{
      //alert("Feedback submitted successfully");
     }
   },
   error: function (xhr, textStatus, errorThrown) {
     console.log('Error in Operation');
   }
 }); 
  $('#DisLikeModal${this.index}').remove(); 
} 
    $("#closePopupLike${this.index}").click(function(){                
          $('#LikeModal${this.index}').remove() ; 

    });

    $("#closePopupDisLike${this.index}").click(function(){                
      $('#DisLikeModal${this.index}').remove();  
    });     
    
    $("#closePopupLike1${this.index}").click(function(){                
      $('#LikeModal${this.index}').remove() ; 

    });

    $("#closePopupDisLike1${this.index}").click(function(){                
      $('#DisLikeModal${this.index}').remove() ; 
    });   
    $("#button1").click(function(){
      var val1 = $("#button1").val();
      $("#negativefb").val(val1);
    });  
    $("#button2").click(function(){
      var val1 = $("#button2").val();
      $("#negativefb").val(val1);
    }); 
    $("#button3").click(function(){
      var val1 = $("#button2").val();
      $("#negativefb").val(val1);
    }); 
    $("#send_input").click(function () 
    {
      $("#wait").css("display", "block");
           
    });
    
    $("#msgoninput").focus(); 
</script>
   <div class="modal" id="LikeModal${this.index}" style="padding-top:60px;">
   <div class="modal-dialog">
     <div class="modal-content content1">
   
         <div class="row" style="height:56px;margin-bottom:20px">
          <div class="col-1"><img src="../assets/images/Positive_fb.PNG" style="height:24px;width:32px;"></div>
          <div class="col-10"><h4 class="modal-title" style="font-size:16px;font-weight:600;">${this.pfb}</h4> </div>
          <div class="col-1"><button type="button" class="close" id="closePopupLike${this.index}">&times;</button></div>
         </div>
     <div class="feedback_input">
         <textarea class="LikeRes" style="overflow:hidden" placeholder="${this.pfb_placeholder}"></textarea>  
     </div>                                                            
       
       
       <div class="modal-footer">
         <div class="row" id="full_width">
          <div class="col-7"><button type="button" id="closePopupLike1${this.index}" class="skip_button">${this.fb_skip}</button></div>
          <div class="col-5"><button type="button" class="submit_button" onclick="submitLikeRes()">${this.fb_submit}</button></div>
         </div>                                        
       </div>
     </div>                                                                       
   </div>                                      
 </div>

 <div class="modal" id="DisLikeModal${this.index}" style="padding-top:60px;">
 <div class="modal-dialog">
   <div class="modal-content content2" >
     <div class="row" style="height:56px;margin-bottom:20px">
       <div class="col-1"><img src="../assets/images/Emoji.PNG" style="height:24px;"></div>
       <div class="col-10"><h4 class="modal-title" style="font-size:16px;font-weight:600;">${this.pfb}</h4> </div>
       <div class="col-1"><button type="button" class="close" id="closePopupDisLike${this.index}">&times;</button></div>
     </div>
     <div class="heading">${this.nfb}</div>
     </br>                                                     
     
     <div class="feedback_input">
     <textarea class="DisLikeRes" id="negativefb" style="overflow:hidden" placeholder="${this.nfb_placeholder}"></textarea>  
     </div>         
     <div class="modal-footer">
       <div class="row" id="full_width">
           <div class="col-7"><button type="button" id="closePopupDisLike1${this.index}" class="skip_button">${this.fb_skip}</button></div>
           <div class="col-5"><button type="button" class="submit_button" onclick="submitDisLikeRes()">${this.fb_submit}</button></div>
       </div>                            
     </div>
   </div>                                                                       
 </div>                                      
</div>

   <div class="msg left-msg" id="feedbackId${this.index}">   
   <div class="msg-img-left"><img src="${this.botImg}" style="height:30px;width:30px"></div>    
    
   <div class="row msg-bubble-feedback">
     <div class="col-9 msg-text-new">${this.fb_prompt}</div>
     
       <div id="likediv${this.index}" class="col" style="padding-left:0px;padding-right:0px;">
       <button type="button" id="button_like${this.index}" onclick='feedbackLike3(${this.index})' class="feedbackid"> 
         <img id="likeImage${this.index}" src="${this.likeImage}"           
         style="width: 21.04px;height: 19.51px;"/>
       </button>${this.option1}</div>
       <div id="dislikediv${this.index}" class="col" style="padding-left:0px;padding-right:0px;">
         <button  type="button" id="button_dislike${this.index}" onclick='feedbackDisLike(${this.index})'  class="feedbackdislikeid">
           <img id="dislikeImage${this.index}" src="${this.disLikeImage}" style="width: 21.04px;height: 19.51px;"> 
         </button>${this.option2}</div>        
   </div>
 </div>
     </div>
   </div>
 `; 

  async function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
 
  this.DocumentUploadService.uploadDocument(this.id, formData)
  .subscribe(
      (response :any) => {
      $("#msgoninput").prop('disabled', true);
      this.loadmsg = this.loadmsg1;  
      this.DocumentUploadService.ProcessDocuments(this.id)     
      .subscribe(   
      async (response :any) => {   
        this.loadmsg = this.loadmsg2;
        await delay(20000)
        this.DocumentUploadService.GenerateEmbeddings(this.id)
        .subscribe(
            (response :any) => {                       
                this.DocumentUploadService.ShowSummary(this.id)
                .subscribe(
                    (response :any) => {
                      this.summaryOfDocs.push(response.data)
                      this.feedbackwork = true;
                      for(var i = 0 ; i < response.data.length; i++) { 
                        const summary_title = response.data[i].title;
                        const summary_content = response.data[i].summary;
                        DOMPurify.addHook("beforeSanitizeElements", function (node) {
                          if (node.nodeType === 1) {
                            const replacementNode = document.createTextNode("[removed for security reasons]");
                            node.replaceWith(replacementNode);
                          }
                        });
                        const sanitizedSummary = DOMPurify.sanitize(summary_content, {
                          RETURN_DOM: false,
                          ALLOWED_TAGS: [],  // Customize as needed
                          ALLOWED_ATTR: [] ,  // Customize as needed
                          KEEP_CONTENT: true
                        });
                      msgHTML =   `\
                        <div class="msg left-msg" id="summaryId${i}">  
                          <div class="col-2 msg-img-left">
                            <img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>
                          </div> 
                            <div class="col-10 msg-bubble" style="color:black">
                              <div class="msg-text-new" style="font-size:bold"><b>Title: </b>${summary_title}</div> 
                              
                              <div class="msg-text-new"><b>Summary: </b>${sanitizedSummary}</div>
                            </div>
                        </div>       
                      `;  
                      this.selectedFilesList[i]["isUploaded"] = true
                     // $("#chat").append(msgHTML);
                      
                      $("#chat").append(msgHTML);
                      $("#reset_button").prop('disabled', false);
                      $("#msgoninput").prop('disabled', false); 

                    
                      }
                      this.isShown = !this.isShown;
                      $("#chat").append(feedbackHTML);
                      $("#chat").scrollTop($('#chat')[0].scrollHeight);


                    },
                    (error : any) => {
                      this.isShown = ! this.isShown;
                      $("#reset_button").prop('disabled', false);
                      this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," ");         

                    });
                },
                (error : any) => {
                  this.isShown = ! this.isShown;
                  $("#reset_button").prop('disabled', false);
                  this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," ");         
                  //console.log("Generate Embeddings error", error);

                }); 
      },
      (error : any) => {
          this.isShown = ! this.isShown;
          $("#reset_button").prop('disabled', false);
          this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," ");         
          //console.log("Process Documents error",error);
      });
      // this.selectedFilesList[index]["isUploaded"] = true
    },
    
    (error: any) => {
      this.isShown = ! this.isShown;
      this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," ");         
      console.error('File upload failed.', error);
      $("#reset_button").prop('disabled', false);
    },
    
  ); 
        this.isSummarizeClicked = true;
        // DJANGO UPLOAD
        // const body = {
        //   "fileName" : file["file"].name,
        //   "userId"   : "gprajwalakeerthi@deloitte.ca"
        // }
        // interface UploadResponse {
        //   title: string;
        //   summary:string;
        // }

        // this.http.post<UploadResponse>( "" , body)
        // .subscribe(
        //   (response: UploadResponse) => {
        //     this.selectedFilesList[index]["isUploaded"] = true
        //     this.summaryOfDocs.push(response)
        //     msgHTML +=   `
        //       <div class="msg left-msg" id="summaryId${index}">  
        //         <div class="col-2 msg-img-left">
        //           <img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>
        //         </div> 
        //           <div class="col-10 msg-bubble" style="color:black">
        //             <div class="msg-text">Title: ${response["title"]}</div> 
                    
        //             <div class="msg-text">Summary: ${response["summary"]}</div>
        //           </div>
        //       </div>       
        //     `; 
        //     console.log('Files uploaded successfully.', response,this.selectedFilesList[index]["isUploaded"]);
        //     resolve(response);
   
        //   },
        //   (error) => {
        //     console.error('File upload failed.', error);
        //   }
        // );
 
    
      // This line will run after all file uploads are complete
         
      $("#chat").scrollTop($('#chat')[0].scrollHeight);
      $("#msgoninput").prop('disabled', false);
      // this.isShown = ! this.isShown;  
   

    // this.http.post( docuplurl+"?userId=gprajwalakeerthi@deloitte.ca", formData 
    //   // { headers: headers, params: { "userId": "gprajwalakeerthi@deloitte.ca" }, observe: 'response' }
    //    )
    // .subscribe(
    //   (response: any) => {
    //     console.log('Files uploaded successfully.', response);
    //   //  this.selectedFilesList = response;
    //   },
    //   (error) => {
    //     console.error('File upload failed.', error);
    //   }
    // );
    this.selectedFiles = [];

  //  this.summaryOfDocs =  [{   
  //                             title: "1st Document",
  //                             summary: "Here is summary of first Document" 
  //                          },
  //                          {  title: "2nd Document",
  //                             summary: "Here is summary of first Document"
  //                          }];

    // let msgHTML = '';                       
   
  //   for( var count=0; count < this.summaryOfDocs.length; count++ ){
  //     msgHTML +=   `
  //     <div class="msg left-msg" id="summaryId${count}">  
  //       <div class="col-2 msg-img-left">
  //         <img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>
  //       </div> 
  //         <div class="col-10 msg-bubble" style="color:black">
  //           <div class="msg-text"> 
  //             ${this.summaryOfDocs[count].title}
  //           </div> 
  //           <div class="msg-text"> 
  //             ${this.summaryOfDocs[count].summary}
  //           </div>
  //         </div>
  //     </div>       
  //  `; 
  //   }
//     `
//     <div class="msg left-msg" id="summaryId">  
//       <div class="col-2 msg-img-left">
//         <img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>
//       </div> 
//         <div class="col-10 msg-bubble" style="color:black">
//           <div class="msg-text"> 
//              The Summary of uploaded document is here....   
//           </div>
//         </div>
//     </div>       
//  `;    
    // $("#chat").append(this.loadingImage);
    //$('#tabchat').append(this.tablecode);
 
  }
  ngOnInit() {
    $("#msgoninput").keypress(function(e){
      if(e.which == 13 && !e.shiftKey) {
        e.preventDefault();
      }
    });
    this.getProfile();
    this.cid = uuidv4();

    this.sharingService.obs.subscribe(res => this.getdata(res)); 

    this.sharingService.obs2.subscribe(res => this.getFiledata(res)); 

     
    //multi
    //this.router.navigate(['/docanalyzer']);
    this.route.queryParams.subscribe(params => {
      var lang = params['lang']; // price
      //this.i18n.setLocale('uk', UK);
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (lang) {
          case "fr":
    
            this.label1 = "Dock de documents"; 
            this.subheading = " Vous cherchez de l’inspiration? Essayez un exemple de question ci-dessous! ";
            this.input_placeholder = "Tapez votre information ou votre question...";
            this.messagetoChat1 = 'Pouvez-vous m’aider à trouver de l’information sur [sujet]?';
            this.messagetoChat2 = 'Quelles sont certaines sources d’information fiables sur [sujet]? ';
            this.messagetoChat3 = 'Je cherche des statistiques sur [sujet]. Pouvez-vous m’aider à en trouver? ';
            this.messagetoChat4 = 'Texte : [ajouter le texte ici]\nPouvez-vous résumer cet article pour moi? ';
            this.messagetoChat5 = 'Texte : [ajouter le texte ici]\nÀ partir du texte ci-dessus, pouvez-vous créer un résumé qui sera présenté au responsable du service?';
            this.messagetoChat6 = 'Pouvez-vous produire un courriel que je peux envoyer à [personne] au sujet de [sujet]?';
            this.messagetoChat7 = 'Pouvez-vous produire un rapport sur [sujet] pour moi?';
            this.messagetoChat8 = 'Pouvez-vous produire une offre de service pour [projet]?';
            this.messagetoChat9 = 'Effectuez une comparaison côte à côte de [Technologie1] et [Technologie2] et présentez les résultats sous forme de tableau.';
            this.messagetoChat10 = 'Code: [ajouter le code ici]\nPouvez-vous expliquer la fonctionnalité de base de ce script en quelques phrases?';
            this.messagetoChat11 = 'Pouvez-vous me donner le code pour générer un robot qui répète en Python à l’aide de RASA?';
            this.prompt_head1 = 'Téléchargement de fichiers';
            this.prompt_head2 = 'Taille maximale de 100 Mo';
            this.prompt_head3 = 'Glisser-déposer les fichiers ici';
            this.choose = 'Choisir un fichier';
            this.pfb = 'Choisir un fichier';
            this.limit1 = 'Veuillez sélectionner moins de 5 documents à résumer.';
            this.pfb_placeholder = 'Veuillez sélectionner moins de 5 documents à résumer.';
            this.fb_skip = 'Ignorer cette étape pour l’instant';
            this.fb_submit = 'Soumettre';
            this.loadmsg1 = 'Traitement des fichiers, veuillez patienter!';
            this.loadmsg = 'Téléchargement des fichiers en cours!';  
            this.loadmsg2 = 'Préparation du résumé, veuillez patienter!';  
            this.nfb = 'Traitement des fichiers, veuillez patienter !';
            this.limit2 = 'La taille du fichier ';  
            this.nfb_placeholder = 'La taille du fichier ';
            this.limit3 = " est supérieure à 100 MB. Veuillez télécharger un fichier d'une taille inférieure à 100 MB";
            this.fb_prompt = " est supérieure à 100 MB. Veuillez télécharger un fichier d'une taille inférieure à 100 MB";
            this.copy_title = 'Copier le code';
            this.choice1 = 'Télécharger'; 
            this.option1 = 'Télécharger';
            this.choice2 = 'Réinitialiser'; 
            this.option2 = 'Réinitialiser';
            this.fault = 'Désolé, une erreur s’est produite! Veuillez réessayer plus tard.';
            this.welcomemsg = "Bonjour, bienvenue dans l'analyseur de documents ! Téléchargez les documents pour commencer.";
            this.sub1_6_1 = 'Documents d’accueil,';
            this.sub1_6_2 = 'Documents d’exigences commerciales,';
            this.sub1_6_3 = 'Documents d’exigences fonctionnelles,';
            this.sub1_6_4 = 'Codes ou scripts liés aux applications internes de Deloitte;';
            this.instruction = 'En vigueur en date du 1er août 2023';
            this.sub_instruction = 'En raison de ces limites, l’utilisation des résultats de l’outil à des fins de recherche est autorisée. Lorsque l’outil est utilisé à des fins de prestation de services aux clients, les professionnels doivent respecter les attentes en matière de qualité (y compris l’originalité) établies par leur service, les politiques pertinentes et le code d’éthique de Deloitte. Au minimum, une vérification humaine de l’exactitude est nécessaire.';
            this.disclaimer = "Gen-D ne peut être utilisé que par les professionnels de Deloitte du cabinet membre canadien, y compris le Chili.";
            
            this.popup_header1 = 'Conseils et limites';
            this.popup_header2 = 'Politique de stockage des données';
            this.popup_header3 = 'Lignes directrices en matière de données et de confidentialité';
            this.popup_header4 = 'DONNÉES NE POUVANT PAS ÊTRE UTILISÉES';
            this.popup_subheader1 = "L'analyseur de documents permet aux utilisateurs de poser des questions sur la base de documents téléchargés, en utilisant la génération augmentée ainsi que les modèles GPT-4 et GPT-3 Embeddings d'Azure OpenAI.";
            this.popup_subheader_1= 'Les utilisateurs peuvent maintenant interagir avec leurs documents en suivant ces étapes simples';
            this.popup_subheader_2= 'Conseils pour interagir avec plusieurs fichiers';
            this.popup_subheader_3= 'Limitations';
            this.popup_subheader2 = 'Notre agent conversationnel offre toutes les capacités d’IA générative des modèles GPT-3.5-Turboet GPT 4 d’Azure OpenAI';
            this.popup_subheader3 = 'Voici quelques exemples de types de données dont l’utilisation est approuvée dans l’application.';
            this.navigation1 = 'Ignorer';
            this.navigation2 = 'Suivant';
            this.navigation3 = 'Précédent';
            this.navigation4 = 'Fermer';
            this.page1 = '1 de 3';
            this.page2 = '2 de 3';
            this.page3 = '2 de 3';
            this.pop1_msg1 = 'Sous le "Documents Dock", choisissez les fichiers avec lesquels vous souhaitez interagir et cliquez sur "Télécharger".';
            this.pop1_msg2 = `Une fois téléchargé, le bouton "Upload" sera désactivé jusqu'à ce que l'utilisateur clique à nouveau sur "Réinitialiser r".`;
            this.pop1_msg3 = "Lors du téléchargement, l'outil fournit un résumé des documents téléchargés, après quoi les utilisateurs peuvent poser des questions basées sur leur contenu.";
            this.pop1_msg4 = "Les questions des utilisateurs sont mises en correspondance avec les sections correspondantes des documents téléchargés et les réponses sont générées sur la base de ce contexte. Toutefois, l'application ne permet pas de poser des questions de suivi. Il est conseillé aux utilisateurs de formuler leurs questions de manière détaillée afin d'obtenir des réponses pertinentes";
            this.pop1_msg5 = `Une fois l'opération terminée, les utilisateurs peuvent fermer le navigateur ou cliquer sur "Réinitialiser" pour supprimer les documents.`;
            this.pop1_msg6 = 'Après la "réinitialisation", les utilisateurs peuvent sélectionner de nouveaux documents à télécharger et interagir avec eux.';
            this.pop1_msg7 = "Les utilisateurs peuvent sélectionner jusqu'à 5 documents à la fois.";
            this.pop1_msg8 = 'Users can upload one or more files at once. Ex: if two documents are related and if questions based on content from both, users can upload them both together.';
            this.pop11_msg1 = "Les utilisateurs peuvent télécharger jusqu'à 5 fichiers à la fois. Il est recommandé de télécharger des fichiers apparentés ensemble pour améliorer le contexte.";
            this.pop12_msg2 = "Les utilisateurs ne pourront pas ajouter d'éléments à ces documents sélectionnés une fois qu'ils auront été téléchargés. Ex : si les utilisateurs veulent télécharger un troisième document qui est également lié au document déjà téléchargé, ils doivent réinitialiser et télécharger à nouveau tous les documents ensemble.";
            this.pop1_1_msg1 = 'Bien que les réponses soient générées en utilisant le contexte des données téléchargées, elles peuvent être sujettes à des hallucinations.';
            this.pop1_2_msg2 = "L'application utilise MS Form Recognizer pour lire les données des documents téléchargés et applique Gen AI à ces données. Il est recommandé d'utiliser des documents contenant beaucoup de texte et il peut y avoir des problèmes résultant d'une capture inefficace des données des documents dans certains cas.";
            this.pop1_3_msg3 = "Les utilisateurs ne pourront interagir qu'avec le texte et non avec les infographies contenues dans les documents.";
            this.pop2_msg1 = "Une fois téléchargés, les documents et leurs données d'intégration respectives sont stockés dans un cache temporaire afin de retrouver les réponses aux requêtes de l'utilisateur.";
            this.pop2_msg2 = `Les données sont supprimées du cache lorsque l'utilisateur clique sur "Réinitialiser" ou ferme la session.`;
            this.pop2_msg3 = "Les données téléchargées par les utilisateurs ne sont pas utilisées ou recoupées pour fournir des réponses à d'autres utilisateurs de l'application.";
            this.pop2_msg4 = "Le cache d'exécution réside dans la zone Azure de Deloitte, dans la région du Canada.";
            this.pop2_msg5 = "Les détails de l'utilisateur et les conversations sont récupérés au cours de l'interaction pour permettre aux utilisateurs de poser des questions de suivi.";
            this.pop3_msg1 = "N'UTILISEZ PAS l'analyseur de documents pour créer des produits de travail à partir d'informations provenant de différents clients (ce que l'on appelle l'utilisation secondaire). Il est interdit d'utiliser des informations confidentielles relatives à un autre client, telles que des données précédemment collectées ou fournies par un autre client dans une promotion et des pièces jointes (par exemple, ne pas faire référence à des documents relatifs à trois clients différents pour les résumer).";
            this.pop3_msg2 = "N'UTILISEZ PAS l'analyseur de documents si cette utilisation risque de violer les engagements contractuels d'un client. Discutez avec votre ALSC pour confirmer si des restrictions spécifiques au client s'appliquent à votre mission.";
            this.pop3_msg3 = "N'UTILISEZ PAS de renseignements personnels (renseignements concernant une personne identifiable, y compris, mais sans s'y limiter, les professionnels de Deloitte, les clients, leurs clients ou leurs employés, tels que définis dans le Pri) dans L'analyseur de documents à des fins qui ne sont pas compatibles avec l'objectif initial pour lequel ces renseignements ont été recueillis.";
            this.pop3_msg4 = 'Vous pouvez utiliser des informations publiques telles que :';
            this.pop3_msg5 = 'Vous pouvez utiliser les informations de Deloitte telles que :';
            this.pop3_msg6 = 'Vous pouvez utiliser les données des clients :';
            this.pop3_msg7 = 'Vous pouvez utiliser les informations personnelles';
            this.sub1_1 = "Les informations (publiques ou propriétaires) protégées par une marque, des droits d'auteur ou des conditions d'utilisation spécifiques (par exemple, les conditions d'utilisation d'un site web) pour lesquelles une autorisation d'utilisation a été obtenue.";
            this.sub1_2 = "Les informations qui ne sont pas protégées par des droits d'auteur ou d'autres conditions d'utilisation spécifiques.";
            this.sub2_1 = 'Le matériel de marketing externe de Deloitte.';
            this.sub2_2 = "Les politiques, procédures et documents d'orientation de Deloitte.";
            this.sub2_3 = 'Exigences et codes internes de Deloitte.';
            this.sub2_4 = 'Matériel de formation de Deloitte.';
            this.sub3_1 = 'conformément aux dispositions des accords conclus avec les clients et dans le cadre de la fourniture des services convenus à ce même client, ou';
            this.sub3_2 = "pour fournir des services à ce client, et si aucune restriction ne s'oppose à cette utilisation, ou";
            this.sub3_3 = 'pour lesquelles le LCSP a examiné et approuvé';
            this.sub4_1 = "à condition que vous ayez l'autorisation ou le consentement nécessaire pour collecter les informations et que l'objectif de l'utilisation des informations personnelles soit conforme à celui pour lequel elles ont été collectées à l'origine (par exemple, vous êtes un spécialiste des finances qui compare des factures contenant des informations personnelles afin d'identifier des écarts de prix, ou vous êtes un responsable du recrutement qui compare des notes d'entretien afin d'identifier des thèmes communs parmi les candidats). En cas de doute, contactez le Bureau de la protection de la vie privée";
            this.sub4_2 = 'Deloitte firm pricing model materials and rate cards';
            this.sub_4 = 'Processing information requiring a certification or adherence to a specific standard not held by Azure OpenAI (e.g., processing of payment card information as Azure OpenAI does not have a PCIDSS certification)';
            this.sub_5 = 'Individual level talent data';
            this.sub_6 = 'Individual level employee activity';
            this.sub_7 = 'Information (public or proprietary) where the terms of use for such information expressly prohibit the use (e.g., online journal data, or market research)';
            this.sub_8 = 'Data obtained from web scraping (i.e., using bots to extract content and data from a website)';
            this.sub_9 = 'Purchased third party data not procured or purchased for Deloitte use (e.g., data acquired from contractors or vendors)';
            this.sub_10 = 'Any individual level or identifiable personal information';
            this.sub1_6_1 = 'Documents d’accueil,';
            this.sub1_6_2 = 'Documents d’exigences commerciales,';
            this.sub1_6_3 = 'Documents d’exigences fonctionnelles,';
            this.sub1_6_4 = 'Codes ou scripts liés aux applications internes de Deloitte;';
            this.instruction = 'En vigueur en date du 1er août 2023';
            this.sub_instruction = 'En raison de ces limites, l’utilisation des résultats de l’outil à des fins de recherche est autorisée. Lorsque l’outil est utilisé à des fins de prestation de services aux clients, les professionnels doivent respecter les attentes en matière de qualité (y compris l’originalité) établies par leur service, les politiques pertinentes et le code d’éthique de Deloitte. Au minimum, une vérification humaine de l’exactitude est nécessaire.';
            this.help_popup = 'AIDE';
            this.disclaimer = "Gen-D ne peut être utilisé que par les professionnels de Deloitte du cabinet membre canadien, y compris le Chili.";
            this.msgonupload = "Le traitement peut prendre du temps. Ne fermez pas le navigateur. Un fichier de 50 Mo peut prendre jusqu'à 90 secondes";
          
          
            break;
          default:
            this.label1 = "Documents Dock"; 
            this.popup_header1 = 'Guidance and Limitations';  
            this.popup_header2 = 'Data Storage Policy';
            this.popup_header3 = 'Data & Privacy Guidelines '; 
            this.popup_header4 = 'Data that cannot be used'; 
            this.popup_subheader1 = 'Document Analyzer allows users to ask questions based on uploaded documents, utilizing Retrieval Augmented Generation along with the GPT-4 and GPT-3 Embeddings model from Azure OpenAI.'; 
            this.popup_subheader_1= 'Users can now interact with their documents by following these simple steps';
            this.popup_subheader_2= 'Tips to interact with multiple files';
            this.popup_subheader_3= 'Limitations';
            this.popup_subheader2 = 'This chatbot offers all Generative AI capabilities offered by GPT 3.5 Turbo and GPT 4 models from Azure OpenAI.'; 
            this.navigation1 = 'Skip';
            this.navigation2 = 'Next';
            this.navigation3 = 'Previous';
            this.navigation4 = 'Close';
            this.page1 = '1 OF 3';
            this.page2 = '2 OF 3';
            this.page3 = '3 OF 3';
            this.pop1_msg1 = 'Under the “Documents Dock”, choose files you need to interact with and click “Upload”.';
            this.pop1_msg2 = 'Once uploaded, the “Upload” button will be disabled until user clicks on “Reset” again.';
            this.pop1_msg3 = 'On upload, the tool provides a summary of the documents uploaded, post which, users will be able to ask questions based on their content within.';
            this.pop1_msg4 = 'User queries are matched with related sections of the documents uploaded and answers are generated based on this context. However, the application does not support follow up questions. Users are advised to be elaborate in their queries to get relevant responses.';
            this.pop1_msg5 = 'Once complete, users can close the browser or click on “Reset” to remove the documents.';
            this.pop1_msg6 = 'After “Reset”, users can select new documents to upload and interact with the same';
            this.pop1_msg7 = 'Users can select upto 5 documents at once.';
            this.pop1_msg8 = 'Users can upload one or more files at once. Ex: if two documents are related and if questions based on content from both, users can upload them both together.';
            this.pop11_msg1 = 'Users can upload upto 5 files at once. It is recommended to upload related files together for enhanced context.';
            this.pop12_msg2 = 'Users will not be able to append to these selected documents once uploaded. Ex: if the users want to upload a third document that is also related to the once uploaded, they need to reset and upload them all together again.';
            this.pop1_1_msg1 = 'While the answers are generated using the context from the data uploaded, they may be prone to hallucinations.';
            this.pop1_2_msg2 = 'The application uses MS Form recognizer to read the data from the uploaded documents and applies Gen AI on this data. It is recommended to use text heavy documents and there may be issues resulting from inefficient data capturing from documents in some cases.';
            this.pop1_3_msg3 = 'Users will only be able to interact with the text and not the infographics within the documents.';
            this.pop2_msg1 = 'Once uploaded, the documents and their respective embeddings data is stored in a temporary cache to retrieve the answers to user queries.';
            this.pop2_msg2 = 'Data is removed from the cache when the user clicks “Reset” or closes the session.';
            this.pop2_msg3 = 'Data uploaded by the users is not utilized or cross referenced to provide answers to other users of the application.';
            this.pop2_msg4 = 'The run time cache resides within Deloitte’s Azure tenet within the Canada region.';
            this.pop2_msg5 = 'User details and conversations are retrieved within the interaction to enable users ask follow-up questions.';
            this.help_popup = 'HELP';
            this.pop3_msg1 = ' use Document Analyzer to create work products using information from different clients (referred to as secondary use). The use of any Client Confidential Information pertaining to a different client, such as data previously collected or provided by another client in a promote and attachments is prohibited (e.g., do not reference documents related to three different clients for summarization)';
            this.pop3_msg2 = ' use Document Analyzer if such use would violate any client contractual commitments. Discuss with your Lead Client Service Partner (“LCSP”) to confirm if client specific restrictions apply to your engagement';
            this.pop3_msg3 = ' use Personal information (information about an identifiable individual, including but not limited to Deloitte professionals, clients, their customers, or their employees, as defined in the Pri) in Document Analyzer for purposes that are not compatible with the original purpose for which that information was collected.';
            this.pop3_msg4 = 'You may use Public Information such as:';
            this.pop3_msg5 = 'You may use Deloitte Information such as:';
            this.pop3_msg6 = 'You may use Client Data:';
            this.pop3_msg7 = 'You may use Personal Information:';
            this.sub1_1 = 'Information (public or proprietary) protected by trademark, copyright or specific use terms (e.g., website terms) for which permission to use has been obtained.';
            this.sub1_2 = 'Information that is not protected by copyright or other specific terms of use.';
            this.sub2_1 = 'Deloitte external facing marketing materials.';
            this.sub2_2 = 'Deloitte policies, procedures and guidance documents.';
            this.sub2_3 = 'Internal Deloitte business requirements/code.';
            this.sub2_4 = 'Deloitte training materials.';
            this.sub3_1 = 'In accordance with the terms of the client agreements and related to the delivery of the agreed-upon services to that same client, or';
            this.sub3_2 = 'To provide services to that client, and there are no restrictions against such use, or';
            this.sub3_3 = 'For which the LCSP has reviewed and approved ';
            this.sub4_1 = 'Provided you have appropriate permission / consent to collect the information, and that the purpose for using the Personal Information is consistent with that for which it was originally collected (e.g. you are a Finance specialist comparing invoices that contain personal information for the purpose of identifying pricing discrepancies, or you are a hiring manager comparing interview notes to identify common themes among candidates). If you are uncertain, contact the Privacy Office';
            this.sub4_2 = 'Deloitte firm pricing model materials and rate cards';
            this.sub_4 = 'Processing information requiring a certification or adherence to a specific standard not held by Azure OpenAI (e.g., processing of payment card information as Azure OpenAI does not have a PCIDSS certification)';
            this.sub_5 = 'Individual level talent data';
            this.sub_6 = 'Individual level employee activity';
            this.sub_7 = 'Information (public or proprietary) where the terms of use for such information expressly prohibit the use (e.g., online journal data, or market research)';
            this.sub_8 = 'Data obtained from web scraping (i.e., using bots to extract content and data from a website)';
            this.sub_9 = 'Purchased third party data not procured or purchased for Deloitte use (e.g., data acquired from contractors or vendors)';
            this.sub_10 = 'Any individual level or identifiable personal information';
            this.sub1_6_1 = 'Intake Documents';
            this.sub1_6_2 = 'Business Requirement Documents';
            this.sub1_6_3 = 'Functional Requirement Documents';
            this.sub1_6_4 = 'Code or scripts related to Deloitte internal applications';
            this.instruction = 'Effective August 1, 2023';
            this.sub_instruction = 'As a result of these limitations, use of the output from the tool for research purposes is permitted. Where the tool is being used for the purpose of client delivery, practitioners are reminded to adhere to quality (including originality) expectations set by their business, relevant policies, and Deloitte’s Code of Conduct. At a minimum, prior human review of accuracy is necessary.';
            this.disclaimer = 'Gen-D may only be used by Deloitte professionals in the Canadian member firm, including Chile.';
            this.messagetoChat1 = 'Can you help me find information about [topic]?';
            this.messagetoChat2 = 'What are some reputable sources for information about [topic]?';
            this.messagetoChat3 = 'I am looking for statistics about [topic]. Can you help me find some?';
            this.messagetoChat4 = 'Text: [add text here]\nCan you summarize this article for me?';
            this.messagetoChat5 = 'Text: [add text here]\nFrom the above text, can you create an executive summary to be presented to the business sponsor';
            this.messagetoChat6 = 'Can you generate an email for me to send to [person] about [topic]?';
            this.messagetoChat7 = 'Can you generate a report on [topic] for me?';
            this.messagetoChat8 = 'Can you generate a proposal for [project]?';
            this.messagetoChat9 = 'Conduct a side-by-side comparison of [Technology1] and [Technology2] and present the results in a tabular form.';
            this.messagetoChat10 = 'Code: [add code here]\nCan you explain the core functionality of this script in a few sentences?';
            this.messagetoChat11 = 'Can you give me the code to generate an echo bot in python using RASA?';
            this.prompt_head1 = 'Upload Files';
            this.prompt_head2 = 'Max size of 100MB';
            this.prompt_head3 = 'Drag and Drop files here';
            this.choose = 'Choose File';
            this.pfb = 'Your feedback is valuable to us';
            this.limit1 = 'Please select less than 5 documents to Summarize.';  
            this.pfb_placeholder = 'What did you like about the response?';
            this.fb_skip = 'Skip this for now';
            this.fb_submit = 'Submit';
            this.loadmsg1 = 'Processing the Files, Please wait!';  
            this.loadmsg = 'Uploading the Files!';  
            this.loadmsg2 = 'Preparing the Summary, Please wait!';  
            this.nfb = 'Do you have any additional feedback? We’re listening.';
            this.limit2 = 'The file size of ';  
            this.nfb_placeholder = 'Tell us how we can improve.';
            this.limit3 = ' is greater than 100MB. Please upload file with size less than 100MB.'
            this.fb_prompt = 'Was this response helpful/accurate?';
            this.copy_title = 'Copy code';
            this.choice1 = 'Upload';
            this.option1 = 'Yes';
            this.choice2 = 'Reset';
            this.option2 = 'No';
            this.fault = 'Sorry, something went wrong!! Please try again after some time.';
            this.welcomemsg = 'Hi, welcome to Document Analyzer! Upload the documents to start.';
            this.sub1_6_1 = 'Intake Documents';
            this.sub1_6_2 = 'Business Requirement Documents';
            this.sub1_6_3 = 'Functional Requirement Documents';
            this.sub1_6_4 = 'Code or scripts related to Deloitte internal applications';
            this.instruction = 'Effective August 1, 2023';
            this.sub_instruction = 'As a result of these limitations, use of the output from the tool for research purposes is permitted. Where the tool is being used for the purpose of client delivery, practitioners are reminded to adhere to quality (including originality) expectations set by their business, relevant policies, and Deloitte’s Code of Conduct. At a minimum, prior human review of accuracy is necessary.';
            this.disclaimer = 'Gen-D may only be used by Deloitte professionals in the Canadian member firm, including Chile.';
            this.msgonupload = "Processing may take time. Do not close the browser. A 50 MB file may take upto 90 seconds";
            break;
        }
      });
    });
      //this.http.get('assets/i18n/en.json').subscribe((data: ITranslation) => this.i18n.setLocale('uk', data));
  }
  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.profile,
  ];
  ngOnDestroy() {
    if(this.subscription != undefined){
      this.subscription.unsubscribe();
    }
    this.destroy.next();
    this.destroy.complete();
  }
  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
    .subscribe((profile:any) => {
      
      this.name = profile.displayName;
      this.id = profile.mail;
    })
  }

  getdata(data:any):any{
    this.userIdLetter = data
  }
  getFiledata(data:File[]):any{
    // console.log("files Data",data)
    this.filesListfromobs = data
    if(this.filesListfromobs) {
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document','text/plain','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/vtt','application/vnd.ms-excel'];
     // const validFiles = Array.from(files).filter(file => allowedTypes.includes(file.type));

    const validFiles = Array.from(data).filter(file => (file.type == "") ? file.name.includes('.vtt'): allowedTypes.includes(file.type));
    if(validFiles){ 
      // Add selected files to the collection (array)
      for (let i = 0; i < validFiles.length; i++) {
        // console.log(`Selected files before push ${this.selectedFiles}`);
        const fileName = validFiles[i].name;  
        if (!fileName.includes('#')){
        this.selectedFiles.push({
          file:validFiles[i],
          isUploaded:false
        });
      }
        // console.log(`Selected files after push ${this.selectedFiles}`);
// ***************************************************************************
      }
      this.selectedFilesList = this.selectedFiles;
      this.isSummarizeClicked = false;
      // console.log(`Selected files choosen file: ${this.selectedFiles}`);

      // console.log(`Selected files choosen file: ${this.filesListfromobs}`);
    }
   } else {
      console.error('No files selected.');
    }
  }

  // getresultfromapi(text:any){
  //   this.chatService.getSearchResult(text,text,text,text,text,text,text,text,text)
  //   .subscribe(response => {
  //     // console.log("res",response.body)
  //     return response.body});
  // }
   
   botImg:any = "../assets/images/AITool.PNG";
   userImg:any = "../assets/images/User_i.PNG";
   botName:string = "";
   userName:string = "";
   messageEntered:any;
  
  //  keyEvent(event)
  //  {
    
  //      event.preventDefault(); // Otherwise the form will be submitted
  //      //this.itemsList.push(this.myText);
    
  //  }
  SanitizeInput(input:any){
    return input
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
  }
  SendMessage(){ 
    const sanitizedUserMsg = this.SanitizeInput(this.messagetoChat);
    const userMsg = sanitizedUserMsg.replaceAll('<','&lt').replaceAll('>','&gt');
   //const userMsg = this.messagetoChat.replaceAll('<','&lt').replaceAll('>','&gt');
      if (!userMsg) return;
      if (userMsg.replace(/\s/g, '') !== '' && userMsg.replace(/\s/g, '') !== '\n' && userMsg !== undefined) {
        this.isAns = true;
        $("#msgoninput").prop("disabled",true);    
        $("#reset_button").prop('disabled', true);
      //const userMsg = this.messagetoChat;
      // console.log("this.messagetoChat",this.messagetoChat)
       this.countDocs = this.countDocs+1;
       this.feedbacknos += 1;
       this.appendMessage(this.userName, this.userIdLetter, "right", userMsg, "userMsg","text",""); 
       //  const loadingImage = `<div id="wait" class="waitstyle"><img src="../assets/images/Loading.gif"></div>`;
      try{
        this.chatService.getDocumentResult(this.id,this.messagetoChat)
        .subscribe({next : response => {  
          const summary_output = response.output;
          const sanitizedOutput = DOMPurify.sanitize(summary_output, {
            RETURN_DOM: false,
            ALLOWED_TAGS: [],  // Customize as needed
            ALLOWED_ATTR: [] ,  // Customize as needed
            KEEP_CONTENT: true
          }); 
          //  console.log("res",response)
          // const delay = response.content.split(" ").length * 100; 
          this.index = this.index+1;
          setTimeout(() => {        
            // console.log("res",response)     
              this.markerWithCode = this.addMarkersForCode(sanitizedOutput);
              this.usermessage1 = userMsg.replace('\n',"")
              this.countDocs = this.countDocs+1;
              this.feedbacknos += 1;
              this.appendMessage(this.botName, this.botImg, "left", this.markerWithCode, this.usermessage1,"text",response.content)
          }, 250);
          //this.isShown = ! this.isShown;
          this.isAns = false;
          $("#msgoninput").prop("disabled",false);
          $("#reset_button").prop('disabled', false);
         }, error: err =>{
          //this.isShown = ! this.isShown;
          this.isAns = false;
          this.isError = ! this.isError;   
          this.countDocs = this.countDocs+1;
          this.feedbacknos += 1;     
          this.appendMessage(this.botName, this.botImg, "left",this.fault,this.usermessage1,"error"," "); 
          $("#reset_button").prop('disabled', false);        
          // console.log('Something went wrong!');
         }}); 
        }
  //     try{
  //       const requestData =  {
  //         "userId": "gprajwalakeerthi@deloitte.ca",
  //         "prompt": this.messagetoChat,

  //       };;  
  //         this.chatService.getDocumentResult(requestData)
  //       this.chatService.getDocumentResult(this.messagetoChat)
  //      .subscribe(
  //       (response) => {
  //         debugger;
  //         console.log("res", response);
  //     Rest of your success logic
  //   },
  //   (error) => {
  //     this.isShown = !this.isShown;
  //     this.isError = !this.isError;
  //     this.appendMessage(
  //       this.botName,
  //       this.botImg,
  //       "left",
  //       this.fault,
  //       this.usermessage1,
  //       "error",
  //       " "
  //     );
  //      console.log('Something went wrong!',error);
  //   }
  // );
  //     }
        catch(err){}
      }
     this.messagetoChat='';
  };
  // loading(){
  //   $("wait").css("display","block");
  // }
 
  appendMessage(name:any, img:any, side:any, text:any, usermes:any,flag:any,responseContent:any) {
      var userInput;    
      const msgHTML = `<script type="text/javascript/"> 
      </script>
      <div class="msg ${side}-msg" id="chatdata${this.countDocs}" >  
        <div class="col-2 msg-img-${side}">
          ${side == "right" ? this.userIdLetter : "<img src='../assets/images/AITool.PNG' style='width:30px;height:30px;'>"}
        </div> 
          <div class="col-10 msg-bubble" style="color:black">
            <div class="msg-${flag}">${text}</div>
          </div>
      </div>       
   `;    
      // $("#chat").append(this.loadingImage);
      //$('#tabchat').append(this.tablecode);

     
       $("#chat").append(msgHTML);
       $("#chat").scrollTop($('#chat')[0].scrollHeight);
       
      
      // $("#chat").append(this.tablecode) 
     const feedbackHTML = `<script type="text/javascript">
     var token = "";
     var key =  "msal.token.keys."+"${environment.clientId}";
     var getalltokens = localStorage.getItem(key);
     if (getalltokens != undefined){
       var t = JSON.parse(getalltokens);
       var accesstoken = localStorage.getItem(t.accessToken[1]);
       if(accesstoken != undefined){
         var accesstokenobj = JSON.parse(accesstoken);
         this.token = accesstokenobj.secret;
         //console.log(t);
       }   
     } 
     
    function submitLikeRes(){
     var sPageURL = window.location.search.substring(1);
     var inpRes = $('.LikeRes').val();     
     var dataLike =  {
       "conversationId": "${this.cid}",
       "userName": "${this.name}",
       "userId": "${this.id}",
       "document_Name": ["query"],
       "userQuery": "${usermes}",
       "apiResponse": "${responseContent}",
       "feedback": $('.LikeRes').val(),
       "feedbackType": "Positive",
       "gptModel": "gpt3.5" 
     }
     $.ajax({
      type: 'POST',
      data:JSON.stringify(dataLike) , 
      contentType: "application/json",
      url: "${environment.docfeedbackURL}", 
      headers: { "Authorization": 'Bearer ' + token },         
      success: function () {         
        if(sPageURL == 'lang=fr'){
        //alert("Rétroaction soumise avec succès");
        }
        else{
        // alert("Feedback submitted successfully");
        }
      },
      error: function (xhr, textStatus, errorThrown) { 
        // console.log('Error in Operation');
      }
    });
    $('#LikeModal${this.index}').remove() ; 
  } 

  function submitDisLikeRes(){
   var sPageURL = window.location.search.substring(1);
   var inpRes = $('.DisLikeRes').val();
    var dataDisLike =  {
       "conversationId": "${this.cid}",
       "userName": "${this.name}",
       "userId":"${this.id}",
       "document_Name": ["query"],
       "userQuery": "${usermes}",
       "apiResponse": "${responseContent}",
       "feedback": $('.DisLikeRes').val(),
       "feedbackType": "Negative",
       "gptModel": "gpt3.5" 
     }
     $.ajax({
      type: 'POST',
      data:JSON.stringify(dataDisLike),
      contentType: "application/json",
      url: "${environment.docfeedbackURL}", 
      headers: { "Authorization": 'Bearer ' + token },         
      success: function () {         
        if(sPageURL == 'lang=fr'){
        //alert("Rétroaction soumise avec succès");
        }
        else{
         //alert("Feedback submitted successfully");
        }
      },
      error: function (xhr, textStatus, errorThrown) {
        console.log('Error in Operation');
      }
    }); 
     $('#DisLikeModal${this.index}').remove(); 
   } 
       $("#closePopupLike").click(function(){                
             $('#LikeModal${this.index}').remove() ; 

       });

       $("#closePopupDisLike").click(function(){                
         $('#DisLikeModal${this.index}').remove();  
       });     
       
       $("#closePopupLike1").click(function(){                
         $('#LikeModal${this.index}').remove() ; 

       });

       $("#closePopupDisLike1").click(function(){                
         $('#DisLikeModal${this.index}').remove() ; 
       });   
       $("#button1").click(function(){
         var val1 = $("#button1").val();
         $("#negativefb").val(val1);
       });  
       $("#button2").click(function(){
         var val1 = $("#button2").val();
         $("#negativefb").val(val1);
       }); 
       $("#button3").click(function(){
         var val1 = $("#button2").val();
         $("#negativefb").val(val1);
       }); 
       $("#send_input").click(function () 
       {
         $("#wait").css("display", "block");
              
       });
       
       $("#msgoninput").focus(); 
   </script>
      <div class="modal" id="LikeModal${this.index}" style="padding-top:60px;">
      <div class="modal-dialog">
        <div class="modal-content content1">
      
            <div class="row" style="height:56px;margin-bottom:20px">
             <div class="col-1"><img src="../assets/images/Positive_fb.PNG" style="height:24px;width:32px;"></div>
             <div class="col-10"><h4 class="modal-title" style="font-size:16px;font-weight:600;">${this.pfb}</h4> </div>
             <div class="col-1"><button type="button" class="close" id="closePopupLike">&times;</button></div>
            </div>
        <div class="feedback_input">
            <textarea class="LikeRes" style="overflow:hidden" placeholder="${this.pfb_placeholder}"></textarea>  
        </div>                                                            
          
          
          <div class="modal-footer">
            <div class="row" id="full_width">
             <div class="col-7"><button type="button" id="closePopupLike1" class="skip_button">${this.fb_skip}</button></div>
             <div class="col-5"><button type="button" class="submit_button" onclick="submitLikeRes()">${this.fb_submit}</button></div>
            </div>                                        
          </div>
        </div>                                                                       
      </div>                                      
    </div>

    <div class="modal" id="DisLikeModal${this.index}" style="padding-top:60px;">
    <div class="modal-dialog">
      <div class="modal-content content2" >
        <div class="row" style="height:56px;margin-bottom:20px">
          <div class="col-1"><img src="../assets/images/Emoji.PNG" style="height:24px;"></div>
          <div class="col-10"><h4 class="modal-title" style="font-size:16px;font-weight:600;">${this.pfb}</h4> </div>
          <div class="col-1"><button type="button" class="close" id="closePopupDisLike">&times;</button></div>
        </div>
        <div class="heading">${this.nfb}</div>
        </br>                                                     
        
        <div class="feedback_input">
        <textarea class="DisLikeRes" id="negativefb" style="overflow:hidden" placeholder="${this.nfb_placeholder}"></textarea>  
        </div>         
        <div class="modal-footer">
          <div class="row" id="full_width">
              <div class="col-7"><button type="button" id="closePopupDisLike1" class="skip_button">${this.fb_skip}</button></div>
              <div class="col-5"><button type="button" class="submit_button" onclick="submitDisLikeRes()">${this.fb_submit}</button></div>
          </div>                            
        </div>
      </div>                                                                       
    </div>                                      
  </div>

      <div class="msg ${side}-msg"  id="feedbackId${this.feedbacknos}">   
      <div class="msg-img-${side}" style="background-image: url(${img})"></div>    
       
      <div class="row msg-bubble-feedback">
        <div class="col-9 msg-text-new">${this.fb_prompt}</div>
        
          <div id="likediv${this.index}" class="col" style="padding-left:0px;padding-right:0px;">
          <button type="button"  onclick='feedbackLike3(${this.index})' class="feedbackid"> 
            <img id="likeImage${this.index}" src="${this.likeImage}"           
            style="width: 21.04px;height: 19.51px;"/>
          </button>${this.option1}</div>
          <div id="dislikediv${this.index}" class="col" style="padding-left:0px;padding-right:0px;">
            <button  type="button" onclick='feedbackDisLike(${this.index})'  class="feedbackdislikeid">
              <img id="dislikeImage${this.index}" src="${this.disLikeImage}" style="width: 21.04px;height: 19.51px;"> 
            </button>${this.option2}</div>        
      </div>
    </div>
        </div>
      </div>
    `;
    // side == "left" ?  $("#chat").append(feedbackHTML) : null;
    side == "left" ?  $("#chat").append(feedbackHTML) : $("#chat").append();
      // $("#chat").scrollTop += 500;
      // $("#chat").scrollTop(5000);
      $("#chat").scrollTop($('#chat')[0].scrollHeight);
  }
  tableidentify(apiResponse:any):any{
    var locateString1:any = "\n\n|";
    var locateString2:any = "|\n\n";
    let index1 = apiResponse.indexOf(locateString1);
    let index2 = apiResponse.indexOf(locateString2);
    var newstring = apiResponse.substring(index1, index2+1);
    while(index1 != -1){
      var rows = apiResponse.split('\n');
      var table = $('<table style="border:1px solid grey;border-collapse: collapse;padding: 5px;">');
      var thRow = $('<tr>'); 
      var headers = rows[1].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th style="border:1px solid grey;border-collapse: collapse;padding: 5px;">').text(header.trim());  
      
        // Append header cell to row  
        thRow.append(th);  
      });  
      table.append(thRow); 
      for (var i = 3; i < rows.length-1; i++) {  
        // Split row into cells  
        var cells = rows[i].split('|').filter(function(cell:any) :any{  
          return cell.trim() !== '';  
        }); 
      }
      var tr = $('<tr>');
      cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td style="border:1px solid grey;border-collapse: collapse;padding: 5px;">').text(cell.trim());  
    
        // Append cell to row  
        tr.append(td);  
      }); 
      table.append(tr);  
    } 
  }
  
  addMarkersForCode(apiResponse:any):any{  
    var updatedResponse:any = '';
    var locateString:any = "```"; //identifying code part
    var locateString1:any = "\n\n|";
    var locateString2:any = "|\n\n";
    var locateString3:any = "\n\n```\n|";
    var locateString4:any = "|\n```\n\n";
    var locateString5:any = "\n\n```\n<table>";
    var locateString6:any = "</table>\n```\n\n";
    var value ="";
    let index = apiResponse.indexOf(locateString);
    //console.log(apiResponse);
    let index1 = apiResponse.indexOf(locateString1);
    let index2 = apiResponse.indexOf(locateString2);
    let index3 = apiResponse.indexOf(locateString3);
    let index4 = apiResponse.indexOf(locateString4);
    let index5 = apiResponse.indexOf(locateString5);
    let index6 = apiResponse.indexOf(locateString6);
    // var newstring = apiResponse.substring(index1+2);
    // console.log(newstring);
    if(index5 != -1 && index6!= -1){
      var newstring = apiResponse.substring(index5+1, index6-1); 
      var table = $('<table>');
      apiResponse = apiResponse.replace("```","");
    }
    else if(index3 != -1 && index4 != -1 ){
      var newstring = apiResponse.substring(index3, index4+1);
      var rows = newstring.split('\n');
      var table = $('<table>');
      var thRow = $('<tr>'); 
      var headers = rows[3].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th>').text(header.trim());  
      
        // Append header cell to row  
        thRow.append(th);  
      });
      table.append(thRow);
      for (var j = 5; j < rows.length; j++) {  
        // Split row into cells  
        var cells = rows[j].split('|').filter(function(cell:any):any{  
          return cell.trim() !== '';  
        }); 
      
        var tr = $('<tr>');
        cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td>').text(cell.trim());  
    
        // Append cell to row  
        tr.append(td);  
        }); 
        this.tablecode = table.append(tr);
      }
      
      //console.table(JSON.stringify(this.tablecode));
       apiResponse = apiResponse.replace(newstring,
        `<table>          
          ${this.tablecode[0].innerHTML};
          </table> `
        ); 
        
    }
    else if(index1 != -1 && index2 != -1){
      var newstring = apiResponse.substring(index1, index2+1);
      var rows = newstring.split('\n');
      var table = $('<table>');
      var thRow = $('<tr>'); 
      var headers = rows[2].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th>').text(header.trim());  
      
        // Append header cell to row  
        thRow.append(th);  
      }); 
      table.append(thRow); 
      for (var j = 4; j < rows.length; j++) {  
        // Split row into cells  
        var cells = rows[j].split('|').filter(function(cell:any):any{  
          return cell.trim() !== '';  
        }); 
      
        var tr = $('<tr>');
        cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td>').text(cell.trim());  
    
        // Append cell to row  
        tr.append(td);  
        }); 
        this.tablecode = table.append(tr);
      }
       apiResponse = apiResponse.replace(newstring,
        `<table>          
          ${this.tablecode[0].innerHTML};
          </table> `
        );     
    }
    else if(index1 != -1 && index2 == -1){
      var newstring = apiResponse.substring(index1);
      var rows = newstring.split('\n');
      var table = $('<table>');
      var thRow = $('<tr>'); 
      var headers = rows[2].split('|').filter(function(cell:any):any {  
        return cell.trim() !== ''; 
      });
      headers.forEach(function(header:any):any {  
        // Create table header cell element  
        var th = $('<th>').text(header.trim());  
      
        // Append header cell to row  
        thRow.append(th);  
      }); 
      table.append(thRow); 
      for (var j = 4; j < rows.length; j++) {  
        // Split row into cells  
        var cells = rows[j].split('|').filter(function(cell:any):any{  
          return cell.trim() !== '';  
        }); 
      
        var tr = $('<tr>');
        cells.forEach(function(cell:any) {  
        // Create table cell element  
        var td = $('<td>').text(cell.trim());  
    
        // Append cell to row  
        tr.append(td);  
        }); 
        this.tablecode = table.append(tr);
      }
       console.table(JSON.stringify(this.tablecode));
        apiResponse = apiResponse.replace(newstring,
          `<table>          
          ${this.tablecode[0].innerHTML};
          </table> `
          );
    }
    //code functionality
  else{
    let i = 1;
    while (index != -1)
    {

      if (i % 2 != 0)
        {
          this.countvalue  = this.countvalue+1;
        // + apiResponse.substring(index+3,index+10);
        if(apiResponse.slice(index+3, index+4)=="\n"){
          this.language = ""
        }
        else{
         let index1 = apiResponse.indexOf("\n",index+3);
         this.language = apiResponse.substring(index+3, index1);
        }
        
        
          // if(this.language == "xml" || this.language ==  "html")
            apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replaceAll("<" , "&lt")
            apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replaceAll(">" , "&gt")
            apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(this.language , " ")
            apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(locateString, 
            `<pre style="border:none;margin-bottom:0px"><div style="background-color:#4A4A48;height:30px;"><button class="btn language" type="button">${this.language}</button><input type="button" id="copy_btn${this.countvalue}" value="${this.copy_title}" class="copy" onclick='copycode(${this.countvalue})'></input></div><code class="hljs language-xslt"  id="precode${this.countvalue}" lang=${this.language} >`);        
        // }
        // else{
        // apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(locateString, 
        //   `<pre style="border:none;margin-bottom:0px"><div style="background-color:#4A4A48;height:30px;"><button class="btn language" type="button">${this.language}</button><input type="button" id="copy_btn${this.countvalue}" value="${this.copy_title}" class="copy" onclick='copycode(${this.countvalue})'></input></div><code class="hljs" id="precode${this.countvalue}" lang=${this.language} >`);
        // }        
      }
      else
        {
          apiResponse = apiResponse.slice(0, index) + apiResponse.slice(index).replace(locateString, `</code> </pre>`);
        }

      index = apiResponse.indexOf(locateString, index + 1);
      i++;
    }
  }
    //console.log("apiResponse",apiResponse);
    //console.log("apiResponse",apiResponse);
    var key1   = localStorage.key(0);
    return apiResponse;
  }
  tableFormate(apiResponse:any):any {
      var str = " ------- -------- ------- \n| Name | Age | Sex |\n ------- -------- ------- \n| Alice | 25 | Female|\n| Bob | 30 | Male |\n| Cindy | 20 |Female |\n ------- -------- ------- ";
      
          var rows = str.split('\n');  
        
          // Sample string with dynamic rows and columns  
          var str = "+-------+--------+-------+\n| Name  |  Age   |  Sex  |\n+-------+--------+-------+\n| Alice |   25   | Female|\n|  Bob  |   30   |  Male |\n| Cindy |   20   |Female |\n+-------+--------+-------+";  

          // Split string into rows  
          var rows = str.split('\n');  
          
          // Create table element  
          var table = $('<table style="border:1px solid grey;border-collapse: collapse;padding: 5px;">');  
          
          // Create table header row element  
          var thRow = $('<tr>');  
          
          // Split header row into cells  
          var headers = rows[1].split('|').filter(function(cell) {  
            return cell.trim() !== '';  
          });  
          
          // Loop through header cells  
          headers.forEach(function(header) {  
            // Create table header cell element  
            var th = $('<th style="border:1px solid grey;border-collapse: collapse;padding: 5px;">').text(header.trim());  
          
            // Append header cell to row  
            thRow.append(th);  
          });  
          
          // Append header row to table  
          table.append(thRow);  
          
          // Loop through rows starting from the second row (index 2)  
          for (var i = 3; i < rows.length-1; i++) {  
            // Split row into cells  
            var cells = rows[i].split('|').filter(function(cell) {  
              return cell.trim() !== '';  
            });  
          
            // Create table row element  
            var tr = $('<tr>');  
          
            // Loop through cells  
            cells.forEach(function(cell) {  
              // Create table cell element  
              var td = $('<td style="border:1px solid grey;border-collapse: collapse;padding: 5px;">').text(cell.trim());  
          
              // Append cell to row  
              tr.append(td);  
            });  
          
            // Append row to table  
            table.append(tr);  
          }  
          
          // Append table to the page  
          $('#chat').append(table);  
  
    }

    sendAsMessage(messagefromprompt:any):any {
      this.messagetoChat = messagefromprompt;
      // this.messagetoChat = messagefromprompt.replaceAll({'"':'' , '[topic]?':''});
      // this.messagetoChat = messagefromprompt.replace(/"|[topic]?/g,'' );
      this.messagetoChat = messagefromprompt.replace(/"/g,'');
      $("#msgoninput").val(this.messagetoChat); 
    
   }

  
   
}


