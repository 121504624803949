export const environment = {
    production: false,
    APIBaseUrl:"https://gendapi.deloitte.ca/",
    clientId:"f89cf4ee-b0e9-41e2-ad1e-709b09da6713",
    tenentId:"36da45f1-dd2c-4d1f-af13-5abe46b99921",
    APIScopeURL:"api://7ca624f5-8232-49fd-b747-25fcd3fe01ca/user_impersonation",
    feedbackURL: "https://gendapi.deloitte.ca/OpenAPI/SubmitFeedBack",
    docfeedbackURL : "https://gendapi.deloitte.ca/api/DocumentAnalyzer/SubmitFeedBack",
    uploadDocumentURL : "https://gendapi.deloitte.ca/api/Blob/UploadDocument",
    refreshDocumentURL : "https://gendapi.deloitte.ca/api/Blob/RefreshBlobs",
    summaryDocumentURL : "https://gendapi.deloitte.ca/api/DocumentAnalyzer/DocumentAnalyzerResults",
    orangeProjectURL : "https://gendapi.deloitte.ca/api/Onboarding/OnboardingQuery",
    orangefeedbackURL : "https://gendapi.deloitte.ca/api/Onboarding/SubmitOnboardingFeedBack",
    verifyUserURL : "https://gendapi.deloitte.ca/api/Onboarding/VerifyUser",
    downloadDocumentURL : "https://gendapi.deloitte.ca/api/Onboarding/DownloadDocument",
    processDocumentURL : "https://gendapi.deloitte.ca/api/DocumentAnalyzer/",
    previewGenDURL : "https://gendbeta.deloitte.ca/"
 };