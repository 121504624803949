<!-- <div *ngIf="isLoading" id="wait" class="waitstyle">
  <img src="../assets/images/load.gif" height="50" width="50" alt="loading_icon"/><br>
  <p class="loadingtext">{{loadmsg}}</p>
</div> -->
<div><app-chat-navbar [uservalid]="validuser"></app-chat-navbar>
<div class="">
  <!-- Preview PopUP-->
  <div class="modal" *ngIf="switcpopup" id="preview" style="padding-top:50px;padding-left:0%;" id="myModal" role="dialog" [ngStyle]="{'display': displayPreview}">
    <div class="modal-dialog" role="document">
      <div class="modal-content" id="preview">
          <div class="row">
            <div class="col-11" >
              <div class="row" >
              <h2 id="text-preview">  
                <div>                   
                   {{previewText}}
                   <br>&nbsp;
                   <ul>
                      <li> {{temp1}}</li>
                      <li> {{temp2}}</li>
                      <li> {{temp3}}</li>
                      <li> {{temp4}}</li>
                    </ul>
                  </div>
              </h2>
            </div>
            <div class="row"  style="padding-top:1.9em;padding-bottom:1.8em;"> 
                <div  class="col-2"> </div>
                <button type="button" class="col-4" data-dismiss="modal" id="text-preview-buttons" style="background-color: gray;color:white;border-radius:4px" (click)="closePreview()">{{preview_option1}} </button>
                <div  class="col-1"> </div>
 
                <button type="button" class="col-4" data-dismiss="modal" id="text-preview-buttons" style="background-color:#084E6C;color:white;border-radius:4px" (click)="openPreview()"> {{preview_option2}} </button>
                <div  class="col-2"> </div>

              </div>
            </div>
            <div class="col-1"><button type="button" class="close" data-dismiss="modal" (click)="closePreview()">
              <span aria-hidden="true">&times;</span>
            </button></div>
          </div>
          <br> 
        <br>
      </div> 
    </div>
  </div>
  <!-- Modal popup screen-1 -->
 <div class="modal" *ngIf="switcpopup" id="limitations" style="padding-top:50px;padding-left:0%;" id="myModal" role="dialog" [ngStyle]="{'display': displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="row">
          <!-- <div class="col-1"><img src="../assets/images/Limitations.PNG" class="popup-images"></div> -->
          <div class="col-11" ><h4 >                     
              <dds-tabs [theme]="tabContainerOptions.theme"
              [themeType]="tabContainerOptions.themeType"
              [size]="tabContainerOptions.size"
              [isResponsive]="tabContainerOptions.isResponsive"
              [customClass]="tabContainerOptions.customClass"
              [ariaLabel]="tabContainerOptions.ariaLabel" style="overflow-y:auto;height:60em;">
                  <dds-tab [label]="guardrails">                 
                      <br>
                            
                    <div class="col-lg-3">    
                      <dds-vertical-tabs [theme]="verticaltabContainerOptions.theme"
                          [themeType]="verticaltabContainerOptions.themeType"
                          [size]="verticaltabContainerOptions.size" 
                          >    
                          <dds-vertical-tab [label]="'Gen-D'"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged($event)">
                          </dds-vertical-tab>
                          <dds-vertical-tab [label]="doc_analyzer"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged($event)">
                          </dds-vertical-tab>
                      </dds-vertical-tabs>
                    </div>

                          <!-- <dds-wizard [theme]="wizardOptions.theme"
                          [startNumbering]="wizardOptions.startNumbering"
                          [isVertical]="wizardOptions.isVertical"
                          [isNumeric]="wizardOptions.isNumeric"
                          [isInverse]="wizardOptions.isInverse"
                          (wizardItemChanged)="wizardItemChanged($event)">

                          <dds-wizard-item [label]="'Gen-D'"
                                [customClass]="'col-6'">
                          </dds-wizard-item>

                          <dds-wizard-item [label]="doc_analyzer"
                               [customClass]="'col-6'">
                          </dds-wizard-item>                    
                        </dds-wizard> -->
                      <div class="col-lg-9" *ngIf="guideLinesLimitations == 'Gen-D'" style="max-height: 24em; overflow-y: scroll">
                        <div >
                          <b>{{this.popup_subheader1}}</b>
                          <br>  &nbsp;
                          <ol>
                            <li>{{pop1_msg1}}</li>
                            <li>{{pop1_msg2}}</li>
                            <li>{{pop1_msg3}}</li>
                            <li>{{pop1_msg4}}</li>
                            <li>{{pop1_msg5}}</li>
                          </ol><br>
                          <h4><b>{{popup_header3}}</b></h4>
                          <br>
                          {{this.popup_subheader3}} &nbsp;
                          <ol>
                            <li>{{pop3_msg1}}
                              <ul>
                                <li>{{sub1_1}}</li>
                                <li>{{sub1_2}}</li>
                                <li>{{sub1_3}}</li>
                                <li>{{sub1_4}}</li>
                                <li>{{sub1_5}}</li>
                                <li>{{sub1_6}}
                                  <ul>
                                    <li>{{sub1_6_1}}</li>
                                    <li>{{sub1_6_2}}</li>
                                    <li>{{sub1_6_3}}</li>
                                    <li>{{sub1_6_4}}</li>
                                  </ul>
                                </li>
                                <li>{{sub1_7}}</li>
                                <li>{{sub1_8}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg2}}
                              <ul>
                                <li>{{sub2_1}}</li>
                                <li>{{sub2_2}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg3}}
                              <ul>
                                <li>{{sub3_1}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg4}}
                              <ul>
                                <li>{{sub4_1}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg5}}
                              <ul>
                                <li>{{sub5_1}}</li>
                              </ul>
                            </li>
                            <li>{{pop3_msg6}}
                              <ul>
                                <li>{{sub6_1}}</li>
                              </ul>
                            </li>
                          </ol>
                          <br>
                          <h4><b>{{popup_header4}}</b></h4>
                          <br>
                          {{this.popup_subheader4}} &nbsp;
                          <ol>
                          <li>{{pop4_msg1}}
                            <ul>
                              <li>{{sub_1}}</li>
                              <li>{{sub_2}}</li>
                              <li>{{sub_3}}</li>
                              <li>{{sub_4}}</li>
                              <li>{{sub_5}}</li>
                              <li>{{sub_6}}</li>
                            </ul>
                          </li>
                          <li>{{pop3_msg2}}
                            <ul>
                              <li>{{sub_7}}</li>
                              <li>{{sub_8}}</li>
                            </ul>
                          </li>
                          <li>{{pop3_msg3}}
                            <ul><li>{{sub_9}}</li></ul>
                          </li>
                          <li>{{pop3_msg5}}
                            <ul><li>{{sub_10}}</li></ul>
                          </li>
                        </ol> 

                        </div>
                      </div>
                      <div class="col-lg-9" *ngIf="guideLinesLimitations == doc_analyzer" style="max-height: 24em; overflow-y: scroll;">
                        <div>
                          {{docHeader}}
                          <br><br>
                         <h4> <b>{{limitation}}</b></h4>
                          <ul>
                            <li>{{xls_msg}}</li>
                               <li>{{limitationdoc1}}</li>
                               <li>{{limitationdoc2}}</li>
                               <li>{{limitationdoc3}}</li>
                          </ul> 
                          <br>
                          <h4><b>{{guidancePrivacy}}</b></h4>
                          <ul >
                            <div>
                              <li> <b> DO NOT </b>{{docpop3_msg1}} </li>
                              <li> <b> DO NOT </b>{{docpop3_msg2}} </li>
                              <li> <b> DO NOT </b>{{docpop3_msg3}} </li>
                            </div>
                            <div class="custom-tick"> <b> {{docpop3_msg4}} </b>
                              <ul> 
                                <li> {{docsub1_1}}</li>
                                <li> {{docsub1_2}}</li>
                              </ul>
                            </div>
                            <div class="custom-tick">  <b> {{docpop3_msg5}} </b>
                              <ul> 
                                <li> {{docsub2_1}}</li>
                                <li> {{docsub2_2}}</li>
                                <li> {{docsub2_3}}</li>
                                <li> {{docsub2_4}}</li>
                              </ul>
                            </div>
                            <div class="custom-tick">  <b> {{docpop3_msg6}} </b>
                              <ul> 
                                <li> {{docsub3_1}}</li>
                                <li> {{docsub3_2}}</li>
                                <li> {{docsub3_3}}</li>
                              </ul>
                            </div>
                            <div class="custom-tick">  <b> {{docpop3_msg7}} </b>
                              <ul> 
                                <li> {{docsub4_1}}</li>
                              </ul>
                            </div>
                          </ul>   
                          <br>
                          <h4><b>{{docstorage}}</b></h4>
                        <ul>
                          <li>{{docpop2_msg1}}</li>
                          <li>{{docpop2_msg2}}</li>
                          <li>{{docpop2_msg3}}</li>
                          <li>{{docpop2_msg4}}</li>
                          <li>{{docpop2_msg5}}</li>

                        </ul>                   
                        </div>
                      </div>
                     
                  </dds-tab>
                  <dds-tab [label]="howto">
                    <br>
                    <div class="col-lg-3">    
                        
                      <dds-vertical-tabs [theme]="verticaltabContainerOptions.theme"
                          [themeType]="verticaltabContainerOptions.themeType"
                          [size]="verticaltabContainerOptions.size" 
                          >    
                          <dds-vertical-tab [label]="'Gen-D'"  [customClass]="'col-6 dds-vertical-tabs__item-wrap_active'" (tabChanged)="wizardItemChanged1($event)">
                          </dds-vertical-tab>
                          <dds-vertical-tab [label]="doc_analyzer"  [customClass]="'col-6'" (tabChanged)="wizardItemChanged1($event)">
                          </dds-vertical-tab>
                      </dds-vertical-tabs>
                    </div>
                    <div class="col-lg-9" *ngIf="guideLinesLimitations1 == 'Gen-D'" style="max-height: 24em; overflow-y: scroll">
                      <div >
                        {{this.popup_subheader2}}
                        &nbsp;
                        <br><br>
                        <ol>
                          <li>{{pop2_msg1}}</li>
                          <li>{{pop2_msg2}}</li>
                          <li>{{pop2_msg3}}</li>
                          <li>{{pop2_msg4}}</li>
                          <li>{{pop2_msg5}}</li>
                          <li>{{pop2_msg6}}</li>
                        </ol><br>
                        <div class="subhead1">{{subheading}}</div>
                        <h4><b>{{prompt_head1}}</b></h4>
                        <ul>
                              <li>{{messagetoChat1}}</li>
                              <li>{{messagetoChat2}}</li>
                              <li>{{messagetoChat3}}</li>
                              <li>{{messagetoChat9}}</li>
                        </ul>                      
                        <br>
                        <h4><b>{{prompt_head2}}</b></h4>
                        <ul>
                              <li>{{messagetoChat4}}</li>
                              <li>{{messagetoChat5}}</li>
                              <li>{{messagetoChat10}}</li>
                        </ul> 
                        <br>
                        <h4><b>{{prompt_head3}}</b></h4>
                        <ul>
                              <li>{{messagetoChat6}}</li>
                              <li>{{messagetoChat7}}</li>
                              <li>{{messagetoChat8}}</li>
                              <li>{{messagetoChat11}}</li>
                            </ul> 
                        
                      </div>
                    </div>
                    <div class="col-lg-9" *ngIf="guideLinesLimitations1 == doc_analyzer" style="max-height: 24em; overflow-y: scroll;">
                      <div>
                        <ol>
                          <li>{{this.docpopup_subheader_1}}
                            <ul>
                              <li> {{docpop1_msg1}} </li>
                              <li> {{docpop1_msg2}} </li>
                              <li> {{docpop1_msg3}} </li>
                              <li> {{docpop1_msg4}} </li>
                              <li> {{docpop1_msg5}} </li>
                              <li> {{docpop1_msg6}} </li>
                              <li> {{docpop1_msg7}} </li>
                            </ul>
                          </li>
                          <br>
                          <li>{{this.docpopup_subheader_2}}
                            <ul>
                              <li> {{tips1}} </li>
                              <li> {{tips2}} </li>
                            </ul>
                          </li>
                          <br>
                          <li>{{this.transtip}}
                            <ul>
                              <li> {{transtip1}}                                 
                                <ol>
                                  <li> {{transtip1_1}} </li>
                                  <li> {{transtip1_2}} </li>
                                  <li> {{transtip1_3}} </li>
                                  <li> {{transtip1_4}} </li>
                                </ol>
                              
                              </li>
                              <li> {{transtip2}} </li>
                              <li> {{transtip3}} </li>
                              <li> {{transtip4}} </li>
                            </ul>
                          </li>
                        </ol>                        
                      </div>
                    </div>
                  </dds-tab>
              </dds-tabs>
          </h4></div>
          <div class="col-1"><button type="button" class="close" data-dismiss="modal" (click)="closePopup1()">
            <span aria-hidden="true">&times;</span>
          </button></div>
        </div>
        <br> 
      <br>
    </div> 
  </div>
</div>
  <router-outlet></router-outlet>
</div>
</div>